import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import DescriptionIcon from '@mui/icons-material/Description';

import { ACTIVITY_LOGS_PATIENT } from '~/ui/constants/paths';
import styles from './Item.module.scss';
import { IActivityLogList } from '~/services/api/activityLogs/types';

interface IProps {
  activityLog: IActivityLogList;
}

const Item = ({ activityLog }: IProps): ReactElement => (
  <TableRow>
    <TableCell>
      <Link to={ACTIVITY_LOGS_PATIENT.replace(':patientId', String(activityLog.patientId))}>
        <IconButton className={classnames(styles.icon, styles.infoIcon)} title="Details">
          <DescriptionIcon />
        </IconButton>
      </Link>
    </TableCell>
    <TableCell className={styles.cell}>{activityLog.patientFullName}</TableCell>
    <TableCell>{activityLog.patientSubjectId}</TableCell>
    <TableCell>{activityLog.patientStatus}</TableCell>
    <TableCell className={styles.cell}>{activityLog.programName}</TableCell>
    <TableCell align="center">{activityLog.activityMinutesCount}</TableCell>
  </TableRow>
);

export default Item;
