import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const validationSchema = object().shape({
  email: string()
    .max(100, 'Max length - 100 symbols')
    .email('Email must be valid')
    .required('Field is required'),
  password: string().max(100, 'Max length - 100 symbols').required('Field is required'),
});

export default yupResolver(validationSchema);
