import { action } from 'easy-peasy';
import { IActionSetMoreUsers } from '../types';

const setMoreUsers: IActionSetMoreUsers = action((state, payload) => {
  const hasMore = state.itemsPagination.itemsPerPage === payload.length;

  state.items = [...state.items, ...payload];
  state.itemsPagination.page += 1;
  state.itemsPagination.hasMore = hasMore;
});

export default setMoreUsers;
