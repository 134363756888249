import { ReactElement } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { getChartOptions } from './helper';
import GraphCard from '~/ui/components/reusable/GraphCard';
import { IReading } from '~/services/api/readings/types';

interface IProps {
  readings: IReading[];
}

const ReadingCount = ({ readings }: IProps): ReactElement => {
  const title = 'Reading Count Over Period';

  if (readings.length === 0) {
    return <GraphCard title={title} showNoData />;
  }

  const options = getChartOptions(readings);

  return (
    <GraphCard title={title}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </GraphCard>
  );
};

export default ReadingCount;
