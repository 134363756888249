import { action } from 'easy-peasy';
import { IActionSetActivityLogs } from '../types';

const setActivityLogs: IActionSetActivityLogs = action((state, payload) => {
  const hasMore = state.pagination.itemsPerPage === payload.length;

  state.items = payload;
  state.pagination.page += 1;
  state.pagination.hasMore = hasMore;
});

export default setActivityLogs;
