import { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import SaveIcon from '@mui/icons-material/Save';
import pick from 'lodash/pick';

import { useStoreState, useStoreActions } from '~/store/hooks';
import { firstLastNameValidationSchema } from '../validate';
import { extractErrorMessage } from '~/utils/error';
import Button from '~/ui/components/common/Button';
import Input from '~/ui/components/inputs/Input';
import Loader from '~/ui/components/common/Loader';

import styles from '../Form.module.scss';
import api from '~/services/api';

type IFormInputs = {
  firstName: string;
  lastName: string;
};

const AccountSettingsForm = (): ReactElement => {
  const [loading, setLoading] = useState(false);

  const currentUser = useStoreState(state => state.auth.currentUser);
  const onRefresh = useStoreActions(actions => actions.auth.onRefresh);
  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);

  const defaultValues = pick(currentUser, ['firstName', 'lastName']);

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<IFormInputs>({ defaultValues, resolver: firstLastNameValidationSchema });

  const onSubmit = async (data: IFormInputs) => {
    try {
      setLoading(true);
      await api.users.updateCurrent(data);
      await onRefresh();
      showNotify('User info successfully updated');
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loader />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.inputContainer}>
        <Input
          name="firstName"
          register={register}
          errors={errors}
          label="First Name"
          size="small"
        />
      </div>
      <div className={styles.inputContainer}>
        <Input name="lastName" register={register} errors={errors} label="Last Name" size="small" />
      </div>
      <Button type="submit" variant="contained" color="primary" startIcon={<SaveIcon />}>
        <span className={styles.divider} />
        SAVE
      </Button>
    </form>
  );
};

export default AccountSettingsForm;
