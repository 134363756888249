import onDeleteNotifications from './onDeleteNotifications';
import onGetMoreNotifications from './onGetMoreNotifications';
import onGetNotifications from './onGetNotifications';
import onGetNotifyCount from './onGetNotifyCount';

export default {
  onGetNotifications,
  onDeleteNotifications,
  onGetNotifyCount,
  onGetMoreNotifications,
};
