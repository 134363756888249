import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getPaginationPayload } from '~/utils/pagination';
import { IInsurancesStore, IThunkGetMoreInsurances } from '../types';

const onGetMoreInsurances: IThunkGetMoreInsurances = thunk(
  // @ts-ignore
  async ({ setMoreInsurances }, filters, { getState }) => {
    const localState = getState() as IInsurancesStore;

    const paginationPayload = getPaginationPayload(localState.pagination);

    const { data } = await api.insurances.getList({
      ...filters,
      ...paginationPayload,
    });

    setMoreInsurances(data);
  },
);

export default onGetMoreInsurances;
