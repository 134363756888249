import { ReactElement } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';

import { useStoreState } from '~/store/hooks';
import Loader from '~/ui/components/common/Loader';
import NoDataFound from '~/ui/components/reusable/NoDataFound';
import BloodPressure from '~/ui/components/reusable/Reading/BloodPressure';
import BloodGlucose from '~/ui/components/reusable/Reading/BloodGlucose';
import PulseOximeter from '~/ui/components/reusable/Reading/PulseOximeter';
import WeightScale from '~/ui/components/reusable/Reading/WeightScale';

import { PATIENT_DASHBOARD } from '~/ui/constants/paths';
import { IPagePermissions } from '../../../types';
import { IStatusPatient } from '~/services/api/patients/types';

import styles from './Table.module.scss';

interface IProps {
  patients: IStatusPatient[];
  permissions: IPagePermissions;
  onLoadMore: () => void;
}

const TableComponent = ({ patients, permissions, onLoadMore }: IProps): ReactElement => {
  const pagination = useStoreState(state => state.patients.pagination);

  return (
    <TableContainer className={styles.tableContainer}>
      <InfiniteScroll
        next={onLoadMore}
        hasMore={pagination.hasMore}
        dataLength={patients.length}
        loader={<Loader />}
        scrollableTarget="scrollableDiv"
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="center">Latest Reading</TableCell>
              <TableCell align="center" className={styles.colReadings}>
                # Readings in current month
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {patients.length ? (
              patients.map(patient => {
                const patientName = patient.fullName.toUpperCase();

                const latestReadings = patient?.latestReadings;

                return (
                  <TableRow key={patient.id}>
                    <TableCell className={styles.cell}>
                      {permissions.canManagePatients ? (
                        <Link
                          to={PATIENT_DASHBOARD.replace(':id', String(patient.id))}
                          className={styles.link}
                        >
                          {patientName}
                        </Link>
                      ) : (
                        patientName
                      )}
                    </TableCell>
                    <TableCell width={260} align="center">
                      <div className={styles.latestReadingWidgets}>
                        {latestReadings.bloodPressure && (
                          <BloodPressure
                            reading={patient.latestReadings.bloodPressure}
                            rules={patient.notificationRules}
                            timezone={patient.timezone}
                          />
                        )}
                        {latestReadings.weightScale && (
                          <WeightScale
                            reading={patient.latestReadings.weightScale}
                            rules={patient.notificationRules}
                            timezone={patient.timezone}
                          />
                        )}
                        {latestReadings.pulseOximeter && (
                          <PulseOximeter
                            reading={patient.latestReadings.pulseOximeter}
                            rules={patient.notificationRules}
                            timezone={patient.timezone}
                          />
                        )}
                        {latestReadings.bloodGlucose && (
                          <BloodGlucose
                            reading={patient.latestReadings.bloodGlucose}
                            rules={patient.notificationRules}
                            timezone={patient.timezone}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell align="center">{patient.totalReadingsCount}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={3}>
                  <NoDataFound />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </InfiniteScroll>
    </TableContainer>
  );
};

export default TableComponent;
