import { parseISO, formatISO, isDate, isValid, format, set } from 'date-fns';
import { utcToZonedTime, format as formatTZ } from 'date-fns-tz';
import { Timezone, TimezoneCode } from '~/types';

export const isoToFormat = (
  date: string,
  dateFormat: string,
  timezone?: Timezone,
  showTimezone = true,
): string => {
  const dateObject = new Date(date);

  if (!isValid(dateObject)) return date;

  if (!timezone) return format(dateObject, dateFormat);

  const dateTimezoneCorrected = utcToZonedTime(date, TimezoneCode[timezone]);

  const formattedDate = format(dateTimezoneCorrected, dateFormat);

  if (showTimezone) return `${formattedDate} ${timezone}`;
  return formattedDate;
};

export const setEndOfDay = (dateValue: Date | string): string => {
  if (!dateValue) return '';

  const date = isDate(dateValue) ? (dateValue as Date) : new Date(dateValue);

  const formattedDate = set(date, {
    hours: 23,
    minutes: 59,
    seconds: 59,
  });

  return formatISO(formattedDate);
};

export const isDateBigger = (start: string, end: string): boolean => {
  const startDate = parseISO(start);
  const endDate = parseISO(end);

  if (!isValid(startDate) || !isValid(endDate)) return false;
  return endDate.getTime() > startDate.getTime();
};

export const isDateBiggerOrEqual = (start: string, end: string): boolean => {
  const startDate = parseISO(start);
  const endDate = parseISO(end);

  if (!isValid(startDate) || !isValid(endDate)) return false;
  return endDate.getTime() >= startDate.getTime();
};

export const getCurrentMonthFirstDayDate = (): string => {
  const date = new Date();
  const formattedDate = new Date(date.getFullYear(), date.getMonth(), 1);
  return formatISO(formattedDate);
};

export const getMonthAgoDate = (): string => {
  const date = new Date();
  const formattedDate = new Date(date.getFullYear(), date.getMonth() - 1, date.getDate());
  return formatISO(formattedDate);
};

export const getWeekAgoDate = (): string => {
  const date = new Date();
  const formattedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 6);
  return formatISO(formattedDate);
};

export const formatToIsoWithoutTimezone = (dateValue: Date | string): string => {
  if (!dateValue) return '';

  const date = isDate(dateValue) ? (dateValue as Date) : new Date(dateValue);
  return format(date, "yyyy-MM-dd'T'HH:mm:ss'Z'");
};

export const formatToIsoWithTimezone = (dateValue: Date | string, timezone: Timezone): string => {
  if (!dateValue) return '';

  const date = isDate(dateValue) ? (dateValue as Date) : new Date(dateValue);
  return formatTZ(date, "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone: TimezoneCode[timezone] });
};

export const getInitialDateRange = () => ({
  startDate: getCurrentMonthFirstDayDate(),
  endDate: setEndOfDay(new Date().toISOString()), // use `today` as endDate
});

export const getYesterdayDate = (): string => {
  const date = new Date();
  const formattedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
  return formatISO(formattedDate);
};

export const getEndOfTodayDate = (): string => setEndOfDay(new Date().toISOString());

export const getLastWeekDateRange = () => ({
  startDate: getWeekAgoDate(),
  endDate: setEndOfDay(new Date()), // use `today` as endDate
});

export const getLastMonthDateRange = () => ({
  startDate: getMonthAgoDate(),
  endDate: setEndOfDay(new Date()), // use `today` as endDate
});
