import { UserRole } from '~/types';

export const userTypeOptions = [
  {
    value: UserRole.HealthManager,
    label: 'Health Manager',
  },
  {
    value: UserRole.ProgramAdmin,
    label: 'Program Admin',
  },
  {
    value: UserRole.SuperAdmin,
    label: 'Super Admin',
  },
];

export const userPermissionOptions = [
  {
    value: 'ManageDevices',
    label: 'Manage Devices - Gives access to the Inventory tab. Can Assign/Unassign device',
  },
  {
    value: 'ManagePatients',
    label:
      'Manage Patients - Gives access to Patients tab with ability to go to patient level and Edit patients data. Also gives permission to discharge and reinstate patients',
  },
  {
    value: 'MonitorPatients',
    label:
      'Monitor Patients - Can only perform monitoring activities. Patients (limited access), Notification Rules (limited access) and Activity Logs. Users cannot go to the patient level to edit details',
  },
  {
    value: 'PatientsEnrollments',
    label: 'Patients Enrollments - Can enroll Patients',
  },
  {
    value: 'ManageReports',
    label: 'Manage Reports - Can view reports',
  },
  {
    value: 'ManageNotifications',
    label: 'Manage Notifications - Create/Terminate Notifications Rules for Patients',
  },
  {
    value: 'ManageUsers',
    label: 'Manage Users - Can view Users tab. Can Add/Edit details for Health Manager',
  },
  {
    value: 'ManageMobileUsers',
    label:
      'Manage Mobile Users - Can view the Mobile Users tab. Can manage the mobile application users',
  },
];

export const userRoles = {
  1: UserRole.SystemAdmin,
  2: UserRole.SuperAdmin,
  3: UserRole.ProgramAdmin,
  4: UserRole.HealthManager,
};
