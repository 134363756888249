import { ReactElement } from 'react';

import PatientsPopup from '../reusable/PatientsPopup';

import { IPagePermissions } from '../../types';
import { PatientStatus } from '~/services/api/enums';

interface IProps {
  permissions: IPagePermissions;
  onClose: () => void;
}

const ApprovedPatients = ({ permissions, onClose }: IProps): ReactElement => (
  <PatientsPopup
    permissions={permissions}
    onClose={onClose}
    status={PatientStatus.Approved}
    title="Approved"
    subTitle="Approved Patients"
  />
);

export default ApprovedPatients;
