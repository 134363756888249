import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetUser } from '../types';

// @ts-ignore
const onGetUser: IThunkGetUser = thunk(async ({ setUser }, id) => {
  const user = await api.users.get(id).then(res => res.data);
  setUser(user);
});

export default onGetUser;
