import { ReactElement } from 'react';
import Paper from '@material-ui/core/Paper';

import GraphCard from '~/ui/components/reusable/GraphCard';

import { IPatientOverview } from '~/services/api/patients/types';
import styles from './Reading.module.scss';
import { DeviceType } from '~/services/api/enums';
import {
  IBloodGlucoseReading,
  IBloodPressureReading,
  IPulseOximeterReading,
  IWeightScaleReading,
} from '~/services/api/readings/types';
import BloodPressure from '~/ui/components/reusable/Reading/BloodPressure';
import WeightScale from '~/ui/components/reusable/Reading/WeightScale';
import PulseOximeter from '~/ui/components/reusable/Reading/PulseOximeter';
import BloodGlucose from '~/ui/components/reusable/Reading/BloodGlucose';

interface IProps {
  patientOverview: IPatientOverview;
  deviceTypeId: DeviceType;
}

const Reading = ({ patientOverview, deviceTypeId }: IProps): ReactElement => {
  const title = 'Readings';

  return (
    <Paper elevation={24} square className={styles.container}>
      {patientOverview.readings.length ? (
        <GraphCard title={title}>
          {patientOverview.readings.map(reading => (
            <div key={reading.id} className={styles.item}>
              {deviceTypeId === DeviceType.BloodPressure && (
                <BloodPressure
                  reading={reading as IBloodPressureReading}
                  rules={patientOverview.notificationRules}
                  timezone={patientOverview.timezone}
                />
              )}
              {deviceTypeId === DeviceType.WeightScale && (
                <WeightScale
                  reading={reading as IWeightScaleReading}
                  rules={patientOverview.notificationRules}
                  timezone={patientOverview.timezone}
                />
              )}
              {deviceTypeId === DeviceType.PulseOximeter && (
                <PulseOximeter
                  reading={reading as IPulseOximeterReading}
                  rules={patientOverview.notificationRules}
                  timezone={patientOverview.timezone}
                />
              )}
              {deviceTypeId === DeviceType.BloodGlucose && (
                <BloodGlucose
                  reading={reading as IBloodGlucoseReading}
                  rules={patientOverview.notificationRules}
                  timezone={patientOverview.timezone}
                />
              )}
            </div>
          ))}
        </GraphCard>
      ) : (
        <GraphCard title={title} showNoData />
      )}
    </Paper>
  );
};

export default Reading;
