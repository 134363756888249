import { useState, ReactElement } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';

import { useStoreActions } from '~/store/hooks';
import { isoToFormat } from '~/utils/date';
import { secondsToMinutes } from '~/utils/convert';
import { extractErrorMessage } from '~/utils/error';
import ConfirmModal from '~/ui/components/common/ConfirmModal';

import { IActivityLog } from '~/services/api/activityLogs/types';
import { ISectionPermissions } from '../../types';
import styles from './Item.module.scss';
import ReadMoreLess from '~/ui/components/common/ReadMoreLess';

interface IProps {
  activityLog: IActivityLog;
  userId: number;
  permissions: ISectionPermissions;
  onEdit: (activityLog: IActivityLog) => void;
}

const Item = ({ activityLog, userId, permissions, onEdit }: IProps): ReactElement => {
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);
  const onDeleteActivityLog = useStoreActions(actions => actions.activityLogs.onDeleteActivityLog);

  const removeActivityLog = async () => {
    setIsRemoving(true);

    try {
      await onDeleteActivityLog(activityLog.id);
      showNotify('Activity log removed');
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsRemoving(false);
    }
  };

  return (
    <TableRow>
      <TableCell align="center">{secondsToMinutes(activityLog.secondsSpent)}</TableCell>
      <TableCell>{`${isoToFormat(activityLog.dateTime, 'dd MMM y H:mm')} ${
        activityLog.patientTimezone
      }`}</TableCell>
      <TableCell>
        <ReadMoreLess content={activityLog.note} />
      </TableCell>
      <TableCell>{activityLog.userFullName}</TableCell>
      {activityLog.userId === userId || permissions.canManage ? (
        <TableCell>
          <IconButton className={styles.icon} title="Edit" onClick={() => onEdit(activityLog)}>
            <CreateIcon />
          </IconButton>
        </TableCell>
      ) : (
        <TableCell />
      )}
      {permissions.canManage && (
        <TableCell align="center" width={80}>
          <IconButton
            className={styles.icon}
            title="Remove"
            onClick={() => setShowRemoveConfirm(true)}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      )}
      {showRemoveConfirm && (
        <ConfirmModal
          title="Remove"
          description="Are you sure you want to remove current Activity log?"
          isLoading={isRemoving}
          onClose={() => setShowRemoveConfirm(false)}
          onConfirm={removeActivityLog}
        />
      )}
    </TableRow>
  );
};

export default Item;
