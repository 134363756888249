import { Grid } from '@material-ui/core';
import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import SearchIcon from '@mui/icons-material/Search';
import RotateRightIcon from '@mui/icons-material/RotateRight';

import { formatToIsoWithTimezone, setEndOfDay } from '~/utils/date';
import { initialValues, validate } from './form';
import Button from '~/ui/components/common/Button';
import DatePicker from '~/ui/components/inputs/DatePicker';

import { IPatientInfo } from '~/services/api/patients/types';
import styles from './Header.module.scss';
import { IDatePagedFilters } from '~/services/api/types';
import Select from '~/ui/components/inputs/Select';
import { isTelephoneConversationOptions } from './constants';

interface IProps {
  patientInfo: IPatientInfo;
  onSubmit: (values: IDatePagedFilters) => Promise<void>;
  showModal: () => void;
}

const Header = ({ patientInfo, onSubmit, showModal }: IProps): ReactElement => {
  const formMethods = useForm({ resolver: validate, defaultValues: initialValues });

  const {
    getValues,
    reset,
    control,
    formState: { errors },
    handleSubmit,
  } = formMethods;
  const values = getValues();
  const disabled =
    values.startDate === initialValues.startDate &&
    values.endDate === initialValues.endDate &&
    values.isTelephoneConversation === initialValues.isTelephoneConversation;

  const onSubmitForm = (formValues: IDatePagedFilters) => {
    onSubmit({
      ...formValues,
      startDate: formatToIsoWithTimezone(formValues.startDate, patientInfo.timezone),
      endDate: formatToIsoWithTimezone(setEndOfDay(formValues.endDate), patientInfo.timezone),
    });
  };

  return (
    <>
      <div className={styles.headerWrapper}>
        <div className={styles.title}>Notes</div>
        <Button variant="contained" color="primary" onClick={showModal}>
          Add Notes
        </Button>
      </div>
      <form className={styles.form} onSubmit={handleSubmit(onSubmitForm)}>
        <Grid container spacing={1}>
          <Grid item xs>
            <DatePicker
              placeholder=".. /.. /.."
              label="Start Date"
              name="startDate"
              maxDate={new Date()}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs>
            <DatePicker
              placeholder=".. /.. /.."
              label="End Date"
              name="endDate"
              maxDate={new Date()}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs>
            <Select
              name="isTelephoneConversation"
              control={control}
              errors={errors}
              options={isTelephoneConversationOptions}
              label="Telephone Conversation"
            />
          </Grid>
          <Grid item xs={4} />
          <Grid item>
            <Button variant="outlined" type="submit" color="primary" startIcon={<SearchIcon />}>
              Search
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={disabled}
              variant="outlined"
              type="button"
              startIcon={<RotateRightIcon />}
              onClick={() => {
                reset(initialValues);
                onSubmit({});
              }}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default Header;
