import { action } from 'easy-peasy';
import { IActionSetMobileUsers } from '../types';

const setMobileUsers: IActionSetMobileUsers = action((state, payload) => {
  const hasMore = state.itemsPagination.itemsPerPage === payload.length;

  state.items = payload;
  state.itemsPagination.page += 1;
  state.itemsPagination.hasMore = hasMore;
});

export default setMobileUsers;
