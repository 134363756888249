export enum Tab {
  ReadingLogs = 'ReadingLogs',
  PatientSummary = 'PatientSummary',
  BillingReport = 'BillingReport',
  PayrollReport = 'PayrollReport',
}

export enum DateRangeType {
  Week = 'Week',
  Month = 'Month',
}
