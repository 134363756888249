import initStore from './initStore';
import actions from './actions';
import thunks from './thunks';

import { ICarePlanIcd10CodesModel } from './types';

const carePlanIcd10Codes: ICarePlanIcd10CodesModel = {
  ...initStore,
  ...actions,
  ...thunks,
};

export default carePlanIcd10Codes;
