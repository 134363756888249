import axios from 'axios';
import { IResponse } from '~/services/api/types';
import { IIcd10Code } from './types';

const apiUrl = 'icd-10-codes';

export const create = (data: IIcd10Code): IResponse<IIcd10Code> =>
  axios({
    method: 'post',
    url: apiUrl,
    data,
  });

export default {};
