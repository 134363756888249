import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getPaginationPayload } from '~/utils/pagination';
import { IProgramsStore, IThunkGetPrograms } from '../types';

const onGetPrograms: IThunkGetPrograms = thunk(
  // @ts-ignore
  async ({ reset, setPrograms }, payload, { getState }) => {
    reset();
    const localState = getState() as IProgramsStore;
    const paginationPayload = getPaginationPayload(localState.pagination);

    const programs = await api.programs
      .getList({ ...payload, ...paginationPayload })
      .then(res => res.data);
    setPrograms(programs);
  },
);

export default onGetPrograms;
