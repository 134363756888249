import { DeviceType } from '~/services/api/enums';

export const deviceTypeOptions = [
  { value: DeviceType.BloodPressure, label: 'Blood Pressure' },
  { value: DeviceType.WeightScale, label: 'Weight Scale' },
  { value: DeviceType.PulseOximeter, label: 'Pulse Oximeter' },
  { value: DeviceType.BloodGlucose, label: 'Blood Glucose' },
];

export default {};
