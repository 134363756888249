import { useState, ReactElement, useEffect } from 'react';
import classnames from 'classnames';
import HomeIcon from '@mui/icons-material/Home';
import IconButton from '@material-ui/core/IconButton';
import ReorderIcon from '@mui/icons-material/Reorder';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { useStoreActions, useStoreState } from '~/store/hooks';
import Menu from './Menu';
import PatientPanel from './PatientPanel';

import { ActionType } from '~/ui/layouts/types';
import styles from './Header.module.scss';
import Notifications from './Notifications';
import { INotification } from '~/services/api/notifications/types';

interface IProps {
  isExpanded: boolean;
  patientId: string;
  onToggleSidebar: () => void;
  onAction: (actionType: ActionType, notification?: INotification) => void;
}

const Header = ({ isExpanded, patientId, onToggleSidebar, onAction }: IProps): ReactElement => {
  const isPatientLayout = Boolean(patientId);

  const notificationCount = useStoreState(state => state.notifications.notifyCount);
  const { onGetNotifyCount } = useStoreActions(store => store.notifications);

  const [showMenu, setShowMenu] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);

  const currentUser = useStoreState(state => state.auth.currentUser);
  const fullName = currentUser?.fullName;

  const handleMenuItemClick = (action: ActionType) => {
    setShowMenu(false);
    onAction(action);
  };

  const handleNotificationsItemClick = (action: ActionType, notification: INotification) => {
    setShowNotifications(false);
    onAction(action, notification);
  };

  useEffect(() => {
    onGetNotifyCount();
  }, []);

  return (
    <header className={classnames(styles.header, { [styles.patientHeader]: isPatientLayout })}>
      <div className={styles.leftContainer}>
        <div className={classnames(styles.spacer, { [styles.expanded]: isExpanded })} />
        <IconButton className={styles.toggleBtn} onClick={onToggleSidebar}>
          <ReorderIcon />
        </IconButton>
      </div>
      <div className={styles.rightContainer}>
        {Boolean(patientId) && <PatientPanel />}
        <div className={styles.block}>
          <div
            role="presentation"
            className={styles.inner}
            onClick={() => onAction(ActionType.Home)}
          >
            <HomeIcon />
          </div>
        </div>

        <div className={styles.block}>
          <div
            role="presentation"
            className={styles.inner}
            onClick={() => {
              setShowNotifications(true);
              onGetNotifyCount();
            }}
          >
            <div className={styles.NotificationIcon}>
              <NotificationsIcon />
              {notificationCount !== 0 && (
                <span className={styles.NotifyCount}>{notificationCount}</span>
              )}
            </div>
          </div>
          {showNotifications && (
            <Notifications
              onAction={handleNotificationsItemClick}
              onClose={() => setShowNotifications(false)}
            />
          )}
        </div>

        <div className={styles.block}>
          <div role="presentation" className={styles.inner} onClick={() => setShowMenu(true)}>
            <AccountCircleIcon />
            <span className={styles.title}>{fullName}</span>
          </div>
          <Menu show={showMenu} onAction={handleMenuItemClick} onClose={() => setShowMenu(false)} />
        </div>
      </div>
    </header>
  );
};

export default Header;
