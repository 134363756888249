import { ReactElement, useState, useEffect } from 'react';

import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import { formatDateToISO } from '~/utils/activityLogs';
import createFormInitialValues from './form/create/initialValues';
import getEditFormInitialValues from './form/edit/getInitialValues';
import Modal from '~/ui/components/common/Modal';
import Loader from '~/ui/components/common/Loader';
import Header from '~/ui/components/reusable/Notes/Header';
import ActivityLogOfflineForm from '~/ui/components/reusable/ActivityLogOfflineForm';
import ActivityLogsList from './ActivityLogsList';

import { UserRole } from '~/types';
import { IActivityLog } from '~/services/api/activityLogs/types';
import { IFormValues } from '~/ui/components/reusable/ActivityLogOfflineForm/types';
import styles from './LogOfflineActivities.module.scss';
import { ActivityLogMode } from '~/services/api/enums';

interface IProps {
  onClose: () => void;
  patientId: number;
}

const LogOfflineActivities = ({ onClose, patientId }: IProps): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [editActivityLog, setEditActivityLog] = useState<IActivityLog>(null);

  const patient = useStoreState(state => state.patient.info);
  const currentUser = useStoreState(state => state.auth.currentUser);

  const { onGetInfo: onGetPatientInfo } = useStoreActions(actions => actions.patient);
  const { onCreateActivityLog, onUpdateActivityLog } = useStoreActions(
    actions => actions.activityLogs,
  );
  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);

  const userRole = currentUser?.roleId;
  const userId = currentUser?.id;

  const permissions = {
    canManage: userRole === UserRole.SystemAdmin,
  };

  const handleCreateActivityLog = async (formValues: IFormValues, reset: any) => {
    const { dateTime, minutesSpent, note } = formValues;

    const payload = {
      patientId,
      data: {
        mode: ActivityLogMode.Offline,
        dateTime: formatDateToISO(dateTime, patient.timezone),
        secondsSpent: minutesSpent * 60, // convert from minutes to seconds
        note,
      } as IActivityLog,
    };

    try {
      setIsCreating(true);
      await onCreateActivityLog(payload);
      reset(createFormInitialValues); // reset form
      showNotify('Activity log successfully created');
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsCreating(false);
    }
  };

  const handleUpdateActivityLog = async (formValues: IFormValues) => {
    const { dateTime, minutesSpent, note } = formValues;

    const payload = {
      ...editActivityLog,
      mode: ActivityLogMode.Offline,
      dateTime: formatDateToISO(dateTime, patient.timezone),
      secondsSpent: minutesSpent * 60, // convert from minutes to seconds
      note,
    };

    setIsUpdating(true);

    try {
      await onUpdateActivityLog(payload);
      setEditActivityLog(null);
      showNotify('Activity log successfully updated');
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsUpdating(false);
    }
  };

  const onMount = async () => {
    try {
      await onGetPatientInfo(patientId);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  return (
    <Modal
      open
      title="Log Offline Activities"
      maxWidth="md"
      className={styles.modal}
      onClose={onClose}
    >
      <div className={styles.container}>
        {isLoading ? <Loader /> : <Header patient={patient} />}
        {!editActivityLog && !isLoading && (
          <>
            <ActivityLogOfflineForm
              initialValues={createFormInitialValues}
              isProcessing={isCreating}
              onSubmit={handleCreateActivityLog}
            />
            <ActivityLogsList
              patientInfo={patient}
              userId={userId}
              permissions={permissions}
              onEdit={setEditActivityLog}
            />
          </>
        )}
        {editActivityLog && (
          <ActivityLogOfflineForm
            mode="edit"
            showBackBtn
            initialValues={getEditFormInitialValues(editActivityLog)}
            isProcessing={isUpdating}
            onSubmit={handleUpdateActivityLog}
            onClose={() => setEditActivityLog(null)}
          />
        )}
      </div>
    </Modal>
  );
};

export default LogOfflineActivities;
