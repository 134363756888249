import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetPatient } from '../types';

const onGetPatient: IThunkGetPatient = thunk(
  // @ts-ignore
  async ({ setPersonalData }, patientId) => {
    const patient = await api.patients.get(patientId).then(res => res.data);

    setPersonalData(patient);

    return patient;
  },
);

export default onGetPatient;
