import { GroupTypeBase, Styles } from 'react-select';
import { IOption } from './types';

const customStyles: Partial<Styles<IOption, boolean, GroupTypeBase<IOption>>> = {
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menu: provided => ({
    ...provided,
    zIndex: 2,
  }),
  control: (provided, { isFocused }: any) => ({
    ...provided,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: isFocused ? '#1e89c2!important' : '#c4c4c4',
    borderRadius: '4px',
    minHeight: '40px',
    boxShadow: 'none',
    ':hover': {
      borderColor: '#1e1f21',
    },
  }),
  multiValue: provided => ({
    ...provided,
    color: '#fff',
    backgroundColor: '#1e89c2',
  }),
  multiValueLabel: provided => ({
    ...provided,
    color: '#fff',
  }),
  multiValueRemove: provided => ({
    ...provided,
    ':hover': {
      color: '#fff',
      backgroundColor: '#309fda',
      cursor: 'pointer',
    },
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '3px 8px',
  }),
  option: (provided, { isDisabled, isFocused, isSelected }: any) => ({
    ...provided,
    wordBreak: 'break-word',
    backgroundColor: (() => {
      if (isDisabled) return undefined;
      if (isSelected) return '#07a0df';
      if (isFocused) return '#e3f2fd';
      return undefined;
    })(),
  }),
};

export default customStyles;
