import { ReactElement } from 'react';
import classnames from 'classnames';

import styles from './SmallCard.module.scss';

interface IProps {
  colorType?: 'blue' | 'yellow' | 'green';
  iconComponent: any;
  title: string;
  onClick: () => void;
}

const SmallCard = ({
  colorType = 'blue',
  iconComponent: Icon,
  title,
  onClick,
}: IProps): ReactElement => (
  <div role="presentation" className={styles.smallCard} onClick={onClick}>
    <div className={styles.inner}>
      <Icon className={classnames(styles.icon, styles[colorType])} />
      <div className={styles.title}>{title}</div>
    </div>
  </div>
);

export default SmallCard;
