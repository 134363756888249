import SelectedDevicesList from '../SelectedDevicesList';
import { IDevice } from '~/services/api/devices/types';

interface IProps {
  title: string;
  devices: IDevice[];
  devicesIds: number[];
}

const ModalDescription = ({ title, devices, devicesIds }: IProps) => (
  <>
    <div>{title}</div>
    <SelectedDevicesList devices={devices} devicesIds={devicesIds} />
  </>
);

export default ModalDescription;
