import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkRemoveCarePlanMedication } from '../types';

const onRemoveCarePlanMedication: IThunkRemoveCarePlanMedication = thunk(
  // @ts-ignore
  async ({ removeCarePlanMedication }, id) => {
    await api.carePlanMedications.remove(id);
    removeCarePlanMedication(id);
  },
);

export default onRemoveCarePlanMedication;
