import { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';

import Input from '~/ui/components/inputs/Input';
import Select from '~/ui/components/inputs/Select';

import { countryOptions, stateOptions } from '~/ui/constants';
import { timezoneOptions } from '~/ui/constants/timezones';

const HomeAddress = (): ReactElement => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <Select
          name="country"
          control={control}
          errors={errors}
          options={countryOptions}
          label="Country *"
        />
      </Grid>
      <Grid item xs={4}>
        <Select
          name="state"
          control={control}
          errors={errors}
          options={stateOptions}
          label="State *"
        />
      </Grid>
      <Grid item xs={4}>
        <Input name="city" register={register} errors={errors} label="City *" />
      </Grid>
      <Grid item xs={4}>
        <Input name="address" register={register} errors={errors} label="Address *" />
      </Grid>
      <Grid item xs={4}>
        <Input name="zip" register={register} errors={errors} label="Zip *" />
      </Grid>
      <Grid item xs={4}>
        <Select
          name="timezone"
          control={control}
          errors={errors}
          options={timezoneOptions}
          label="Timezone *"
        />
      </Grid>
    </Grid>
  );
};

export default HomeAddress;
