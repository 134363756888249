import { secondsToMinutes } from '~/utils/convert';
import { parseDateFromISO } from '~/utils/activityLogs';
import { IActivityLog } from '~/services/api/activityLogs/types';
import { IFormValues } from '~/ui/components/reusable/ActivityLogOfflineForm/types';

const getInitialValues = (activityLog: IActivityLog): IFormValues => {
  // dateTime - is already in patient timezone (calculated on BE)
  const { dateTime, secondsSpent, note } = activityLog;

  return {
    dateTime: parseDateFromISO(dateTime),
    minutesSpent: secondsToMinutes(secondsSpent),
    note,
  };
};

export default getInitialValues;
