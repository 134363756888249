import { useState, ReactElement } from 'react';
import Modal from '~/ui/components/common/Modal';

import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import InsuranceForm from '~/ui/components/reusable/InsuranceForm';

import { IFormValues } from '~/ui/components/reusable/InsuranceForm/types';
import styles from './EditInsurance.module.scss';
import { IInsurance, IInsurancesSelectors } from '~/services/api/insurances/types';
import { isoToFormat } from '~/utils/date';

interface IProps {
  insurance: IInsurance;
  selectors: IInsurancesSelectors;
  onClose: () => void;
}

const EditInsurance = ({ insurance, selectors, onClose }: IProps): ReactElement => {
  const [isEditing, setIsEditing] = useState(false);

  const onUpdateInsurance = useStoreActions(actions => actions.insurances.onUpdateInsurance);
  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);

  const updateInsurance = async (data: IFormValues) => {
    try {
      setIsEditing(true);
      const payload = {
        ...insurance,
        ...data,
        expiresAt: isoToFormat(data.expiresAt, 'y-MM-dd'),
        authorizationStartDate: isoToFormat(data.authorizationStartDate, 'y-MM-dd'),
        authorizationEndDate: isoToFormat(data.authorizationEndDate, 'y-MM-dd'),
      };
      await onUpdateInsurance(payload);
      showNotify('Insurance successfully updated');
      onClose();
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsEditing(false);
    }
  };

  const handleClose = () => {
    // prevent closing popup when insurance is updating
    if (isEditing) return;
    onClose();
  };

  return (
    <Modal open title="Edit Insurance" className={styles.modal} onClose={handleClose}>
      <InsuranceForm
        selectors={selectors}
        initialValues={insurance}
        isProcessing={isEditing}
        onSubmit={updateInsurance}
      />
    </Modal>
  );
};

export default EditInsurance;
