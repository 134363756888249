import initStore from './initStore';
import actions from './actions';
import thunks from './thunks';

import { IPatientNotificationRulesModel } from './types';

const patientNotificationRules: IPatientNotificationRulesModel = {
  ...initStore,
  ...actions,
  ...thunks,
};

export default patientNotificationRules;
