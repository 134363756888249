import { DeviceType } from '~/services/api/enums';

import { Day, DayPart } from '~/types';
import { analysisCategories } from '../../types';
import { IDayPartReading } from '../types';

interface ILevelData {
  value: number;
  day: Day;
  dayPart: DayPart;
}

interface IIndicatorData {
  label: string;
  min: ILevelData;
  max: ILevelData;
}

interface IData {
  indicators: IIndicatorData[];
}

const getIndicatorData = (
  dayPartReadings: IDayPartReading[],
  valueIndex: number,
  func: (...values: number[]) => number,
): ILevelData => {
  const values = dayPartReadings.map(x => x.values[valueIndex]).filter(x => x !== null);

  if (values.length === 0) {
    return null;
  }

  const value = func(...values);

  const reading = dayPartReadings.find(x => x.values[valueIndex] === value);

  return {
    day: reading.day,
    dayPart: reading.dayPart,
    value: reading.values[valueIndex],
  };
};

export const getData = (dayPartReadings: IDayPartReading[], deviceType: DeviceType): IData => ({
  indicators: analysisCategories[deviceType].map((x, i) => ({
    label: x,
    min: getIndicatorData(dayPartReadings, i, Math.min),
    max: getIndicatorData(dayPartReadings, i, Math.max),
  })),
});

export default {};
