import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkCreateCarePlanIcd10Code } from '../types';

const onCreateCarePlanIcd10Code: IThunkCreateCarePlanIcd10Code = thunk(
  // @ts-ignore
  async ({ addCarePlanIcd10Code }, payload) => {
    const { patientId, data } = payload;
    const Icd10Code = await api.carePlanIcd10Codes.create(patientId, data).then(res => res.data);
    addCarePlanIcd10Code(Icd10Code);
  },
);

export default onCreateCarePlanIcd10Code;
