import { RuleType, RuleCondition } from '~/services/api/enums';

const initialValues = {
  typeId: RuleType.Systolic,
  conditionId: RuleCondition.Below,
  level1: 0,
  level2: 0,
  level3: 0,
};

export default initialValues;
