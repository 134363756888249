import { ReactElement } from 'react';
import classnames from 'classnames';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { headCells, ICell } from './constants';
import { Order } from '~/types';
import styles from './TableHead.module.scss';
import { ISort } from '~/utils/sort/types';

interface IProps {
  onRequestSort: (sort: ISort) => void;
  sort: ISort;
}

const CustomTableHead = ({ sort, onRequestSort }: IProps): ReactElement => {
  const createSortHandler = (property: string) => {
    onRequestSort({
      orderBy: property,
      isDescending: !sort.isDescending,
    });
  };

  const getSortDirection = (headCell: ICell) => {
    if (sort.orderBy !== headCell.id) {
      return false;
    }

    return sort.isDescending ? Order.Desc : Order.Asc;
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            sortDirection={getSortDirection(headCell)}
            width={headCell.width}
            align={headCell.align}
            className={classnames({ [styles.cellCenter]: headCell.align === 'center' })}
          >
            <TableSortLabel
              active={sort.orderBy === headCell.id}
              direction={sort.orderBy === headCell.id && sort.isDescending ? Order.Desc : Order.Asc}
              onClick={() => createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default CustomTableHead;
