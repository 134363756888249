import { action } from 'easy-peasy';
import { IActionSetUsers } from '../types';

const setUsers: IActionSetUsers = action((state, payload) => {
  const hasMore = state.itemsPagination.itemsPerPage === payload.length;

  state.items = payload;
  state.itemsPagination.page += 1;
  state.itemsPagination.hasMore = hasMore;
});

export default setUsers;
