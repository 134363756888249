import { object, string, ref } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { password } from '~/ui/constants/validation';

const firstLastSchema = object().shape({
  firstName: string().max(100, 'Max length - 100 symbols').required('Field is required'),
  lastName: string().max(100, 'Max length - 100 symbols').required('Field is required'),
});

export const firstLastNameValidationSchema = yupResolver(firstLastSchema);

export const changePassword = object().shape({
  password,
  confirmNewPassword: string()
    .oneOf([ref('password'), null], 'The passwords must match')
    .max(100, 'Max - 100 characters'),
});

export const changePasswordValidationSchema = yupResolver(changePassword);
