import { useState, ReactElement } from 'react';
import has from 'lodash/has';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { useStoreActions } from '~/store/hooks';
import { isoToFormat } from '~/utils/date';
import { extractErrorMessage } from '~/utils/error';
import { getProgramsName } from '~/ui/pages/Users/helper';
import Button from '~/ui/components/common/Button';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import EditUser from '../../popups/EditUser';
import ProgramModal from '../../popups/ProgramModal';
import AssignPatientsModal from '../../popups/AssignPatientsModal';
import AssignedPatientsModal from '../../popups/AssignedPatientsModal';

import { IUserList } from '~/services/api/users/types';
import { ITabPermissions } from '../types';
import styles from './Item.module.scss';

interface IProps {
  user: IUserList;
  permissions: ITabPermissions;
}

const Item = ({ user, permissions }: IProps): ReactElement => {
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showProgramPopup, setShowProgramPopup] = useState(false);
  const [showAssignPopup, setShowAssignPopup] = useState(false);
  const [showAssignedPopup, setShowAssignedPopup] = useState(false);
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const onRemoveUser = useStoreActions(actions => actions.users.onRemoveUser);
  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);

  const removeUser = async () => {
    setIsRemoving(true);

    try {
      await onRemoveUser(user.id);
      showNotify('User terminated');
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsRemoving(false);
    }
  };

  return (
    <>
      <TableRow>
        <TableCell className={styles.cell}>{user.fullName}</TableCell>
        <TableCell>{user.email}</TableCell>
        <TableCell>
          {has(user, 'lastLoginAt') ? isoToFormat(user.lastLoginAt, 'dd MMM y H:mm') : '-'}
        </TableCell>
        <TableCell className={styles.cell}>{getProgramsName(user.programs)}</TableCell>
        <TableCell>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={() => setShowProgramPopup(true)}
          >
            Program
          </Button>
        </TableCell>
        <TableCell>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={() => setShowAssignedPopup(true)}
          >
            Assigned Patients
          </Button>
        </TableCell>
        <TableCell>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={() => setShowAssignPopup(true)}
          >
            Assign Patients
          </Button>
        </TableCell>
        <TableCell width={80}>
          <IconButton className={styles.icon} title="Edit" onClick={() => setShowEditPopup(true)}>
            <CreateIcon />
          </IconButton>
        </TableCell>
        {permissions.canRemoveUser && (
          <TableCell align="center">
            <IconButton
              className={styles.icon}
              title="Remove"
              onClick={() => setShowRemoveConfirm(true)}
            >
              <DeleteIcon />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      {showEditPopup && <EditUser userId={user.id} onClose={() => setShowEditPopup(false)} />}
      {showProgramPopup && (
        <ProgramModal open userId={user.id} onClose={() => setShowProgramPopup(false)} />
      )}
      {showAssignPopup && (
        <AssignPatientsModal open userId={user.id} onClose={() => setShowAssignPopup(false)} />
      )}
      {showAssignedPopup && (
        <AssignedPatientsModal open userId={user.id} onClose={() => setShowAssignedPopup(false)} />
      )}
      {showRemoveConfirm && (
        <ConfirmModal
          title="Remove"
          description={`Are you sure you want to terminate user ${user.fullName}?`}
          isLoading={isRemoving}
          onClose={() => setShowRemoveConfirm(false)}
          onConfirm={removeUser}
        />
      )}
    </>
  );
};

export default Item;
