interface ICell {
  id: string;
  label: string;
  useSort: boolean;
  width?: number;
  align?: any;
}

export const headCells: ICell[] = [
  {
    id: '',
    label: '',
    useSort: false,
    width: 50,
  },
  {
    id: 'patientFullName',
    label: 'Patient Name',
    useSort: true,
  },
  {
    id: 'patientSubjectId',
    label: 'Patient ID',
    useSort: true,
  },
  {
    id: 'patientStatus',
    label: 'Status',
    useSort: true,
  },
  {
    id: 'programName',
    label: 'Program Name',
    useSort: true,
  },
  {
    id: 'activityMinutesCount',
    label: 'Activity Minutes',
    useSort: true,
    align: 'center',
  },
];

export default {};
