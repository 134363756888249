import { IMobileUser } from '~/services/api/mobileUsers/types';
import { IMobileUserFormValues } from '~/ui/components/reusable/MobileUserForm/types';

const getInitialValues = (mobileUser: IMobileUser): IMobileUserFormValues => {
  const { phone, isActive } = mobileUser;

  return {
    phone,
    isActive,
  };
};

export default getInitialValues;
