import { useState, ReactElement } from 'react';
import toUpper from 'lodash/toUpper';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';

import EditProgram from './EditProgram';

import { IProgramList } from '~/services/api/programs/types';
import { IPagePermissions } from '../../types';
import styles from './Item.module.scss';

interface IProps {
  program: IProgramList;
  permissions: IPagePermissions;
  onRemove: (program: IProgramList) => void;
}

const Item = ({ program, permissions, onRemove }: IProps): ReactElement => {
  const [showEditPopup, setShowEditPopup] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <>
            <div className={styles.title}>{toUpper(program.name)}</div>
            <div className={styles.timezone}>{program.timezone}</div>
          </>
        </TableCell>
        <TableCell width={50} align="center">
          <IconButton className={styles.icon} title="Edit" onClick={() => setShowEditPopup(true)}>
            <CreateIcon />
          </IconButton>
        </TableCell>
        {permissions.canRemoveProgram && (
          <TableCell width={50} align="center" className={styles.removeCell}>
            <IconButton className={styles.icon} title="Remove" onClick={() => onRemove(program)}>
              <DeleteIcon />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      {showEditPopup && (
        <EditProgram programId={program.id} onClose={() => setShowEditPopup(false)} />
      )}
    </>
  );
};

export default Item;
