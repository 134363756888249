import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkRemovePatients } from '../types';

// @ts-ignore
const onRemovePatients: IThunkRemovePatients = thunk(async (_actions, payload) => {
  const { id, patients } = payload;
  await api.mobileUsers.removePatients(id, patients);
});

export default onRemovePatients;
