import axios from 'axios';
import { IResponse } from '~/services/api/types';
import { IFile } from './types';

export const uploadFile = (data: FormData): IResponse<IFile> =>
  axios({
    method: 'post',
    url: 'files/upload',
    data,
  });

export default {};
