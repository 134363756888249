import { useState, useEffect, ReactElement } from 'react';

import api from '~/services/api';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import Loader from '~/ui/components/common/Loader';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import ActivatePatient from '~/ui/components/reusable/ActivatePatient';
import PatientProfile from './PatientProfile';
import Insurance from './Insurance';
import Medication from './Medication';
import { IPatient } from '~/services/api/patients/types';

interface IProps {
  patientId: number;
}

const PersonalData = ({ patientId }: IProps): ReactElement => {
  const [showDischargePopup, setShowDischargePopup] = useState(false);
  const [showActivatePopup, setShowActivatePopup] = useState(false);
  const [isDischarging, setIsDischarging] = useState(false);
  const [isActivating, setIsActivating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const patient = useStoreState(store => store.patient.personalData);

  const { onGetPatient, onUpdatePatient, onDischargePatient, onActivatePatient } = useStoreActions(
    actions => actions.patient,
  );
  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);

  const handleDischarge = async () => {
    try {
      setIsDischarging(true);
      onDischargePatient(patientId);
      showNotify('Patient discharged');
      setShowDischargePopup(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsDischarging(false);
    }
  };

  const handleActivate = async (deviceId: number) => {
    try {
      setIsActivating(true);
      onActivatePatient(patientId);
      await api.devices.registerDevices(patientId, [deviceId]);
      showNotify('Patient activated');
      setShowActivatePopup(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsActivating(false);
    }
  };

  const handleUpdatePatient = async (values: IPatient) => {
    try {
      setIsUpdating(true);

      const updatePayload = {
        ...patient,
        ...values,
      };

      await onUpdatePatient(updatePayload);

      showNotify('Patient successfully updated');
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsUpdating(false);
    }
  };

  const onMount = async () => {
    try {
      await onGetPatient(patientId);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  if (!patient) {
    return <Loader />;
  }

  return (
    <>
      <PatientProfile
        isProcessing={isUpdating}
        patient={patient}
        onUpdate={handleUpdatePatient}
        onRequestDischarge={() => setShowDischargePopup(true)}
        onRequestActivate={() => setShowActivatePopup(true)}
      />
      <Insurance patientId={patientId} />
      <Medication patient={patient} />
      {showDischargePopup && (
        <ConfirmModal
          title="Request Discharge"
          description="Are you sure you want to discharge current patient?"
          isLoading={isDischarging}
          onClose={() => setShowDischargePopup(false)}
          onConfirm={handleDischarge}
        />
      )}
      {showActivatePopup && (
        <ActivatePatient
          patientId={patientId}
          isProcessing={isActivating}
          onClose={() => setShowActivatePopup(false)}
          onSubmit={handleActivate}
        />
      )}
    </>
  );
};

export default PersonalData;
