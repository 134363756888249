import { useState, useEffect } from 'react';

import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';

import Modal from '~/ui/components/common/Modal';
import Loader from '~/ui/components/common/Loader';
import HistoryList from './HistoryList';

import { INITIAL_STATE } from './constants';
import {} from '~/services/api/enums';
import { IDeviceHistoryLog } from '~/services/api/devices/types';
import { IDeviceHistoryState } from './types';
import styles from './HistoryModal.module.scss';
import { getPaginationPayload } from '~/utils/pagination';
import api from '~/services/api';

interface IProps {
  deviceId: number;
  onClose: () => void;
}

const HistoryModal = ({ deviceId, onClose }: IProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [history, setHistory] = useState<IDeviceHistoryState>(INITIAL_STATE);

  const { showError } = useStoreActions(actions => actions.snackbar);

  const saveResult = (items: IDeviceHistoryLog[]) => {
    setHistory(prev => ({
      items: [...prev.items, ...items],
      pagination: {
        ...prev.pagination,
        page: prev.pagination.page + 1,
        hasMore: prev.pagination.itemsPerPage === items.length,
      },
    }));
  };

  const onGetDeviceHistory = async () => {
    try {
      const paginationPayload = getPaginationPayload(history.pagination);
      const items = await api.devices
        .getDeviceHistory(deviceId, paginationPayload)
        .then(res => res.data);
      saveResult(items);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onGetDeviceHistory();
  }, []);

  return (
    <Modal open title="History" maxWidth="md" className={styles.modal} onClose={onClose}>
      <div className={styles.container}>
        {isLoading ? (
          <Loader />
        ) : (
          <HistoryList
            items={history.items}
            hasMore={history.pagination.hasMore}
            onLoadMore={onGetDeviceHistory}
          />
        )}
      </div>
    </Modal>
  );
};

export default HistoryModal;
