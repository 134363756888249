import { useEffect, useState, ReactElement } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import InfiniteScroll from 'react-infinite-scroll-component';

import api from '~/services/api';
import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import Loader from '~/ui/components/common/Loader';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import NoDataFound from '~/ui/components/reusable/NoDataFound';
import Item from './Item';

import { UserRole } from '~/types';
import styles from './NotesList.module.scss';
import { INote } from '~/services/api/notes/types';

interface IProps {
  patientId: number;
  onEdit: (note: INote) => void;
}

const NotesList = ({ patientId, onEdit }: IProps): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteNoteId, setDeleteNoteId] = useState<number>(null);

  const currentUser = useStoreState(state => state.auth.currentUser);
  const { items: notes, pagination } = useStoreState(state => state.notes);

  const { onGetNotes, onGetMoreNotes } = useStoreActions(actions => actions.notes);
  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);

  const userRole = currentUser?.roleId;
  const userId = currentUser?.id;

  const permissions = {
    canManage: userRole === UserRole.SystemAdmin,
  };

  const onMount = async () => {
    try {
      await onGetNotes({ patientId });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  const onLoadMore = async () => {
    try {
      await onGetMoreNotes({ patientId });
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const removeNote = async () => {
    try {
      setIsDeleting(true);
      await api.notes.remove(deleteNoteId);
      onMount();
      setDeleteNoteId(null);
      showNotify('Note removed');
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsDeleting(false);
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <TableContainer id="scrollableDiv" className={styles.tableContainer}>
      <InfiniteScroll
        next={onLoadMore}
        hasMore={pagination.hasMore}
        dataLength={notes.length}
        loader={<Loader />}
        scrollableTarget="scrollableDiv"
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Author</TableCell>
              <TableCell>Note</TableCell>
              <TableCell>Telephone Conversation</TableCell>
              <TableCell>Reminder to follow up</TableCell>
              <TableCell width={100}>Note Type</TableCell>
              <TableCell align="center" width={70}>
                Edit
              </TableCell>
              <TableCell align="center" width={80}>
                Remove
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notes.length ? (
              notes.map(note => (
                <Item
                  key={note.id}
                  note={note}
                  permissions={permissions}
                  userId={userId}
                  onEdit={onEdit}
                  onDelete={setDeleteNoteId}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <NoDataFound />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </InfiniteScroll>
      {deleteNoteId && (
        <ConfirmModal
          title="Remove"
          description="Are you sure you want to remove current note?"
          isLoading={isDeleting}
          onClose={() => setDeleteNoteId(null)}
          onConfirm={removeNote}
        />
      )}
    </TableContainer>
  );
};

export default NotesList;
