import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getPaginationPayload } from '~/utils/pagination';
import { IThunkGetActivityLogs, IActivityLogsStore } from '../types';

const onGetActivityLogs: IThunkGetActivityLogs = thunk(
  // @ts-ignore
  async ({ setActivityLogs, reset }, filters, { getState }) => {
    reset();

    const localState = getState() as IActivityLogsStore;

    const paginationPayload = getPaginationPayload(localState.pagination);

    const { data } = await api.activityLogs.getList({
      ...filters,
      ...paginationPayload,
    });

    setActivityLogs(data);
  },
);

export default onGetActivityLogs;
