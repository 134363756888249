import { useState, ReactElement } from 'react';
import Modal from '~/ui/components/common/Modal';

import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import MedicationForm from '~/ui/components/reusable/MedicationForm';

import { IFormValues } from '~/ui/components/reusable/MedicationForm/types';
import styles from './EditMedication.module.scss';
import { IMedication } from '~/services/api/medications/types';

interface IProps {
  medication: IMedication;
  onClose: () => void;
}

const EditMedication = ({ medication, onClose }: IProps): ReactElement => {
  const { id, ...initialValues } = medication;

  const [isEditing, setIsEditing] = useState(false);

  const onUpdateMedication = useStoreActions(actions => actions.medications.onUpdateMedication);
  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);

  const updateMedication = async (data: IFormValues) => {
    try {
      setIsEditing(true);
      const payload = {
        ...medication,
        ...data,
      };
      await onUpdateMedication(payload);
      showNotify('Medication successfully updated');
      onClose();
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsEditing(false);
    }
  };

  const handleClose = () => {
    // prevent closing popup when medication is updating
    if (isEditing) return;
    onClose();
  };

  return (
    <Modal open title="Edit Medication" className={styles.modal} onClose={handleClose}>
      <MedicationForm
        initialValues={initialValues}
        isProcessing={isEditing}
        onSubmit={updateMedication}
      />
    </Modal>
  );
};

export default EditMedication;
