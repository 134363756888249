import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getPaginationPayload } from '~/utils/pagination';
import { IProgramsStore, IThunkGetMorePrograms } from '../types';

const onGetMorePrograms: IThunkGetMorePrograms = thunk(
  // @ts-ignore
  async ({ setMorePrograms }, payload, { getState }) => {
    const localState = getState() as IProgramsStore;
    const paginationPayload = getPaginationPayload(localState.pagination);

    const programs = await api.programs
      .getList({ ...payload, ...paginationPayload })
      .then(res => res.data);
    setMorePrograms(programs);
  },
);

export default onGetMorePrograms;
