import { ReactElement } from 'react';

import { Tooltip } from '@material-ui/core';
import { getMeasurementData } from '../helper';
import { isoToFormat } from '~/utils/date';

import { Timezone } from '~/types';
import { IPulseOximeterReading } from '~/services/api/readings/types';

import styles from './PulseOximeter.module.scss';
import { INotificationRule } from '~/services/api/notificationRules/types';
import Icon from './PulseOximeter.svg';
import { RuleType } from '~/services/api/enums';

interface IProps {
  reading: IPulseOximeterReading;
  rules: INotificationRule[];
  timezone: Timezone;
}

const PulseOximeter = ({ reading, rules, timezone }: IProps): ReactElement => {
  const pulsOximeterBpmData = getMeasurementData({
    typeId: RuleType.POPulserate,
    value: reading.pulseBpm,
    rules,
  });

  const pulsOximeterSpoData = getMeasurementData({
    typeId: RuleType.POSPO2,
    value: reading.spo2,
    rules,
  });

  const measurementDate = `${isoToFormat(reading.measuredAt, 'dd MMM y HH:mm')} ${timezone}`;

  const receivedBySystem = `Received by system: ${isoToFormat(
    reading.createdAt,
    'dd MMM y HH:mm',
  )} ${timezone}`;

  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <Tooltip title="Pulse Oximeter">
          <img src={Icon} alt="" />
        </Tooltip>
      </div>
      <div className={styles.info}>
        <div className={styles.measurements}>
          <div className={pulsOximeterSpoData.className}>
            <span className={styles.value}>{pulsOximeterSpoData.value}</span>
            <span className={styles.unit}>spo2</span>
          </div>
          <div className={pulsOximeterBpmData.className}>
            <span className={styles.value}>{pulsOximeterBpmData.value}</span>
            <span className={styles.unit}>bpm</span>
          </div>
        </div>
        <div className={styles.date} title={receivedBySystem}>
          {measurementDate}
        </div>
      </div>
      <div className={styles.statusIcon} />
    </div>
  );
};

export default PulseOximeter;
