import PrintIcon from '@mui/icons-material/Print';

import Button from '~/ui/components/common/Button';
import styles from './Print.module.scss';

interface IProps {
  isLoading: boolean;
  onPrint: () => void;
}

const Print = ({ isLoading, onPrint }: IProps) => (
  <div className={styles.print}>
    <Button isLoading={isLoading} disabled={isLoading} onClick={onPrint}>
      <PrintIcon className={styles.icon} />
      <span className={styles.text}>Print</span>
    </Button>
  </div>
);

export default Print;
