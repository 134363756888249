import onGetCarePlanHospitalizations from './onGetCarePlanHospitalizations';
import onCreateCarePlanHospitalization from './onCreateCarePlanHospitalization';
import onUpdateCarePlanHospitalization from './onUpdateCarePlanHospitalization';
import onRemoveCarePlanHospitalization from './onRemoveCarePlanHospitalization';

export default {
  onGetCarePlanHospitalizations,
  onCreateCarePlanHospitalization,
  onUpdateCarePlanHospitalization,
  onRemoveCarePlanHospitalization,
};
