import { ReactElement, ReactNode } from 'react';
import { CircularProgress, Button, ButtonProps } from '@material-ui/core';

import styles from './Button.module.scss';

interface ICommon {
  children?: ReactNode;
  isLoading?: boolean;
}

type IProps = ICommon & ButtonProps;

// This component extends basic MUI Button component
// It supports: isLoading (loader spinner)
const ButtonComponent = ({ children, isLoading = false, ...props }: IProps): ReactElement => (
  <Button {...props}>
    <>
      {children}
      {isLoading && (
        <>
          <div className={styles.spacer} />
          <CircularProgress size={20} />
        </>
      )}
    </>
  </Button>
);

export default ButtonComponent;
