import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';

import validate from './form/validate';
import Modal from '~/ui/components/common/Modal';
import Button from '~/ui/components/common/Button';
import Input from '~/ui/components/inputs/Input';
import Textarea from '~/ui/components/inputs/Textarea';

import { IFormValues } from './form/types';
import styles from './MedicationModal.module.scss';

interface IProps {
  mode?: 'create' | 'edit';
  initialValues: IFormValues;
  isProcessing: boolean;
  onClose: () => void;
  onSubmit: (values: IFormValues) => void;
}

const MedicationModal = ({
  mode = 'create',
  initialValues,
  isProcessing,
  onClose,
  onSubmit,
}: IProps): ReactElement => {
  const title = mode === 'create' ? 'Add Medication' : 'Update Medication';

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: validate,
    defaultValues: initialValues,
  });

  return (
    <Modal open maxWidth="sm" title={title} className={styles.modal} onClose={onClose}>
      <div className={styles.inner}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Input name="name" label="Name *" register={register} errors={errors} />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={12}>
              <Textarea name="reason" label="Reason" register={register} errors={errors} />
            </Grid>
          </Grid>
          <div className={styles.btnWrapper}>
            <Button variant="outlined" color="default" disabled={isProcessing} onClick={onClose}>
              Cancel
            </Button>
            <div className={styles.widthSpacer} />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              isLoading={isProcessing}
              disabled={isProcessing}
            >
              {mode === 'create' ? 'Create' : 'Save'}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default MedicationModal;
