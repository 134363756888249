import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkRegisterDevice } from '../types';

const onRegisterDevice: IThunkRegisterDevice = thunk(async (_actions, payload) => {
  const { patientId, deviceList } = payload;
  await api.devices.registerDevices(patientId, deviceList);
});

export default onRegisterDevice;
