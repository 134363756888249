import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getPaginationPayload } from '~/utils/pagination';
import { IDevicesStore, IThunkGetMoreDevices } from '../types';

const onGetMoreDevices: IThunkGetMoreDevices = thunk(
  // @ts-ignore
  async ({ setMoreDevices }, payload, { getState }) => {
    const localState = getState() as IDevicesStore;
    const paginationPayload = getPaginationPayload(localState.pagination);

    const devices = await api.devices
      .getDevices({
        ...payload,
        ...paginationPayload,
      })
      .then(res => res.data);

    setMoreDevices(devices);
  },
);

export default onGetMoreDevices;
