import KeyboardReturnOutlinedIcon from '@mui/icons-material/KeyboardReturnOutlined';
import PhonelinkEraseOutlinedIcon from '@mui/icons-material/PhonelinkEraseOutlined';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';

import { DeviceHistoryLogEvent } from '~/services/api/enums';
import { IDeviceHistoryState } from './types';

export const INITIAL_STATE: IDeviceHistoryState = {
  items: [],
  pagination: {
    page: 0,
    itemsPerPage: 20,
    hasMore: false,
  },
};

export const EVENT_ICONS = {
  [DeviceHistoryLogEvent.AssignToProgram]: <LoginIcon />,
  [DeviceHistoryLogEvent.UnassignFromProgram]: <LogoutIcon />,
  [DeviceHistoryLogEvent.RegisterToPatient]: <PersonAddIcon />,
  [DeviceHistoryLogEvent.UnregisterFromPatient]: <PersonRemoveIcon />,
  [DeviceHistoryLogEvent.MarkAsReturned]: <KeyboardReturnOutlinedIcon />,
  [DeviceHistoryLogEvent.MarkAsLost]: <PhonelinkEraseOutlinedIcon />,
};
