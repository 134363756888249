import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetInfo } from '../types';

const onGetInfo: IThunkGetInfo = thunk(
  // @ts-ignore
  async ({ setInfo, reset }, patientId) => {
    reset();

    const patientInfo = await api.patients.getInfo(patientId).then(res => res.data);

    setInfo(patientInfo);

    return patientInfo;
  },
);

export default onGetInfo;
