import { ReactElement } from 'react';
import { components, MultiValueProps } from 'react-select';

import { IOption } from '../types';

const MultiValue = (props: MultiValueProps<IOption>): ReactElement => {
  const { data } = props;

  return (
    <components.MultiValue {...props}>
      <span>{data?.label}</span>
    </components.MultiValue>
  );
};

export default MultiValue;
