import onGetCarePlanMedications from './onGetCarePlanMedications';
import onCreateCarePlanMedication from './onCreateCarePlanMedication';
import onUpdateCarePlanMedication from './onUpdateCarePlanMedication';
import onRemoveCarePlanMedication from './onRemoveCarePlanMedication';

export default {
  onGetCarePlanMedications,
  onCreateCarePlanMedication,
  onUpdateCarePlanMedication,
  onRemoveCarePlanMedication,
};
