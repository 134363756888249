import { ReactElement } from 'react';

import Button from '~/ui/components/common/Button';
import Modal from '~/ui/components/common/Modal';

import styles from './ConfirmModal.module.scss';

interface IProps {
  title: string;
  description?: string | ReactElement;
  isLoading?: boolean;
  onConfirm: () => void;
  onClose?: () => void;
}

const ConfirmModal = ({
  title,
  description,
  isLoading = false,
  onClose,
  onConfirm,
}: IProps): ReactElement => {
  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  return (
    <Modal open title={title} className={styles.modal} onClose={handleClose}>
      <div className={styles.inner}>
        <div className={styles.description}>{description}</div>
        <div className={styles.buttonsWrapper}>
          <Button
            color="primary"
            variant="outlined"
            className={styles.btnCancel}
            disabled={isLoading}
            onClick={handleClose}
          >
            No
          </Button>
          <Button
            color="primary"
            variant="contained"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={onConfirm}
          >
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
