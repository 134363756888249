import { ReactElement } from 'react';
import Select, { createFilter, ValueType } from 'react-select';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import get from 'lodash/get';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import MenuList from './MenuList';

import { IOption } from './types';
import customStyles from './customStyles';
import styles from './SelectVirtualized.module.scss';

type IValue = string | number;

type IsMulti = boolean;

interface IProps {
  control: Control<any>;
  errors: FieldErrors;
  name: string;
  options: IOption[];
  label?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  isSearchable?: boolean;
  maxMenuHeight?: number;
  isMulti?: boolean;
  placeholder?: string;
}

const SelectComponent = ({
  control,
  errors,
  name,
  options,
  label,
  isDisabled = false,
  isLoading = false,
  isMulti = false,
  isSearchable = true,
  maxMenuHeight = 180,
  placeholder = '',
}: IProps): ReactElement => {
  const error = get(errors, `${name}.message`, '');

  const formatValue = (option: ValueType<IOption | IOption[], IsMulti>) => {
    const value = isMulti
      ? (option as IOption[]).map((item: IOption) => item.value)
      : (option as IOption)?.value;
    return value;
  };

  const getValue = (value: IValue | IValue[]): IOption | IOption[] => {
    if (options) {
      return isMulti && Array.isArray(value)
        ? options.filter(option => value.indexOf(option.value) >= 0)
        : options.find(option => option.value === value) || { value: '', label: '' };
    }

    return isMulti ? [] : ('' as any);
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <FormControl component="fieldset" className={styles.selectControl}>
          <FormLabel component="legend" className={styles.label}>
            {label}
          </FormLabel>
          <Select
            filterOption={createFilter({ ignoreAccents: false })}
            instanceId={name}
            styles={customStyles}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isSearchable={isSearchable}
            maxMenuHeight={maxMenuHeight}
            name={name}
            options={options}
            placeholder={placeholder}
            isMulti={isMulti}
            value={getValue(value)}
            onChange={val => onChange(formatValue(val))}
            components={{ MenuList }}
          />
          {error && <FormHelperText error>{error}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

export default SelectComponent;
