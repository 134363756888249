import { ReactElement } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { getData, getChartOptions, dataExists } from './helper';
import GraphCard from '~/ui/components/reusable/GraphCard';
import { IReading } from '~/services/api/readings/types';
import { DeviceType } from '~/services/api/enums';

interface IProps {
  readings: IReading[];
  deviceTypeId: DeviceType;
}

const MorningEvening = ({ readings, deviceTypeId }: IProps): ReactElement => {
  const title = 'Average - Morning/Evening';

  const data = getData(readings, deviceTypeId);

  if (!dataExists(data)) {
    return <GraphCard title={title} showNoData />;
  }

  const options = getChartOptions(data, deviceTypeId);

  return (
    <GraphCard title={title}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </GraphCard>
  );
};

export default MorningEvening;
