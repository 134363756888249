import setPatients from './setPatients';
import setMorePatients from './setMorePatients';
import reset from './reset';
import activatePatient from './activatePatient';

export default {
  setPatients,
  setMorePatients,
  reset,
  activatePatient,
};
