import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getPaginationPayload } from '~/utils/pagination';
import { IThunkGetUsers, IUsersStore } from '../types';

// @ts-ignore
const onGetUsers: IThunkGetUsers = thunk(async ({ setMoreUsers }, payload, { getState }) => {
  const localState = getState() as IUsersStore;
  const paginationPayload = getPaginationPayload(localState.itemsPagination);

  const users = await api.users
    .getList({
      ...payload,
      ...paginationPayload,
    })
    .then(res => res.data);

  setMoreUsers(users);
});

export default onGetUsers;
