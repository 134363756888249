import { useState, ReactElement, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import { getCounterDurationSec } from '~/ui/components/reusable/ActivityCounter/helper';
import Sidebar from '~/ui/components/layouts/Sidebar';
import Header from '~/ui/components/layouts/Header';
import Footer from '~/ui/components/layouts/Footer';
import Snackbar from '~/ui/components/layouts/Snackbar';
import ActivityCounter from '~/ui/components/reusable/ActivityCounter';
import AddActivityLogOnline from '~/ui/components/layouts/AddActivityLogOnline';

import { DASHBOARD, MOBILE_USERS, PROFILE } from '~/ui/constants/paths';
import { ActionType } from '~/ui/layouts/types';
import styles from './GeneralLayout.module.scss';
import { UserRole } from '~/types';
import { INotification } from '~/services/api/notifications/types';

interface IProps {
  children: ReactNode;
  patientId?: string;
}

const GeneralLayout = ({ children, patientId = '' }: IProps): ReactElement => {
  const isPatientLayout = Boolean(patientId);
  const initialCount = getCounterDurationSec();

  const history = useHistory();

  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
  const [action, setAction] = useState<ActionType>(null);
  const [latestNotification, setLatestNotification] = useState(null);

  const { currentUser } = useStoreState(store => store.auth);

  const onLogout = useStoreActions(actions => actions.auth.onLogout);
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const patient = useStoreState(state => state.patient.info);
  const onGetInfo = useStoreActions(actions => actions.patient.onGetInfo);

  const logout = async () => {
    try {
      await onLogout();
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const runAction = async (actionType: ActionType, notification: INotification) => {
    setAction(null);

    switch (actionType) {
      case ActionType.Home: {
        history.push(DASHBOARD);
        break;
      }
      case ActionType.Profile: {
        history.push(PROFILE);
        break;
      }
      case ActionType.Patient: {
        history.push(
          `/patient/${notification.patientId}/dashboard/overview?deviceTypeId=${notification.deviceTypeId}`,
        );
        await onGetInfo(notification.patientId);
        break;
      }
      case ActionType.LogOut: {
        logout();
        break;
      }
      case ActionType.MobileUsers: {
        history.push(MOBILE_USERS);
        break;
      }
      case ActionType.Notes: {
        history.push(`/patient/${notification.patientId}/notes?id=${notification.noteId}`);
        await onGetInfo(notification.patientId);
        break;
      }
      default: {
        break;
      }
    }
  };

  const onAction = (actionType: ActionType, notification: INotification) => {
    if (isPatientLayout) {
      setLatestNotification(notification);

      if (currentUser.roleId !== UserRole.SystemAdmin) {
        setAction(actionType);
        return;
      }
    }

    runAction(actionType, notification);
  };

  return (
    <>
      <div className={styles.layout}>
        <Sidebar isExpanded={isSidebarExpanded} patientId={patientId} />
        <div className={classnames(styles.main, { [styles.expanded]: isSidebarExpanded })}>
          <Header
            isExpanded={isSidebarExpanded}
            patientId={patientId}
            onToggleSidebar={() => setIsSidebarExpanded(prev => !prev)}
            onAction={onAction}
          />
          <div className={styles.content}>{children}</div>
          <Footer />
        </div>
      </div>
      <Snackbar />
      {isPatientLayout && currentUser.roleId !== UserRole.SystemAdmin && (
        <ActivityCounter
          patient={patient}
          initialCount={initialCount}
          className={styles.counterContainer}
        />
      )}
      {action && (
        <AddActivityLogOnline
          patientId={Number(patientId)}
          onClose={() => setAction(null)}
          onFinish={() => runAction(action, latestNotification)}
        />
      )}
    </>
  );
};

export default GeneralLayout;
