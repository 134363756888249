import { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';

import Input from '~/ui/components/inputs/Input';
import Select from '~/ui/components/inputs/Select';
import SelectVirtualized from '~/ui/components/inputs/SelectVirtualized';
import DatePicker from '~/ui/components/inputs/DatePicker';

import { ethnicityOptions, genderOptions } from '~/ui/constants';
import { IOption } from '~/ui/components/inputs/Select/types';
import PhoneFlagInput from '~/ui/components/inputs/PhoneFlag';
import { useStoreState } from '~/store/hooks';
import { UserPermission, UserRole } from '~/types';

interface IProps {
  managerOptions: IOption[];
}

const GeneralInfo = ({ managerOptions }: IProps): ReactElement => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  const currentUser = useStoreState(store => store.auth.currentUser);

  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <SelectVirtualized
          name="healthCareManagerIds"
          control={control}
          errors={errors}
          options={managerOptions}
          isMulti
          label="Healthcare manager *"
          isDisabled={
            currentUser.roleId === UserRole.HealthManager &&
            !currentUser.permissions.includes(UserPermission.ManageUsers)
          }
        />
      </Grid>
      <Grid item xs={4}>
        <Input name="subjectId" register={register} errors={errors} label="Patient ID *" />
      </Grid>
      <Grid item xs={4}>
        <DatePicker
          name="dateOfBirth"
          maxDate={new Date()}
          control={control}
          errors={errors}
          label="Date of Birth"
        />
      </Grid>
      <Grid item xs={4}>
        <Input name="firstName" register={register} errors={errors} label="First Name *" />
      </Grid>
      <Grid item xs={4}>
        <Input name="middleName" register={register} errors={errors} label="Middle Name" />
      </Grid>
      <Grid item xs={4}>
        <Input name="lastName" register={register} errors={errors} label="Last Name *" />
      </Grid>
      <Grid item xs={4}>
        <PhoneFlagInput name="phoneNumber" control={control} errors={errors} label="Phone *" />
      </Grid>
      <Grid item xs={4}>
        <Select
          name="ethnicity"
          control={control}
          errors={errors}
          options={ethnicityOptions}
          label="Ethnicity"
        />
      </Grid>
      <Grid item xs={4}>
        <Select
          name="gender"
          control={control}
          errors={errors}
          options={genderOptions}
          label="Gender"
        />
      </Grid>
    </Grid>
  );
};

export default GeneralInfo;
