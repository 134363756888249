import { useState, useEffect, ReactElement } from 'react';

import api from '~/services/api';
import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import Modal from '~/ui/components/common/Modal';
import Loader from '~/ui/components/common/Loader';
import Devices from './Devices';
import PatientDetails from './PatientDetails';
import ActivityDetails from './ActivityDetails';

import { IPatientDetails } from '~/services/api/patients/types';
import styles from './InfoModal.module.scss';

interface IProps {
  patientId: number;
  onClose: () => void;
}

const InfoModal = ({ patientId, onClose }: IProps): ReactElement => {
  const [isLoading, setLoading] = useState(true);
  const [patient, setPatient] = useState<IPatientDetails>({} as IPatientDetails);

  const showError = useStoreActions(actions => actions.snackbar.showError);

  const onMount = async () => {
    try {
      const patientData = await api.patients.getDetails(patientId).then(res => res.data);

      setPatient(patientData);
      setLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  return (
    <Modal open title="Details" maxWidth="md" className={styles.modal} onClose={onClose}>
      <div className={styles.container}>
        {isLoading && <Loader />}
        {!isLoading && patient && (
          <>
            <Devices patient={patient} />
            <PatientDetails patient={patient} />
            <ActivityDetails patient={patient} />
          </>
        )}
      </div>
    </Modal>
  );
};

export default InfoModal;
