import initStore from './initStore';
import actions from './actions';
import thunks from './thunks';

import { IInsurancesModel } from './types';

const insurances: IInsurancesModel = {
  ...initStore,
  ...actions,
  ...thunks,
};

export default insurances;
