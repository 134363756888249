import { ReactElement } from 'react';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';

import { getSignalLevel } from '~/utils/deviceReading';
import { levels } from '../constants';
import styles from './Signal.module.scss';

interface IProps {
  levelValue: number;
  showLabel?: boolean;
}

const Signal = ({ levelValue, showLabel = true }: IProps): ReactElement => {
  const signalLevel = getSignalLevel(levelValue);
  const levelName = levels[signalLevel].title;
  const { color } = levels[signalLevel];

  return (
    <div className={styles.signal} title={`Signal strength (${levelName})`}>
      <SignalCellularAltIcon style={{ color }} className={styles.signal} />
      {showLabel && <span className={styles.title}>{`(${levelName})`}</span>}
    </div>
  );
};

export default Signal;
