import { ReactElement } from 'react';

import { isoToFormat } from '~/utils/date';
import Signal from './Signal';
import Battery from './Battery';

import { Timezone } from '~/types';
import styles from './DeviceStatus.module.scss';
import { IPatientDevice } from '~/services/api/devices/types';

interface IProps {
  device: IPatientDevice;
  timezone: Timezone;
}

const DeviceStatus = ({ device, timezone }: IProps): ReactElement => {
  const { lastReading } = device;

  if (!lastReading) {
    return <>-</>;
  }

  return (
    <div className={styles.deviceStatus}>
      <div className={styles.indicators}>
        <div className={styles.indicator}>
          {!!lastReading.signalLevel && <Signal levelValue={lastReading.signalLevel} />}
        </div>
        <div className={styles.indicator}>
          {!!lastReading.batteryLevel && <Battery levelValue={lastReading.batteryLevel} />}
        </div>
      </div>
      <div className={styles.date}>
        {lastReading.measuredAt
          ? `${isoToFormat(lastReading.measuredAt, 'dd MMM y H:mm')} ${timezone}`
          : '-'}
      </div>
    </div>
  );
};

export default DeviceStatus;
