import { ReactElement } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import { getDayPartReadings } from './helper';
import { getChartsData } from './DaysReading/helper';
import Analysis from './Analysis';
import MaxMinReading from './MaxMinReading';
import DaysReadingCount from './DaysReadingCount';
import DaysReading from './DaysReading';

import { IReading } from '~/services/api/readings/types';
import styles from './WeeklyAnalysis.module.scss';
import { DeviceType } from '~/services/api/enums';
import { getAverageData } from './Analysis/helper';

interface IProps {
  readings: IReading[];
  deviceTypeId: DeviceType;
}

const WeeklyAnalysis = ({ readings, deviceTypeId }: IProps): ReactElement => {
  const noData = readings.length === 0;

  const dayPartReadings = getDayPartReadings(readings, deviceTypeId);

  const averageData = getAverageData(dayPartReadings);

  const chartsData = getChartsData(averageData, deviceTypeId);

  return (
    <>
      <Paper elevation={24} square className={styles.container}>
        <div className={styles.title}>Weekly Analysis</div>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Analysis averageData={averageData} />
          </Grid>
          <Grid item xs={12}>
            <MaxMinReading dayPartReadings={dayPartReadings} deviceTypeId={deviceTypeId} />
          </Grid>
        </Grid>
      </Paper>
      <div className={styles.spacer} />
      <Paper elevation={24} square className={styles.container}>
        <div className={styles.title}>Weekly Graphs</div>
        <Grid container spacing={3}>
          {chartsData.charts.map(chart => (
            <Grid item xs={6} key={chart.label}>
              <DaysReading noData={noData} title={chart.label} chartOptions={chart.options} />
            </Grid>
          ))}
          <Grid item xs={6}>
            <DaysReadingCount noData={noData} dayPartReadings={dayPartReadings} />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default WeeklyAnalysis;
