import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkUpdatePatient } from '../types';

const onUpdatePatient: IThunkUpdatePatient = thunk(
  // @ts-ignore
  async ({ setPersonalData }, payload) => {
    const patient = await api.patients.update(payload).then(res => res.data);

    setPersonalData(patient);
  },
);

export default onUpdatePatient;
