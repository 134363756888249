import { ReactElement } from 'react';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';

import NoDataFound from '~/ui/components/reusable/NoDataFound';
import Item from './Item';

import { IInsurance, IInsurancesSelectors } from '~/services/api/insurances/types';
import { ISectionPermissions } from '../types';

interface IProps {
  insurances: IInsurance[];
  selectors: IInsurancesSelectors;
  permissions: ISectionPermissions;
}

const TableComponent = ({ insurances, selectors, permissions }: IProps): ReactElement => (
  <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Group</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Policy Number</TableCell>
          <TableCell>Insurance Type</TableCell>
          <TableCell>Insurance Plan</TableCell>
          <TableCell>Plan Type</TableCell>
          <TableCell width={120}>Expiry Date</TableCell>
          <TableCell width={120}>Authorization Valid Through</TableCell>
          {permissions.canManage && (
            <>
              <TableCell width={60}>Edit</TableCell>
              <TableCell width={60}>Remove</TableCell>
            </>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {insurances.length ? (
          insurances.map(item => (
            <Item key={item.id} insurance={item} selectors={selectors} permissions={permissions} />
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={9}>
              <NoDataFound />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
);

export default TableComponent;
