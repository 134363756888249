import { ReactElement } from 'react';

import { useStoreState } from '~/store/hooks';
import AccessDeniedPage from '~/ui/components/layouts/AccessDeniedPage';
import HealthManager from './components/HealthManager';
import ProgramAdmin from './components/ProgramAdmin';
import SuperAdmin from './components/SuperAdmin';

import { UserRole, UserPermission } from '~/types';
import {
  MOBILE_USERS,
  USERS_HEALTH_MANAGER,
  USERS_PROGRAM_ADMIN,
  USERS_SUPER_ADMIN,
} from '~/ui/constants/paths';
import RoutableTabs from '~/ui/components/common/RoutableTabs';
import MobileUser from './components/MobileUser';

const Users = (): ReactElement => {
  const userRole = useStoreState(state => state.auth.currentUser?.roleId);
  const userPermissions = useStoreState(state => state.auth.currentUser?.permissions || []);

  const permissions = {
    canViewHealthManagerTab:
      userRole !== UserRole.HealthManager || userPermissions.includes(UserPermission.ManageUsers),
    canViewProgramAdminTab: [UserRole.SystemAdmin, UserRole.SuperAdmin].includes(userRole),
    canViewSuperAdminTab: [UserRole.SystemAdmin].includes(userRole),
    canViewMobileUsersTab:
      userRole !== UserRole.HealthManager ||
      userPermissions.includes(UserPermission.ManageMobileUsers),
  };

  if (Object.values(permissions).every(permission => !permission)) {
    return <AccessDeniedPage />;
  }

  const tabs = [
    ...(permissions.canViewHealthManagerTab
      ? [
          {
            label: 'Health Manager',
            path: USERS_HEALTH_MANAGER,
            route: USERS_HEALTH_MANAGER,
            component: <HealthManager />,
          },
        ]
      : []),
    ...(permissions.canViewProgramAdminTab
      ? [
          {
            label: 'Program Admin',
            path: USERS_PROGRAM_ADMIN,
            route: USERS_PROGRAM_ADMIN,
            component: <ProgramAdmin />,
          },
        ]
      : []),
    ...(permissions.canViewSuperAdminTab
      ? [
          {
            label: 'Super Admin',
            path: USERS_SUPER_ADMIN,
            route: USERS_SUPER_ADMIN,
            component: <SuperAdmin />,
          },
        ]
      : []),
    ...(permissions.canViewMobileUsersTab
      ? [
          {
            label: 'Mobile Users',
            path: MOBILE_USERS,
            route: MOBILE_USERS,
            component: <MobileUser />,
          },
        ]
      : []),
  ];

  return <RoutableTabs tabs={tabs} />;
};

export default Users;
