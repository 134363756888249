import { IProgramsStore } from './types';

const initStore: IProgramsStore = {
  items: [],
  item: null,
  pagination: {
    page: 0,
    itemsPerPage: 20,
    hasMore: false,
  },
};

export default initStore;
