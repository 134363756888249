import { ReactElement } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import { IAuditLog } from '~/services/api/auditLogs/types';

import styles from './Item.module.scss';

interface IProps {
  item: IAuditLog;
}

const Item = ({ item }: IProps): ReactElement => (
  <TableRow>
    <TableCell>{format(new Date(item.createdAt), 'yyyy/MM/dd HH:mm:ss')}</TableCell>
    <TableCell className={styles.cell}>{item.userFullName}</TableCell>
    <TableCell className={styles.cell}>
      {item.link ? (
        <Link to={item.link} className={styles.link}>
          {item.message}
        </Link>
      ) : (
        item.message
      )}
    </TableCell>
    <TableCell>{item.areaName}</TableCell>
    <TableCell className={styles.cell}>{item.programName}</TableCell>
    <TableCell>{item.eventName}</TableCell>
  </TableRow>
);

export default Item;
