import { ReactElement } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';

import { initialValues, validate } from './form';
import Input from '~/ui/components/inputs/Input';
import AutoSubmit from '~/ui/components/inputs/AutoSubmit';

import { IFilters } from './types';
import styles from './Header.module.scss';

interface IProps {
  onSubmit: (arg: IFilters) => void;
}

const Header = ({ onSubmit }: IProps): ReactElement => {
  const formMethods = useForm<IFilters>({
    defaultValues: initialValues,
    resolver: validate,
  });

  const {
    register,
    formState: { errors },
  } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <form className={styles.form}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Input name="name" register={register} errors={errors} placeholder="Name" />
          </Grid>
          <Grid item xs={6} />
          <AutoSubmit debounce={1000} initialValues={initialValues} onSubmit={onSubmit} />
        </Grid>
      </form>
    </FormProvider>
  );
};

export default Header;
