import onGetMobileUsers from './onGetMobileUsers';
import onGetMoreMobileUsers from './onGetMoreMobileUsers';
import onRemoveMobileUser from './onRemoveMobileUser';
import onRemovePatients from './onRemovePatients';
import onUpdateMobileUser from './onUpdateMobileUser';

export default {
  onGetMobileUsers,
  onUpdateMobileUser,
  onRemoveMobileUser,
  onRemovePatients,
  onGetMoreMobileUsers,
};
