import { IPatientsStore } from './types';

const initStore: IPatientsStore = {
  items: [],
  pagination: {
    page: 0,
    itemsPerPage: 20,
    hasMore: false,
  },
};

export default initStore;
