import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkCreateActivityLog } from '../types';

// @ts-ignore
const onCreateActivityLog: IThunkCreateActivityLog = thunk(async ({ addActivityLog }, payload) => {
  const { patientId, data } = payload;
  const activityLog = await api.activityLogs.create(patientId, data).then(res => res.data);
  addActivityLog(activityLog);
});

export default onCreateActivityLog;
