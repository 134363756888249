import { action } from 'easy-peasy';
import { PatientStatus } from '~/services/api/enums';
import { IActionActivatePatient } from '../types';

const activatePatient: IActionActivatePatient = action((state, patientId) => {
  state.items = state.items.map(item => {
    if (item.id !== patientId) return item;
    return {
      ...item,
      status: PatientStatus.Approved,
    };
  });
});

export default activatePatient;
