import removeMobileUser from './removeMobileUser';
import reset from './reset';
import setMobileUsers from './setMobileUsers';
import updateMobileUser from './updateMobileUser';
import setMoreMobileUsers from './setMoreMobileUsers';

export default {
  setMobileUsers,
  updateMobileUser,
  removeMobileUser,
  reset,
  setMoreMobileUsers,
};
