import onGetPrograms from './onGetPrograms';
import onGetMorePrograms from './onGetMorePrograms';
import onGetProgram from './onGetProgram';
import onUpdateProgram from './onUpdateProgram';
import onRemoveProgram from './onRemoveProgram';

export default {
  onGetPrograms,
  onGetMorePrograms,
  onGetProgram,
  onUpdateProgram,
  onRemoveProgram,
};
