import axios from 'axios';
import { IResponse } from '~/services/api/types';
import { ITableCustomization } from './types';
import { TableType } from '../enums';

const apiUrl = 'table-customizations';

export const getByTableTypeId = (tableTypeId: TableType): IResponse<ITableCustomization[]> =>
  axios({
    method: 'get',
    url: `${apiUrl}/by-table-type-id/${tableTypeId}`,
  });

export const updateByTableTypeId = (
  tableTypeId: TableType,
  data: ITableCustomization[],
): IResponse<ITableCustomization[]> =>
  axios({
    method: 'put',
    url: `${apiUrl}/by-table-type-id/${tableTypeId}`,
    data,
  });

export default {};
