import axios from 'axios';
import { IResponse } from '~/services/api/types';
import { IAuditLog, IAuditLogsListFilters, IAuditLogsSelectors } from './types';

const apiUrl = 'audit-logs';

export const getSelectors = (params: IAuditLogsListFilters): IResponse<IAuditLogsSelectors> =>
  axios({
    method: 'get',
    url: `${apiUrl}/selectors`,
    params,
  });

export const getList = (params: IAuditLogsListFilters): IResponse<IAuditLog[]> =>
  axios({
    method: 'get',
    url: apiUrl,
    params,
  });

export default {};
