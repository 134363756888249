import { useState, ReactElement } from 'react';
import Modal from '~/ui/components/common/Modal';

import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import { formatDateToISO } from '~/utils/activityLogs';
import getOfflineInitialValues from './form/offline/getInitialValues';
import getOnlineInitialValues from './form/online/getInitialValues';
import ActivityLogOfflineForm from '~/ui/components/reusable/ActivityLogOfflineForm';
import ActivityLogOnlineForm from '~/ui/components/reusable/ActivityLogOnlineForm';

import { IActivityLog } from '~/services/api/activityLogs/types';
import { IFormValues as IActivityLogOfflineFormValues } from '~/ui/components/reusable/ActivityLogOfflineForm/types';
import { IFormValues as IActivityLogOnlineFormValues } from '~/ui/components/reusable/ActivityLogOnlineForm/types';
import styles from './EditActivityLog.module.scss';
import { ActivityLogMode } from '~/services/api/enums';

interface IProps {
  activityLog: IActivityLog;
  onClose: () => void;
}

const EditActivityLog = ({ activityLog, onClose }: IProps): ReactElement => {
  const [isUpdating, setIsUpdating] = useState(false);

  const onUpdateActivityLog = useStoreActions(actions => actions.activityLogs.onUpdateActivityLog);
  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);

  const updateActivityLog = async (data: IActivityLog) => {
    const payload = {
      ...activityLog,
      ...data,
    };

    setIsUpdating(true);

    try {
      await onUpdateActivityLog(payload);

      showNotify('Activity log successfully updated');
      onClose();
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsUpdating(false);
    }
  };

  const onSubmitOffline = (formValues: IActivityLogOfflineFormValues) => {
    const { dateTime, minutesSpent, note } = formValues;

    const data = {
      ...activityLog,
      mode: ActivityLogMode.Offline,
      dateTime: formatDateToISO(dateTime, activityLog.patientTimezone),
      secondsSpent: minutesSpent * 60, // convert from minutes to seconds
      note,
    };

    updateActivityLog(data);
  };

  const onSubmitOnline = (formValues: IActivityLogOnlineFormValues) => {
    const { note } = formValues;

    updateActivityLog({
      ...activityLog,
      mode: ActivityLogMode.Online,
      note,
      dateTime: activityLog.dateTime,
      secondsSpent: activityLog.secondsSpent,
    });
  };

  const handleClose = () => {
    // prevent closing popup when data is updating
    if (isUpdating) return;
    onClose();
  };

  return (
    <Modal open title="Update Activity Log" className={styles.modal} onClose={handleClose}>
      <>
        {activityLog.mode === ActivityLogMode.Online ? (
          <ActivityLogOnlineForm
            mode="edit"
            initialValues={getOnlineInitialValues(activityLog)}
            isProcessing={isUpdating}
            onSubmit={onSubmitOnline}
          />
        ) : (
          <ActivityLogOfflineForm
            mode="edit"
            initialValues={getOfflineInitialValues(activityLog)}
            isProcessing={isUpdating}
            onSubmit={onSubmitOffline}
          />
        )}
      </>
    </Modal>
  );
};

export default EditActivityLog;
