import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkUpdateCarePlanIcd10Code } from '../types';

const onUpdateCarePlanIcd10Code: IThunkUpdateCarePlanIcd10Code = thunk(
  // @ts-ignore
  async ({ updateCarePlanIcd10Code }, payload) => {
    const { data } = await api.carePlanIcd10Codes.update(payload);
    updateCarePlanIcd10Code(data);
  },
);

export default onUpdateCarePlanIcd10Code;
