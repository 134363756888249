import { action } from 'easy-peasy';
import { IActionSetPatients } from '../types';

const setPatients: IActionSetPatients = action((state, payload) => {
  const hasMore = state.pagination.itemsPerPage === payload.length;

  state.items = payload;
  state.pagination.page += 1;
  state.pagination.hasMore = hasMore;
});

export default setPatients;
