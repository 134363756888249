import { useEffect, useState, ReactElement } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Paper from '@material-ui/core/Paper';

import api from '~/services/api';
import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import Loader from '~/ui/components/common/Loader';
import InfoPanel from '~/ui/components/reusable/InfoPanel';
import Header from './components/Header';
import Table from './components/Table';

import styles from './ActivityLogs.module.scss';
import { IIdName } from '~/services/api/types';
import { IActivityLogsListFilters } from '~/services/api/activityLogs/types';
import { ISort } from '~/utils/sort/types';
import { formatToIsoWithoutTimezone, getInitialDateRange } from '~/utils/date';

const ActivityLogs = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [programs, setPrograms] = useState<IIdName[]>([]);
  const [totalActivityMinutes, setTotalActivityMinutes] = useState(0);
  const [filters, setFilters] = useState<IActivityLogsListFilters>({ ...getInitialDateRange() });
  const [sort, setSort] = useState<ISort>({} as ISort);

  const { items: activityLogs, pagination } = useStoreState(state => state.activityLogs);
  const { onGetActivityLogs, onGetMoreActivityLogs } = useStoreActions(
    actions => actions.activityLogs,
  );
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const requestPayload = {
    ...filters,
    ...sort,
    startDate: formatToIsoWithoutTimezone(filters.startDate),
    endDate: formatToIsoWithoutTimezone(filters.endDate),
  };

  const getData = async () => {
    setIsLoading(true);

    try {
      const [, { totalMinutesCount }] = await Promise.all([
        onGetActivityLogs(requestPayload),
        api.activityLogs.getCounters(requestPayload).then(res => res.data),
      ]);
      setTotalActivityMinutes(totalMinutesCount);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  const onLoadMorePatients = async () => {
    try {
      await onGetMoreActivityLogs(requestPayload);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const onMount = async () => {
    setIsLoading(true);

    try {
      const selectors = await api.activityLogs.getSelectors().then(res => res.data);
      setPrograms(selectors.programs);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // getData() is called after component mount
    // and every time filters, sort props are changing
    getData();
  }, [filters, sort]);

  useEffect(() => {
    onMount();
  }, []);

  return (
    <Paper elevation={3} className={styles.container}>
      <Header programs={programs} onSubmit={setFilters} />
      <InfoPanel
        startDate={filters.startDate}
        endDate={filters.endDate}
        label="Activity Logs"
        total={totalActivityMinutes}
        totalLabel="Activity Minutes"
      />
      <InfiniteScroll
        dataLength={activityLogs.length}
        next={onLoadMorePatients}
        hasMore={pagination.hasMore}
        loader={<Loader />}
      >
        {!isLoading && <Table activityLogs={activityLogs} sort={sort} onSort={setSort} />}
      </InfiniteScroll>
      {isLoading && <Loader />}
    </Paper>
  );
};

export default ActivityLogs;
