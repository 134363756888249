import { ReactElement } from 'react';

import styles from './Footer.module.scss';

const Footer = (): ReactElement | null => (
  <footer className={styles.footer}>
    {`Copyright @ ${new Date().getFullYear()} ${
      process.env.REACT_APP_WHITE_LABEL
    }, Inc. All rights reserved.`}
  </footer>
);

export default Footer;
