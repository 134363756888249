import { ReactElement } from 'react';
import Grid from '@material-ui/core/Grid';

import { isoToFormat } from '~/utils/date';
import { IPatientInfo } from '~/services/api/patients/types';
import styles from './PatientInfoPanel.module.scss';
import { Timezone } from '~/types';

interface IProps {
  patient: IPatientInfo;
}

const PatientInfoPanel = ({ patient }: IProps): ReactElement => (
  <Grid container spacing={1}>
    <Grid item xs={4}>
      <div className={styles.tab}>{`Patient: ${patient.fullName}`}</div>
    </Grid>
    <Grid item xs={4} className={styles.col}>
      <div className={styles.tab}>{`Patient ID: ${patient.subjectId}`}</div>
    </Grid>
    <Grid item xs={4} className={styles.col}>
      <div className={styles.tab}>{`DOB: ${
        patient.dateOfBirth
          ? isoToFormat(patient.dateOfBirth, 'dd MMM y', Timezone.GMT, false)
          : '-'
      }`}</div>
    </Grid>
  </Grid>
);

export default PatientInfoPanel;
