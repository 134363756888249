import { useState, ReactElement } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import VerticalTabs from '~/ui/components/reusable/VerticalTabs';
import ReadingLogs from './ReadingLogs';
import PatientSummary from './PatientSummary';

import { tabs } from './constants';
import { Tab } from './types';
import styles from './Reports.module.scss';

const Reports = (): ReactElement => {
  const [tab, setTab] = useState<string>(Tab.ReadingLogs);

  return (
    <Paper elevation={3} className={styles.reports}>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <VerticalTabs tabs={tabs} activeTab={tab} onChange={setTab} />
        </Grid>
        <Grid item xs={9}>
          {tab === Tab.ReadingLogs && <ReadingLogs />}
          {tab === Tab.PatientSummary && <PatientSummary />}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Reports;
