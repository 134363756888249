import axios from 'axios';
import { IResponse } from '../types';
import {
  IMobileUserPatient,
  IMobileUser,
  IGetPatientsParams,
  IMobileUserInfo,
  IMobileUsersListFilters,
} from './types';

const apiUrl = 'mobile-users';

export const get = (id: number): IResponse<IMobileUserInfo> =>
  axios({
    method: 'get',
    url: `${apiUrl}/${id}`,
  });

export const getByPhoneNumber = (phone: string): IResponse<IMobileUserInfo> =>
  axios({
    method: 'get',
    url: `${apiUrl}/by-phone/${phone}`,
  });

export const getList = (params?: IMobileUsersListFilters): IResponse<IMobileUser[]> =>
  axios({
    method: 'get',
    url: `${apiUrl}`,
    params,
  });

export const update = (data: IMobileUser): IResponse<IMobileUser> =>
  axios({
    method: 'put',
    url: `${apiUrl}`,
    data,
  });

export const removeMobileUser = (mobileUserId: number): IResponse<IMobileUser> =>
  axios({
    method: 'delete',
    url: `${apiUrl}/${mobileUserId}`,
  });

export const getPatients = (
  mobileUserTypeId: number,
  params: IGetPatientsParams,
): IResponse<IMobileUserPatient[]> =>
  axios({
    method: 'get',
    url: `${apiUrl}/${mobileUserTypeId}/patients`,
    params,
  });

export const removePatients = (mobileUserTypeId: number, patients: number[]): IResponse<void> =>
  axios({
    method: 'delete',
    url: `${apiUrl}/${mobileUserTypeId}/patients`,
    data: patients,
  });
export default {};
