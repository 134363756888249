import { useState } from 'react';
import InfoIcon from '@mui/icons-material/InfoTwoTone';

import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import ContentPopup from './ContentPopup';

import { IFormValues } from './ContentPopup/EditForm/types';
import styles from './QuickNotes.module.scss';

interface IProps {
  patientId: number;
}

const QuickNotes = ({ patientId }: IProps) => {
  const [showPopup, setShowPopup] = useState(false);

  const { onUpdateQuickNotes } = useStoreActions(actions => actions.patient);
  const { showError } = useStoreActions(actions => actions.snackbar);

  const onSave = async (data: IFormValues) => {
    try {
      await onUpdateQuickNotes({ patientId, data });
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  return (
    <div className={styles.quickNotes}>
      <InfoIcon className={styles.icon} onClick={() => setShowPopup(true)} />
      {showPopup && <ContentPopup onSave={onSave} onClose={() => setShowPopup(false)} />}
    </div>
  );
};

export default QuickNotes;
