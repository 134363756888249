import { string, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const validationSchema = object().shape({
  dateTime: string().required('Field is required'),
  minutesSpent: string()
    .nullable()
    .max(50, 'Max length - 50 symbols')
    .required('Field is required')
    .test({
      name: 'positiveNumber',
      message: 'Only positive numbers allowed',
      test: value => Number(value) > 0,
    }),
  note: string().required('Field is required').max(500, 'Max length - 500 symbols'),
});

export default yupResolver(validationSchema);
