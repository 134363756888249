import { ReactElement } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';

import { isoToFormat } from '~/utils/date';

import { ISectionPermissions } from '../types';
import styles from './Item.module.scss';
import { INote } from '~/services/api/notes/types';
import ReadMoreLess from '~/ui/components/common/ReadMoreLess';
import { Timezone } from '~/types';

interface IProps {
  note: INote;
  permissions: ISectionPermissions;
  userId: number;
  onEdit: (note: INote) => void;
  onDelete: (id: number) => void;
}

const Item = ({ note, permissions, userId, onEdit, onDelete }: IProps): ReactElement => (
  <TableRow>
    <TableCell>{`${isoToFormat(note.createdAt, 'dd MMM y H:mm')} ${
      note.patientTimezone
    }`}</TableCell>
    <TableCell>{note.userFullName}</TableCell>
    <TableCell className={styles.cell}>
      <ReadMoreLess content={note.content} />
    </TableCell>
    <TableCell>{note.isTelephoneConversation ? 'Yes' : 'No'}</TableCell>
    <TableCell>
      {note.reminderDate
        ? `Yes, ${isoToFormat(note.reminderDate, 'dd MMM y', Timezone.GMT, false)}`
        : 'No'}
    </TableCell>
    <TableCell>{note.typeName}</TableCell>
    {note.userId === userId || permissions.canManage ? (
      <>
        <TableCell align="center">
          <IconButton className={styles.icon} title="Edit" onClick={() => onEdit(note)}>
            <CreateIcon />
          </IconButton>
        </TableCell>
        <TableCell align="center">
          <IconButton className={styles.icon} title="Remove" onClick={() => onDelete(note.id)}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </>
    ) : (
      <TableCell colSpan={2} />
    )}
  </TableRow>
);

export default Item;
