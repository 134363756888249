import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getClientId } from '~/services/auth';
import { IThunkResendCode } from '../types';

const onResendCode: IThunkResendCode = thunk(async () => {
  const clientId = getClientId();

  await api.auth.resendCode({
    clientId,
  });
});

export default onResendCode;
