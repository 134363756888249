import { ReactElement, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@mui/icons-material/Search';
import RotateRightIcon from '@mui/icons-material/RotateRight';

import { setEndOfDay } from '~/utils/date';
import { getInitialFilters, validate } from './form';
import Select from '~/ui/components/inputs/Select';
import SelectVirtualized from '~/ui/components/inputs/SelectVirtualized';
import Button from '~/ui/components/common/Button';
import DatePicker from '~/ui/components/inputs/DatePicker';

import { IAuditLogsListFilters, IAuditLogsSelectors } from '~/services/api/auditLogs/types';
import {
  allAreasOption,
  allEventsOption,
  allProgramsOption,
  allUsersOption,
} from '~/ui/constants/allOptions';

interface IProps {
  selectors: IAuditLogsSelectors;
  filters: IAuditLogsListFilters;
  onSubmit: (filters: IAuditLogsListFilters) => void;
}

const Header = ({ selectors, filters, onSubmit }: IProps): ReactElement => {
  const initialFilters = getInitialFilters();

  const formMethods = useForm<IAuditLogsListFilters>({
    resolver: validate,
    defaultValues: filters,
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = formMethods;

  const userOptions = useMemo(
    () => [
      allUsersOption,
      ...selectors.users.map(item => ({
        value: item.id,
        label: item.name,
      })),
    ],
    [selectors.users],
  );

  const programOptions = useMemo(
    () => [
      allProgramsOption,
      ...selectors.programs.map(item => ({
        value: item.id,
        label: item.name,
      })),
    ],
    [selectors.programs],
  );

  const areaOptions = useMemo(
    () => [
      allAreasOption,
      ...selectors.areas.map(item => ({
        value: item.id,
        label: item.name,
      })),
    ],
    [selectors.areas],
  );

  const eventOptions = useMemo(
    () => [
      allEventsOption,
      ...selectors.events.map(item => ({
        value: item.id,
        label: item.name,
      })),
    ],
    [selectors.events],
  );

  const onSubmitForm = (values: IAuditLogsListFilters) => {
    const payload = { ...values };

    onSubmit({
      ...payload,
      endDate: setEndOfDay(payload.endDate),
    });
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Grid container spacing={2}>
          <Grid item xs>
            <DatePicker
              placeholder=".. /.. /.."
              label="Start Date"
              name="startDate"
              maxDate={new Date()}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs>
            <DatePicker
              placeholder=".. /.. /.."
              label="End Date"
              name="endDate"
              maxDate={new Date()}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs>
            <SelectVirtualized
              name="userId"
              control={control}
              errors={errors}
              options={userOptions}
              label="User"
            />
          </Grid>
          <Grid item xs>
            <Select
              name="areaId"
              control={control}
              errors={errors}
              options={areaOptions}
              label="Area"
            />
          </Grid>
          <Grid item xs>
            <SelectVirtualized
              name="programId"
              control={control}
              errors={errors}
              options={programOptions}
              label="Program Name"
            />
          </Grid>
          <Grid item xs>
            <Select
              name="eventId"
              control={control}
              errors={errors}
              options={eventOptions}
              label="Event"
            />
          </Grid>
          <Grid item xs={1} />
          <Grid item>
            <Button variant="outlined" type="submit" color="primary" startIcon={<SearchIcon />}>
              Search
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              type="button"
              startIcon={<RotateRightIcon />}
              onClick={() => {
                reset(initialFilters);
                onSubmit(initialFilters);
              }}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default Header;
