import { getDevicesNamesList } from './helper';

import { IDevice } from '~/services/api/devices/types';
import styles from './SelectedDevices.module.scss';

interface IProps {
  devices: IDevice[];
  devicesIds: number[];
}

const SelectedDevicesList = ({ devices, devicesIds }: IProps) => (
  <div className={styles.selectedDevices}>
    {getDevicesNamesList(devices, devicesIds).map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={index}>{item}</div>
    ))}
  </div>
);

export default SelectedDevicesList;
