import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkUpdateMobileUser } from '../types';

const onUpdateMobileUser: IThunkUpdateMobileUser = thunk(
  // @ts-ignore
  async ({ updateMobileUser }, payload) => {
    const mobileUserType = await api.mobileUsers.update(payload).then(res => res.data);

    updateMobileUser(mobileUserType);
  },
);

export default onUpdateMobileUser;
