import { Dispatch, SetStateAction, ReactElement } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import MUITable from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useStoreState } from '~/store/hooks';
import NoDataFound from '~/ui/components/reusable/NoDataFound';
import Loader from '~/ui/components/common/Loader';

import { headCells } from '../constants';
import styles from './Table.module.scss';
import { getSort } from '~/utils/sort';
import { ISort } from '~/utils/sort/types';

interface IProps {
  sort: ISort;
  setSort: Dispatch<SetStateAction<ISort>>;
  next: () => void;
}

const Table = ({ sort, setSort, next }: IProps): ReactElement => {
  const { items: devices, pagination } = useStoreState(state => state.devices);

  return (
    <TableContainer className={styles.tableContainer} id="scrollableDiv">
      <InfiniteScroll
        loader={<Loader />}
        next={next}
        hasMore={pagination.hasMore}
        dataLength={devices.length}
        scrollableTarget="scrollableDiv"
      >
        <MUITable>
          <TableHead>
            <TableRow>
              {headCells.map(headCell =>
                headCell.useSort ? (
                  <TableCell key={headCell.id}>
                    <TableSortLabel
                      active={sort.orderBy === headCell.id}
                      direction={sort.orderBy === headCell.id && sort.isDescending ? 'desc' : 'asc'}
                      onClick={() => setSort(getSort(sort, headCell.id))}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  <TableCell key={headCell.id}>{headCell.label}</TableCell>
                ),
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {devices.length ? (
              devices.map(device => (
                <TableRow key={device.id}>
                  <TableCell>{device.typeName}</TableCell>
                  <TableCell>{device.model}</TableCell>
                  <TableCell>{device.imei}</TableCell>
                  <TableCell>{device.statusName}</TableCell>
                  <TableCell>{device.supplierName}</TableCell>
                  <TableCell className={styles.cell}>{device.programName}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5}>
                  <NoDataFound />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </MUITable>
      </InfiniteScroll>
    </TableContainer>
  );
};

export default Table;
