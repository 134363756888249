import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetTableCustomizationsByTableTypeId } from '../types';

const onGetTableCustomizationsByTableTypeId: IThunkGetTableCustomizationsByTableTypeId = thunk(
  // @ts-ignore
  async ({ setTableCustomizations }, tableTypeId) => {
    const { data } = await api.tableCustomizations.getByTableTypeId(tableTypeId);

    setTableCustomizations({ tableTypeId, items: data });
  },
);

export default onGetTableCustomizationsByTableTypeId;
