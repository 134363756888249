import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import Button from '../../common/Button';
import Select from '../../inputs/Select';
import { IMobileUserFormValues } from './types';
import styles from './MobileUserForm.module.scss';
import { IOption } from '../../inputs/Select/types';
import validate from './form/validate';
import PhoneFlagInput from '../../inputs/PhoneFlag/PhoneFlagInput';

interface IProps {
  initialValues: IMobileUserFormValues;
  isProcessing: boolean;
  onSubmit: (data: IMobileUserFormValues) => void;
}

const MobileUserForm = ({ initialValues, isProcessing, onSubmit }: IProps): ReactElement => {
  const statusOptions: IOption[] = [
    { value: true, label: 'Active' },
    { value: false, label: 'Inactive' },
  ];

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<IMobileUserFormValues>({
    resolver: validate,
    defaultValues: initialValues,
  });

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formControl}>
          <PhoneFlagInput name="phone" control={control} errors={errors} />
        </div>

        <div className={styles.formControl}>
          <Select
            name="isActive"
            control={control}
            errors={errors}
            options={statusOptions}
            label="Status"
          />
        </div>

        <div className={styles.saveBtnWrapper}>
          <Button
            type="submit"
            size="large"
            variant="contained"
            color="primary"
            isLoading={isProcessing}
            disabled={isProcessing}
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

export default MobileUserForm;
