import * as Highcharts from 'highcharts';
import { DeviceType } from '~/services/api/enums';
import { IReading } from '~/services/api/readings/types';

import { dayPartTimeInterval } from '~/ui/constants/deviceReadings';
import { roundNumber } from '~/utils/format';
import { average, getMorningEveningRawData, sum } from '../helper';
import { analysisCategories, ChartColor } from '../types';

interface IData {
  morning: number[];
  evening: number[];
}

const getYAxis = (deviceTypeId: DeviceType): Highcharts.YAxisOptions[] => {
  const defaultAxis = [
    {
      title: null,
    },
  ] as Highcharts.YAxisOptions[];

  const weightScaleAxis = [
    {
      title: null,
    },
    {
      title: null,
      opposite: true,
    },
  ] as Highcharts.YAxisOptions[];

  return deviceTypeId === DeviceType.WeightScale ? weightScaleAxis : defaultAxis;
};

const getSeries = (deviceTypeId: DeviceType, data: IData): Highcharts.SeriesColumnOptions[] => {
  const defaultSeries = [
    {
      name: 'Morning',
      type: 'column',
      data: data.morning,
      color: ChartColor.Blue,
    },
    {
      name: 'Evening',
      type: 'column',
      data: data.evening,
      color: ChartColor.Green,
    },
  ] as Highcharts.SeriesColumnOptions[];

  const weightScaleSeries = [
    {
      id: 'morning',
      name: 'Morning',
      type: 'column',
      data: data.morning.slice(0, 1),
      color: ChartColor.Blue,
      centerInCategory: true,
      groupPadding: 0,
    },
    {
      id: 'evening',
      name: 'Evening',
      type: 'column',
      data: data.evening.slice(0, 1),
      color: ChartColor.Green,
      centerInCategory: true,
      groupPadding: 0,
    },
    {
      linkedTo: 'morning',
      name: 'Morning',
      type: 'column',
      data: [null, ...data.morning.slice(1)],
      color: ChartColor.Blue,
      yAxis: 1,
      centerInCategory: true,
      groupPadding: 0,
    },
    {
      linkedTo: 'evening',
      name: 'Evening',
      type: 'column',
      data: [null, ...data.evening.slice(1)],
      color: ChartColor.Green,
      yAxis: 1,
      centerInCategory: true,
      groupPadding: 0,
    },
  ] as Highcharts.SeriesColumnOptions[];

  return deviceTypeId === DeviceType.WeightScale ? weightScaleSeries : defaultSeries;
};

export const getData = (readings: IReading[], deviceTypeId: DeviceType): IData => {
  const morningStartHour = dayPartTimeInterval.morning.start;
  const morningEndHour = dayPartTimeInterval.morning.end;

  const eveningStartHour = dayPartTimeInterval.evening.start;
  const eveningEndHour = dayPartTimeInterval.evening.end;

  const hourReadings = readings.map(x => ({
    reading: x,
    hour: new Date(x.measuredAt).getHours(),
  }));

  const morningReadings = hourReadings
    .filter(x => x.hour >= morningStartHour && x.hour < morningEndHour)
    .map(x => x.reading);

  const eveningReadings = hourReadings
    .filter(x => x.hour >= eveningStartHour && x.hour < eveningEndHour)
    .map(x => x.reading);

  const morningData = getMorningEveningRawData(morningReadings, deviceTypeId);

  morningData.forEach((array, i) => {
    morningData[i] = array.filter(x => x != null);
  });

  const eveningData = getMorningEveningRawData(eveningReadings, deviceTypeId);

  eveningData.forEach((array, i) => {
    eveningData[i] = array.filter(x => x != null);
  });

  const data = {
    morning: morningData.map(array => roundNumber(average(array))),
    evening: eveningData.map(array => roundNumber(average(array))),
  };

  return data;
};

export const dataExists = (data: IData): boolean => sum(data.morning.concat(data.evening)) > 0;

export const getChartOptions = (data: IData, deviceTypeId: DeviceType): Highcharts.Options => ({
  chart: {
    type: 'column',
    marginTop: 25,
  },
  title: {
    text: null,
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    categories: analysisCategories[deviceTypeId],
  },
  yAxis: getYAxis(deviceTypeId),
  series: getSeries(deviceTypeId, data),
});
