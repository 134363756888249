import { ReactElement, useState, useEffect } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Paper from '@material-ui/core/Paper';

import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import Loader from '~/ui/components/common/Loader';
import Button from '~/ui/components/common/Button';
import Table from './Table';
import AddInsurance from '../popups/AddInsurance';

import { UserRole } from '~/types';
import styles from './Insurance.module.scss';
import api from '~/services/api';
import { IInsurancesSelectors } from '~/services/api/insurances/types';

interface IProps {
  patientId: number;
}

const Insurance = ({ patientId }: IProps): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [selectors, setSelectors] = useState<IInsurancesSelectors>(null);

  const currentUser = useStoreState(state => state.auth.currentUser);
  const insurances = useStoreState(state => state.insurances.items);

  const onGetInsurances = useStoreActions(actions => actions.insurances.onGetInsurances);
  const { showError } = useStoreActions(actions => actions.snackbar);

  const userRole = currentUser?.roleId;

  const permissions = {
    canManage: [UserRole.SystemAdmin, UserRole.SuperAdmin].includes(userRole),
  };

  const onMount = async () => {
    try {
      setIsLoading(true);
      await onGetInsurances({ patientId });
      const data = await api.insurances.getSelectors().then(res => res.data);
      setSelectors(data);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <Paper elevation={3} className={styles.insurance}>
      <div className={styles.header}>
        <div className={styles.title}>Insurance List</div>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => setShowCreatePopup(true)}
        >
          NEW INSURANCE
        </Button>
      </div>
      <Table insurances={insurances} selectors={selectors} permissions={permissions} />
      {showCreatePopup && (
        <AddInsurance
          patientId={patientId}
          selectors={selectors}
          onClose={() => setShowCreatePopup(false)}
        />
      )}
    </Paper>
  );
};

export default Insurance;
