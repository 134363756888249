import { thunk } from 'easy-peasy';
import api from '~/services/api/';
import { IThunkRemovePrograms } from '../types';

// @ts-ignore
const onRemovePrograms: IThunkRemovePrograms = thunk(async (_actions, payload) => {
  const { id, programs } = payload;
  await api.users.removePrograms(id, programs);
});

export default onRemovePrograms;
