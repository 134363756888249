import axios from 'axios';
import { IResponse } from '~/services/api/types';
import { ICarePlanIcd10Code } from './types';

const apiUrl = 'care-plan-icd-10-codes';

export const getList = (patientId: number): IResponse<ICarePlanIcd10Code[]> =>
  axios({
    method: 'get',
    url: `${apiUrl}/by-patient-id/${patientId}`,
  });

export const create = (
  patientId: number,
  data: ICarePlanIcd10Code,
): IResponse<ICarePlanIcd10Code> =>
  axios({
    method: 'post',
    url: apiUrl,
    data,
    params: {
      patientId,
    },
  });

export const update = (data: ICarePlanIcd10Code): IResponse<ICarePlanIcd10Code> =>
  axios({
    method: 'put',
    url: apiUrl,
    data,
  });

export const remove = (id: number): IResponse<void> =>
  axios({
    method: 'delete',
    url: `${apiUrl}/${id}`,
  });
