import initStore from './initStore';
import actions from './actions';
import thunks from './thunks';

import { IDashboardModel } from './types';

const dashboard: IDashboardModel = {
  ...initStore,
  ...actions,
  ...thunks,
};

export default dashboard;
