import { ReactElement } from 'react';

import Modal from '~/ui/components/common/Modal';
import NoteHeader from '~/ui/components/reusable/Notes/Header';
import NoteForm from '~/ui/components/reusable/Notes/NoteForm';

import { IPatientInfo } from '~/services/api/patients/types';
import { IFormValues } from '~/ui/components/reusable/Notes/NoteForm/types';
import styles from './NoteModal.module.scss';

interface IProps {
  mode?: 'create' | 'edit';
  patient: IPatientInfo;
  initialValues: IFormValues;
  isProcessing: boolean;
  onClose: () => void;
  onSubmit: (values: IFormValues) => void;
}

const NoteModal = ({
  mode = 'create',
  patient,
  initialValues,
  isProcessing,
  onClose,
  onSubmit,
}: IProps): ReactElement => {
  const title = mode === 'create' ? 'Add Note' : 'Update Note';

  return (
    <Modal open title={title} className={styles.modal} onClose={onClose}>
      <div className={styles.inner}>
        {patient && <NoteHeader patient={patient} />}
        <NoteForm
          mode={mode}
          initialValues={initialValues}
          isProcessing={isProcessing}
          onSubmit={onSubmit}
          onClose={onClose}
        />
      </div>
    </Modal>
  );
};

export default NoteModal;
