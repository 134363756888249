import * as Highcharts from 'highcharts';

import { getRuleLevelWarning, getWeightRuleLevelWarning } from '~/utils/deviceReading';

import { IReading } from '~/services/api/readings/types';
import { INotificationRule } from '~/services/api/notificationRules/types';
import { categories, ChartColor } from '../types';
import { DeviceType, RuleCondition, RuleType } from '~/services/api/enums';
import { getRawData, sum } from '../helper';

interface IData {
  level1: number[];
  level2: number[];
  level3: number[];
}

const ruleTypes = {
  [DeviceType.BloodPressure]: [RuleType.Systolic, RuleType.Diastolic, RuleType.HeartRate],
  [DeviceType.WeightScale]: [
    RuleCondition.PreviousGain,
    RuleCondition.PreviousLoss,
    RuleCondition.DailyGain,
    RuleCondition.DailyLoss,
    RuleCondition.WeeklyGain,
    RuleCondition.WeeklyLoss,
  ],
  [DeviceType.PulseOximeter]: [RuleType.POSPO2, RuleType.POPulserate],
  [DeviceType.BloodGlucose]: [RuleType.BloodGlucose],
};

export const getData = (
  readings: IReading[],
  rules: INotificationRule[],
  deviceTypeId: DeviceType,
): IData => {
  const rawData =
    deviceTypeId === DeviceType.WeightScale
      ? getRawData(readings, deviceTypeId).slice(1)
      : getRawData(readings, deviceTypeId);

  const data = {
    level1: ruleTypes[deviceTypeId].map(() => null),
    level2: ruleTypes[deviceTypeId].map(() => null),
    level3: ruleTypes[deviceTypeId].map(() => null),
  };

  ruleTypes[deviceTypeId].forEach((ruleType, i) => {
    rawData[i].forEach(readingValue => {
      const levelWarningNumber =
        deviceTypeId === DeviceType.WeightScale
          ? getWeightRuleLevelWarning(rules, ruleType as RuleCondition, readingValue)
          : getRuleLevelWarning(rules, ruleType as RuleType, readingValue);

      if (levelWarningNumber === 1) {
        data.level1[i] += 1;
      }
      if (levelWarningNumber === 2) {
        data.level2[i] += 1;
      }
      if (levelWarningNumber === 3) {
        data.level3[i] += 1;
      }
    });
  });

  return data;
};

const getCategories = (deviceTypeId: DeviceType): string[] =>
  deviceTypeId === DeviceType.WeightScale
    ? categories[deviceTypeId].slice(1)
    : categories[deviceTypeId];

export const dataExists = (data: IData): boolean =>
  sum(data.level1.concat(data.level2).concat(data.level3)) > 0;

export const getChartOptions = (data: IData, deviceTypeId: DeviceType): Highcharts.Options => ({
  chart: {
    type: 'column',
    marginTop: 25,
  },
  title: {
    text: null,
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    categories: getCategories(deviceTypeId),
  },
  yAxis: {
    title: null,
    reversedStacks: false,
  },
  plotOptions: {
    column: {
      stacking: 'normal',
      dataLabels: {
        enabled: true,
      },
    },
  },
  series: [
    {
      name: 'Level-1',
      type: 'column',
      data: data.level1,
      color: ChartColor.Blue,
    },
    {
      name: 'Level-2',
      type: 'column',
      data: data.level2,
      color: ChartColor.Yellow,
    },
    {
      name: 'Level-3',
      type: 'column',
      data: data.level3,
      color: ChartColor.Red,
    },
  ],
});

export default {};
