import { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

import { useStoreActions } from '~/store/hooks';
import { changePasswordValidationSchema } from '../validate';
import { extractErrorMessage } from '~/utils/error';
import Button from '~/ui/components/common/Button';
import Input from '~/ui/components/inputs/Input';
import Loader from '~/ui/components/common/Loader';

import styles from '../Form.module.scss';
import api from '~/services/api';

type IFormInputs = {
  password: string;
  confirmNewPassword: string;
};

const ChangePasswordForm = (): ReactElement => {
  const [loading, setLoading] = useState(false);

  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<IFormInputs>({ resolver: changePasswordValidationSchema });

  const onSubmit = async ({ password }: IFormInputs) => {
    try {
      setLoading(true);

      await api.users.updateCurrentPassword({ password });
      showNotify('Password successfully updated');
      reset({ password: '', confirmNewPassword: '' });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loader />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.inputContainer}>
        <Input
          errors={errors}
          name="password"
          register={register}
          label="New Password"
          size="small"
          type="password"
        />
      </div>
      <div className={styles.inputContainer}>
        <Input
          type="password"
          errors={errors}
          name="confirmNewPassword"
          register={register}
          label="Confirm New Password"
          size="small"
        />
      </div>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        startIcon={<SwapHorizIcon />}
        className={styles.button}
      >
        <span className={styles.divider} />
        Change Password
      </Button>
    </form>
  );
};

export default ChangePasswordForm;
