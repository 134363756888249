import { object, string, mixed } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const validationSchema = object().shape({
  name: string().max(100, 'Max length - 100 symbols').required('Field is required'),
  timezone: string().required('Field is required'),
  imageUrl: mixed(),
});

export default yupResolver(validationSchema);
