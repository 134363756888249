import setCarePlanHospitalizations from './setCarePlanHospitalizations';
import addCarePlanHospitalization from './addCarePlanHospitalization';
import updateCarePlanHospitalization from './updateCarePlanHospitalization';
import removeCarePlanHospitalization from './removeCarePlanHospitalization';

export default {
  setCarePlanHospitalizations,
  addCarePlanHospitalization,
  updateCarePlanHospitalization,
  removeCarePlanHospitalization,
};
