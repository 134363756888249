import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import validate from './form/validate';
import DatePicker from '~/ui/components/inputs/DatePicker';
import Input from '~/ui/components/inputs/Input';
import Textarea from '~/ui/components/inputs/Textarea';
import Button from '~/ui/components/common/Button';

import { IFormValues } from './types';
import styles from './ActivityLogOfflineForm.module.scss';

interface IProps {
  mode?: 'create' | 'edit';
  showBackBtn?: boolean;
  initialValues: IFormValues;
  isProcessing: boolean;
  onSubmit: (data: IFormValues, reset: any) => void;
  onClose?: () => void;
}

const ActivityLogOfflineForm = ({
  mode = 'create',
  showBackBtn = false,
  initialValues,
  isProcessing,
  onSubmit,
  onClose,
}: IProps): ReactElement => {
  const {
    control,
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: initialValues, resolver: validate });

  const noteValue = watch('note');

  const onSubmitForm = (values: IFormValues) => {
    onSubmit(values, reset);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <div className={styles.formContainer}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <DatePicker
                name="dateTime"
                label="Date/Time *"
                showTimeSelect
                dateFormat="MM/dd/yyyy HH:mm"
                maxDate={new Date()}
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                type="number"
                name="minutesSpent"
                label="Time Spent (in Min) *"
                register={register}
                errors={errors}
              />
            </Grid>
            <Grid item xs={12}>
              <Textarea label="Note *" name="note" register={register} errors={errors} />
              <div className={styles.noteLength}>{noteValue.length} of 500 max characters</div>
            </Grid>
          </Grid>
          <div className={styles.buttonWrapper}>
            {showBackBtn && (
              <>
                <Button
                  variant="outlined"
                  color="default"
                  startIcon={<ArrowBackIosIcon />}
                  onClick={onClose}
                >
                  Back
                </Button>
                <div className={styles.spacer} />
              </>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              isLoading={isProcessing}
              disabled={isProcessing}
            >
              {mode === 'create' ? 'Create' : 'Save'}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ActivityLogOfflineForm;
