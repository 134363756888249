import { INotificationsStore } from './types';

const initStore: INotificationsStore = {
  items: [],
  pagination: {
    page: 0,
    itemsPerPage: 20,
    hasMore: false,
  },
  notifyCount: 0,
};

export default initStore;
