import { TableRow, TableCell, Button, IconButton } from '@material-ui/core';
import { useState } from 'react';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import PhoneInput from 'react-phone-input-2';
import { IMobileUser } from '~/services/api/mobileUsers/types';
import styles from './Item.module.scss';
import EditMobileUser from '../../../popups/EditMobileUser';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import { extractErrorMessage } from '~/utils/error';
import { useStoreActions } from '~/store/hooks';
import AssignedMobileUserPatientsModal from '../../../popups/AssignedMobileUserPatientsModal';

interface IProps {
  mobileUser: IMobileUser;
}

const Item = ({ mobileUser }: IProps) => {
  const [showAssignedPopup, setShowAssignedPopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [formattedPhone, setFormattedPhone] = useState('');

  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);
  const { onRemoveMobileUser } = useStoreActions(actions => actions.mobileUsers);

  const removeUser = async () => {
    setIsRemoving(true);

    try {
      await onRemoveMobileUser(mobileUser.id);
      showNotify('Mobile user terminated');
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsRemoving(false);
    }
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <div className={styles.phoneInputContainer}>
            <PhoneInput
              key={mobileUser.phone}
              country="us"
              regions={['north-america', 'europe']}
              value={mobileUser.phone}
              onMount={(_value, _data, formattedValue) => setFormattedPhone(formattedValue)}
            />
          </div>
          {formattedPhone}
        </TableCell>
        <TableCell>{mobileUser.mobileUserRoleName}</TableCell>
        <TableCell>{mobileUser.isActive ? 'Active' : 'Inactive'}</TableCell>
        <TableCell align="center">{mobileUser.hasAgreed ? 'Yes' : 'No'}</TableCell>
        <TableCell align="center">
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={() => setShowAssignedPopup(true)}
          >
            Assigned Patients
          </Button>
        </TableCell>
        <TableCell width={80}>
          <IconButton className={styles.icon} title="Edit" onClick={() => setShowEditPopup(true)}>
            <CreateIcon />
          </IconButton>
        </TableCell>
        <TableCell align="center">
          <IconButton
            className={styles.icon}
            title="Remove"
            onClick={() => setShowRemoveConfirm(true)}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      {showEditPopup && (
        <EditMobileUser mobileUser={mobileUser} onClose={() => setShowEditPopup(false)} />
      )}
      {showAssignedPopup && (
        <AssignedMobileUserPatientsModal
          open
          mobileUserTypeId={mobileUser.id}
          onClose={() => setShowAssignedPopup(false)}
        />
      )}
      {showRemoveConfirm && (
        <ConfirmModal
          title="Remove"
          description={`Are you sure you want to terminate mobile user ${mobileUser.phone}?`}
          isLoading={isRemoving}
          onClose={() => setShowRemoveConfirm(false)}
          onConfirm={removeUser}
        />
      )}
    </>
  );
};

export default Item;
