import { useMemo, ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@material-ui/core/Button';

import { initialValues, validate } from './form';
import SelectVirtualized from '~/ui/components/inputs/SelectVirtualized';
import Modal from '~/ui/components/common/Modal';

import { IIdName } from '~/services/api/types';
import { IFormValues } from './form/types';
import styles from './AssignDevicesModal.module.scss';

interface IProps {
  programs: IIdName[];
  description: ReactElement;
  onConfirm: (programId: number) => void;
  onClose: () => void;
}
const AssignPatientsModal = ({
  programs,
  description,
  onConfirm,
  onClose,
}: IProps): ReactElement => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>({
    resolver: validate,
    defaultValues: initialValues,
  });

  const programOptions = useMemo(
    () =>
      programs.map(item => ({
        value: item.id,
        label: item.name,
      })),
    [programs.length],
  );

  const onSubmit = ({ programId }: IFormValues) => {
    onConfirm(programId);
  };

  return (
    <Modal open title="Select Program" onClose={onClose} className={styles.modal} maxWidth="sm">
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <SelectVirtualized
          name="programId"
          control={control}
          errors={errors}
          options={programOptions}
          maxMenuHeight={130}
          label="Program *"
        />
        <div className={styles.spacer} />
        {description}
        <div className={styles.btnWrapper}>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default AssignPatientsModal;
