import onLogin from './onLogin';
import onLogout from './onLogout';
import onCheckAuthorization from './onCheckAuthorization';
import onResendCode from './onResendCode';
import onVerifyCode from './onVerifyCode';
import onRefresh from './onRefresh';

export default {
  onCheckAuthorization,
  onLogin,
  onRefresh,
  onLogout,
  onResendCode,
  onVerifyCode,
};
