import axios from 'axios';
import { IResponse } from '~/services/api/types';
import { IInsurance, IInsurancesListFilters, IInsurancesSelectors } from './types';

const apiUrl = 'insurances';

export const getList = (params: IInsurancesListFilters): IResponse<IInsurance[]> =>
  axios({
    method: 'get',
    url: apiUrl,
    params,
  });

export const getSelectors = (): IResponse<IInsurancesSelectors> =>
  axios({
    method: 'get',
    url: `${apiUrl}/selectors`,
  });

export const create = (patientId: number, data: IInsurance): IResponse<IInsurance> =>
  axios({
    method: 'post',
    url: apiUrl,
    data,
    params: {
      patientId,
    },
  });

export const update = (data: IInsurance): IResponse<IInsurance> =>
  axios({
    method: 'put',
    url: apiUrl,
    data,
  });

export const remove = (id: number): IResponse<void> =>
  axios({
    method: 'delete',
    url: `${apiUrl}/${id}`,
  });
