import { useState, ReactElement } from 'react';
import Modal from '~/ui/components/common/Modal';

import api from '~/services/api';
import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import initialValues from './form/initialValues';
import UserForm from '~/ui/components/reusable/UserForm';

import { IUserFormValues } from '~/ui/components/reusable/UserForm/types';
import styles from './AddUser.module.scss';

interface IProps {
  onClose: () => void;
}

const AddUser = ({ onClose }: IProps): ReactElement => {
  const [isUserAdding, setIsUserAdding] = useState(false);

  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);

  const addUser = async (data: IUserFormValues) => {
    const {
      roleId,
      firstName,
      lastName,
      email,
      phone,
      password,
      programIds,
      permissions,
      isActive,
      isMfaEnabled,
      isMobileEnabled,
      hasAgreed,
      receiveAbnormalReadingNotifications,
      receiveMissedReadingNotifications,
    } = data;

    const payload = {
      id: 0,
      roleId,
      firstName,
      lastName,
      email,
      phone,
      password,
      programIds,
      permissions,
      isActive,
      isMfaEnabled,
      isMobileEnabled,
      hasAgreed,
      receiveAbnormalReadingNotifications,
      receiveMissedReadingNotifications,
    };

    setIsUserAdding(true);

    try {
      await api.users.create(payload);

      showNotify('User successfully added');
      onClose();
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsUserAdding(false);
    }
  };

  const handleClose = () => {
    // prevent closing popup when user is adding
    if (isUserAdding) return;
    onClose();
  };

  return (
    <Modal open title="Add User" className={styles.modal} onClose={handleClose}>
      <UserForm initialValues={initialValues} isProcessing={isUserAdding} onSubmit={addUser} />
    </Modal>
  );
};

export default AddUser;
