import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';

import createValidator from './form/create/validate';
import editValidator from './form/edit/validate';
import Textarea from '~/ui/components/inputs/Textarea';
import Button from '~/ui/components/common/Button';

import { IFormValues } from './types';
import styles from './ActivityLogOnlineForm.module.scss';

interface IProps {
  mode?: 'create' | 'edit';
  initialValues: IFormValues;
  isProcessing: boolean;
  onSubmit: (data: IFormValues) => void;
}

const ActivityLogOnlineForm = ({
  mode = 'create',
  initialValues,
  isProcessing,
  onSubmit,
}: IProps): ReactElement => {
  const isCreateMode = mode === 'create';
  const validator = isCreateMode ? createValidator : editValidator;

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: initialValues, resolver: validator });

  const noteValue = watch('note');

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formContainer}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Textarea label="Note" name="note" register={register} errors={errors} />
              <div className={styles.noteLength}>{noteValue.length} of 500 max characters</div>
            </Grid>
          </Grid>
          <div className={styles.buttonWrapper}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              isLoading={isProcessing}
              disabled={isProcessing}
            >
              {isCreateMode ? 'Submit' : 'Save'}
            </Button>
            {isCreateMode && (
              <Button
                type="button"
                variant="outlined"
                color="primary"
                disabled={isProcessing}
                className={styles.btnSkip}
                onClick={() => onSubmit({ note: '' })}
              >
                Skip
              </Button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ActivityLogOnlineForm;
