import setMedications from './setMedications';
import setMoreMedications from './setMoreMedications';
import reset from './reset';
import addMedication from './addMedication';
import updateMedication from './updateMedication';
import deleteMedication from './deleteMedication';

export default {
  setMedications,
  setMoreMedications,
  reset,
  addMedication,
  updateMedication,
  deleteMedication,
};
