import { action } from 'easy-peasy';
import { IActionUpdateCarePlanMedication } from '../types';

const updateCarePlanMedication: IActionUpdateCarePlanMedication = action((state, payload) => {
  state.items = state.items.map(item => {
    if (item.id !== payload.id) return item;
    return {
      ...item,
      ...payload,
    };
  });
});

export default updateCarePlanMedication;
