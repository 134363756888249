import { ReactElement } from 'react';
import { components, OptionProps, GroupTypeBase } from 'react-select';

import { IOption } from '../types';
import styles from './Option.module.scss';

const Option = (props: OptionProps<IOption, true, GroupTypeBase<IOption>>): ReactElement => {
  const { isSelected, label } = props;

  return (
    <components.Option {...props} className={styles.option}>
      <input type="checkbox" checked={isSelected} onChange={() => null} />
      &nbsp;
      <label htmlFor={label} className={styles.label}>
        {label}
      </label>
    </components.Option>
  );
};

export default Option;
