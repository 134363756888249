import { useState, ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@material-ui/core/Button';

import companyLogo from 'logo/logo-full.svg';
import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import { initialValues, validate } from './form';
import Loader from '~/ui/components/common/Loader';
import Input from '~/ui/components/inputs/Input';
import { IEmailLogin } from '~/services/api/auth/types';

import styles from './EmailLogin.module.scss';

const SignInForm = (): ReactElement => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IEmailLogin>({
    resolver: validate,
    defaultValues: initialValues,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState('');

  const onLogin = useStoreActions(actions => actions.auth.onLogin);

  const onSubmit = async (values: IEmailLogin) => {
    setIsLoading(true);

    try {
      await onLogin(values);
    } catch (e) {
      setServerError(extractErrorMessage(e));
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <img src={companyLogo} className={styles.logo} alt="Logo" />
      <div className={styles.row}>
        <Input
          name="email"
          register={register}
          errors={errors}
          size="medium"
          label="Username"
          placeholder="Username"
        />
      </div>
      <div className={styles.row}>
        <Input
          type="password"
          name="password"
          register={register}
          errors={errors}
          size="medium"
          label="Password"
          placeholder="Password"
        />
      </div>
      <div className={styles.row}>
        <Button
          type="submit"
          size="large"
          variant="contained"
          color="primary"
          className={styles.btn}
          disabled={isLoading}
        >
          Log In
        </Button>
      </div>
      {isLoading && <Loader />}
      {serverError && <div className={styles.serverError}>{serverError}</div>}
    </form>
  );
};

export default SignInForm;
