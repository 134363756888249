import { Icd10CodeType } from '~/services/api/enums';
import { IOption } from '~/ui/components/inputs/Select/types';

export const CODE_TYPE_OPTIONS: IOption[] = [
  {
    value: Icd10CodeType.Primary,
    label: Icd10CodeType.Primary,
  },
  {
    value: Icd10CodeType.Secondary,
    label: Icd10CodeType.Secondary,
  },
  {
    value: Icd10CodeType.Other,
    label: Icd10CodeType.Other,
  },
];

export default {};
