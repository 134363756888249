import { useState, ReactElement } from 'react';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';

import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import { isoToFormat } from '~/utils/date';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import EditMedication from '../../../popups/EditMedication';

import { UserRole } from '~/types';
import styles from './Item.module.scss';
import { IMedication } from '~/services/api/medications/types';
import ReadMoreLess from '~/ui/components/common/ReadMoreLess';

interface IProps {
  medication: IMedication;
}

const Item = ({ medication }: IProps): ReactElement => {
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);

  const currentUser = useStoreState(state => state.auth.currentUser);

  const onDeleteMedication = useStoreActions(actions => actions.medications.onDeleteMedication);
  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);

  const userRole = currentUser?.roleId;
  const userId = currentUser?.id;

  const permissions = {
    canManage:
      medication.userId === userId ||
      [UserRole.SystemAdmin, UserRole.SuperAdmin].includes(userRole),
  };

  const deleteMedication = async () => {
    try {
      setIsDeleting(true);
      await onDeleteMedication(medication.id);
      showNotify('Medication removed');
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsDeleting(false);
    }
  };

  return (
    <div className={styles.itemContainer}>
      <div className={styles.header}>
        <div>
          <span className={styles.bold}>Date: </span>
          <span>
            {isoToFormat(medication.createdAt, 'dd MMM y H:mm')}
            &nbsp;
            {medication.patientTimezone}
          </span>
          <span className={styles.author}>
            <span className={styles.bold}>Author: </span>
            <span>{medication.userFullName}</span>
          </span>
        </div>
        <div>
          {permissions.canManage && (
            <>
              <IconButton
                className={styles.icon}
                title="Edit"
                onClick={() => setShowEditPopup(true)}
              >
                <CreateIcon />
              </IconButton>
              <IconButton
                className={styles.icon}
                title="Remove"
                onClick={() => setShowDeleteConfirm(true)}
              >
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </div>
      </div>
      <ReadMoreLess content={medication.content} />
      {showEditPopup && (
        <EditMedication medication={medication} onClose={() => setShowEditPopup(false)} />
      )}
      {showDeleteConfirm && (
        <ConfirmModal
          title="Remove"
          description="Are you sure you want to remove current medication?"
          isLoading={isDeleting}
          onClose={() => setShowDeleteConfirm(false)}
          onConfirm={deleteMedication}
        />
      )}
    </div>
  );
};

export default Item;
