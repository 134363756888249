export const insuranceTypeOptions = [
  {
    value: 'Primary',
    label: 'Primary',
  },
  {
    value: 'Secondary',
    label: 'Secondary',
  },
];

export const insurancePlanOptions = [
  {
    value: 'Family',
    label: 'Family',
  },
  {
    value: 'Individual',
    label: 'Individual',
  },
];

export const planTypeOptions = [
  {
    value: 'PPOBronze',
    label: 'PPOBronze',
  },
  {
    value: 'PPOSilver',
    label: 'PPOSilver',
  },
  {
    value: 'PPOGold',
    label: 'PPOGold',
  },
  {
    value: 'HMO',
    label: 'HMO',
  },
  {
    value: 'Medicare',
    label: 'Medicare',
  },
  {
    value: 'Medicaid',
    label: 'Medicaid',
  },
];
