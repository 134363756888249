import { ReactElement } from 'react';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import get from 'lodash/get';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormHelperText from '@material-ui/core/FormHelperText';

import styles from './RadioGroup.module.scss';

interface IOption {
  value: string;
  label: string;
  disabled?: boolean;
}

interface IProps {
  control: Control<any>;
  errors: FieldErrors;
  name: string;
  options: IOption[];
  label?: string;
}

const RadioGroupComponent = ({
  control,
  errors,
  name,
  options,
  label = '',
}: IProps): ReactElement => {
  const error = get(errors, `${name}.message`, '');

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <FormControl component="fieldset">
          <FormLabel component="legend" className={styles.label}>
            {label}
          </FormLabel>
          <RadioGroup
            aria-label="gender"
            value={value}
            className={styles.radioGroup}
            onChange={onChange}
          >
            {options.map(option => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio color="primary" />}
                label={option.label}
                disabled={option.disabled}
              />
            ))}
          </RadioGroup>
          {error && <FormHelperText error>{error}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

export default RadioGroupComponent;
