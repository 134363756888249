import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkRemoveProgram } from '../types';

// @ts-ignore
const onRemoveProgram: IThunkRemoveProgram = thunk(async ({ removeProgram }, id) => {
  await api.programs.remove(id);
  removeProgram(id);
});

export default onRemoveProgram;
