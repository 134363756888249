import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetCarePlanMedications } from '../types';

const onGetCarePlanMedications: IThunkGetCarePlanMedications = thunk(
  // @ts-ignore
  async ({ setCarePlanMedications }, patientId) => {
    const { data } = await api.carePlanMedications.getList(patientId);
    setCarePlanMedications(data);
  },
);

export default onGetCarePlanMedications;
