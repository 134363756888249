import { Day, DayPart } from '~/types';
import { roundNumber } from '~/utils/format';
import { dayPartsList, daysList, IAverageData, IDay, IDayPartReading } from '../types';

const getGroupedData = (dayPartReadings: IDayPartReading[], dayPart: DayPart, day: Day): IDay => {
  const readings = dayPartReadings.filter(x => x.dayPart === dayPart && x.day === day);

  if (readings.length === 0) {
    return {
      day,
      values: [],
      value: '(0)',
    };
  }

  const values = readings
    .map(x => [...x.values, ...x.additionalValues, x.batteryLevel, x.signalLevel])
    .reduce(
      (s, x) => x.map<[number, number]>((y, i) => [s[i][0] + y, s[i][1] + (y === null ? 0 : 1)]),
      Array<[number, number]>(
        readings[0].values.length + readings[0].additionalValues.length + 2,
      ).fill([0, 0]),
    )
    .map(([x, length]) => (length === 0 ? null : roundNumber(x / length)));

  return {
    day,
    values,
    value: `${values.map(x => (x === null ? '-' : x)).join('/')} (${readings.length})`,
  };
};

export const getAverageData = (dayPartReadings: IDayPartReading[]): IAverageData => ({
  dayParts: dayPartsList.map(dayPart => ({
    dayPart,
    days: daysList.map(day => getGroupedData(dayPartReadings, dayPart, day)),
  })),
});

export default {};
