import { ReactElement } from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { IProgramList } from '~/services/api/programs/types';
import styles from './TableBody.module.scss';

interface IProps {
  programs: IProgramList[];
}

const CustomTableBody = ({ programs }: IProps): ReactElement => (
  <TableBody>
    {programs.map(item => (
      <TableRow key={item.id}>
        <TableCell className={styles.cell}>{item.name}</TableCell>
        <TableCell align="center">{item.activePatientsCount}</TableCell>
      </TableRow>
    ))}
  </TableBody>
);

export default CustomTableBody;
