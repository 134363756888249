import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkCreateInsurance } from '../types';

// @ts-ignore
const onCreateInsurance: IThunkCreateInsurance = thunk(async ({ addInsurance }, payload) => {
  const { patientId, data } = payload;
  const insurance = await api.insurances.create(patientId, data).then(res => res.data);
  addInsurance(insurance);
});

export default onCreateInsurance;
