import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getPaginationPayload } from '~/utils/pagination';
import { INotesStore, IThunkGetMoreNotes } from '../types';

const onGetMoreNotes: IThunkGetMoreNotes = thunk(
  // @ts-ignore
  async ({ setMoreNotes }, filters, { getState }) => {
    const localState = getState() as INotesStore;

    const paginationPayload = getPaginationPayload(localState.pagination);

    const { data } = await api.notes.getList({
      ...filters,
      ...paginationPayload,
    });

    setMoreNotes(data);
  },
);

export default onGetMoreNotes;
