import { useState } from 'react';
import { RadioGroup, Radio, FormControlLabel } from '@material-ui/core';

import Modal from '~/ui/components/common/Modal';
import ApplyCode from './ApplyCode';
import CreateCode from './CreateCode';

import { TABS } from './constants';
import { ICarePlanIcd10Code } from '~/services/api/carePlanIcd10Codes/types';
import { ModalType } from './types';
import { IFormValues } from './ApplyCode/form/types';
import styles from './Icd10CodeModal.module.scss';

interface IProps {
  mode?: 'create' | 'edit';
  patientId: number;
  currentEditCarePlanIcd10Code?: ICarePlanIcd10Code;
  applyCodeInitialValues: IFormValues;
  onClose: () => void;
}

const Icd10CodeModal = ({
  mode = 'create',
  patientId,
  currentEditCarePlanIcd10Code,
  applyCodeInitialValues,
  onClose,
}: IProps) => {
  const [showModal, setShowModal] = useState(ModalType.ApplyCode);

  const title = mode === 'create' ? 'Add ICD 10 Code' : 'Update ICD 10 Code';

  const onTabChange = (tab: ModalType) => {
    setShowModal(tab);
  };

  return (
    <Modal open maxWidth="sm" title={title} className={styles.modal} onClose={onClose}>
      <div className={styles.inner}>
        <RadioGroup
          row
          aria-label="gender"
          className={styles.radioGroup}
          value={showModal}
          onChange={(e, val) => onTabChange(val as ModalType)}
        >
          {TABS.map(tab => (
            <FormControlLabel
              key={tab.value}
              value={tab.value}
              label={tab.label}
              control={<Radio color="primary" />}
            />
          ))}
        </RadioGroup>
        <div className={styles.heightSpacer} />
        {showModal === ModalType.ApplyCode && (
          <ApplyCode
            mode={mode}
            patientId={patientId}
            currentEditCarePlanIcd10Code={currentEditCarePlanIcd10Code}
            initialValues={applyCodeInitialValues}
            onClose={onClose}
          />
        )}
        {showModal === ModalType.CreateCode && (
          <CreateCode onClose={() => setShowModal(ModalType.ApplyCode)} />
        )}
      </div>
    </Modal>
  );
};

export default Icd10CodeModal;
