import { string } from 'yup';
import { isDateBiggerOrEqual } from './date';

export const dateRangeValidationSchema = {
  startDate: string(),
  endDate: string().test({
    name: 'dateCompare',
    message: '`End date` should be equal or bigger then `Start date`',
    test: (value, context) => {
      const { startDate } = context.parent;

      return !startDate || !value || isDateBiggerOrEqual(startDate, value);
    },
  }),
};

export default {};
