import { ReactElement } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import GraphCard from '~/ui/components/reusable/GraphCard';
import { dayLabelsList } from './constants';
import styles from './DaysReading.module.scss';

interface IProps {
  noData: boolean;
  title: string;
  chartOptions: Highcharts.Options;
}

const DaysReading = ({ noData, title, chartOptions }: IProps): ReactElement => {
  if (noData) return <GraphCard title={title} showNoData />;

  return (
    <GraphCard title={title}>
      <div className={styles.wrapper}>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        <div className={styles.panel}>
          <div className={styles.inner}>
            {dayLabelsList.map(item => (
              <div key={item.name} className={styles.day}>
                <div className={styles.dayColor} style={{ backgroundColor: item.color }} />
                <div>{item.name}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </GraphCard>
  );
};

export default DaysReading;
