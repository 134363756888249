import { fromBase64 } from 'js-base64';
import { ITokens } from '../api/auth/types';

export const getAccessToken = () => localStorage.getItem('accessToken');
export const getRefreshToken = () => localStorage.getItem('refreshToken');
export const getClientId = () => localStorage.getItem('clientId');

export const setTokens = ({ accessToken, refreshToken, clientId }: ITokens) => {
  if (clientId) {
    localStorage.setItem('clientId', clientId);
  }

  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
  }

  if (refreshToken) {
    localStorage.setItem('refreshToken', refreshToken);
  }
};

export const deleteTokens = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
};

export const parseToken = <T>(token: string): T =>
  token ? JSON.parse(fromBase64(token.split('.')[1])) : null;
