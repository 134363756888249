import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkRemoveCarePlanIcd10Code } from '../types';

const onRemoveCarePlanIcd10Code: IThunkRemoveCarePlanIcd10Code = thunk(
  // @ts-ignore
  async ({ removeCarePlanIcd10Code }, id) => {
    await api.carePlanIcd10Codes.remove(id);
    removeCarePlanIcd10Code(id);
  },
);

export default onRemoveCarePlanIcd10Code;
