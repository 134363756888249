import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getPaginationPayload } from '~/utils/pagination';
import { IThunkGetInsurances, IInsurancesStore } from '../types';

const onGetInsurances: IThunkGetInsurances = thunk(
  // @ts-ignore
  async ({ setInsurances, reset }, filters, { getState }) => {
    reset();

    const localState = getState() as IInsurancesStore;

    const paginationPayload = getPaginationPayload(localState.pagination);

    const { data } = await api.insurances.getList({
      ...filters,
      ...paginationPayload,
    });

    setInsurances(data);
  },
);

export default onGetInsurances;
