import setActivityLogs from './setActivityLogs';
import setMoreActivityLogs from './setMoreActivityLogs';
import reset from './reset';
import setActivityLogsByPatientId from './setActivityLogsByPatientId';
import setMoreActivityLogsByPatientId from './setMoreActivityLogsByPatientId';
import resetByPatientId from './resetByPatientId';
import addActivityLog from './addActivityLog';
import updateActivityLog from './updateActivityLog';
import deleteActivityLog from './deleteActivityLog';

export default {
  setActivityLogs,
  setMoreActivityLogs,
  reset,
  setActivityLogsByPatientId,
  setMoreActivityLogsByPatientId,
  resetByPatientId,
  addActivityLog,
  updateActivityLog,
  deleteActivityLog,
};
