import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@mui/icons-material/Save';
import Button from '~/ui/components/common/Button';
import SelectVirtualized from '~/ui/components/inputs/SelectVirtualized';

import { IDeviceSelector } from '~/services/api/devices/types';
import styles from './Form.module.scss';
import { DeviceType } from '~/services/api/enums';

export interface IFormValues {
  bloodPressureDeviceId: number;
  weightScaleDeviceId: number;
  pulseOximeterDeviceId: number;
  bloodGlucoseDeviceId: number;
}

interface IProps {
  onSubmit: (values: IFormValues) => void;
  devices: IDeviceSelector[];
  disabledForm: boolean;
  patientDevices: any;
}

const Form = ({ onSubmit, disabledForm, devices, patientDevices }: IProps): ReactElement => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IFormValues>();

  const defaultOption = {
    value: '',
    label: 'Not Selected',
  };

  const bloodPressureDeviceOptions = devices
    .filter(item => DeviceType.BloodPressure === item.typeId)
    .map(item => ({
      value: item.id,
      label: item.name,
    }));

  const weightScaleDeviceOptions = devices
    .filter(item => DeviceType.WeightScale === item.typeId)
    .map(item => ({
      value: item.id,
      label: item.name,
    }));

  const pulseOximeterDeviceOptions = devices
    .filter(item => DeviceType.PulseOximeter === item.typeId)
    .map(item => ({
      value: item.id,
      label: item.name,
    }));

  const bloodGlucoseDeviceOptions = devices
    .filter(item => DeviceType.BloodGlucose === item.typeId)
    .map(item => ({
      value: item.id,
      label: item.name,
    }));

  const isDisabledField = (devicesType: DeviceType) =>
    patientDevices?.find((el: any) => el.typeId === devicesType);

  return (
    <div className={styles.container}>
      <h4 className={styles.formTopHeader}> Assign Device</h4>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <SelectVirtualized
              name="bloodPressureDeviceId"
              control={control}
              errors={errors}
              options={[defaultOption, ...bloodPressureDeviceOptions]}
              isDisabled={isDisabledField(DeviceType.BloodPressure)}
              label="Blood Pressure *"
            />
          </Grid>
          <Grid item xs={4}>
            <SelectVirtualized
              name="weightScaleDeviceId"
              control={control}
              errors={errors}
              options={[defaultOption, ...weightScaleDeviceOptions]}
              isDisabled={isDisabledField(DeviceType.WeightScale)}
              label="Weight Scale *"
            />
          </Grid>
          <Grid item xs={4}>
            <SelectVirtualized
              name="pulseOximeterDeviceId"
              control={control}
              errors={errors}
              options={[defaultOption, ...pulseOximeterDeviceOptions]}
              isDisabled={isDisabledField(DeviceType.PulseOximeter)}
              label="Pulse Oximeter *"
            />
          </Grid>
          <Grid item xs={4}>
            <SelectVirtualized
              name="bloodGlucoseDeviceId"
              control={control}
              errors={errors}
              options={[defaultOption, ...bloodGlucoseDeviceOptions]}
              isDisabled={isDisabledField(DeviceType.BloodGlucose)}
              label="Blood Glucose *"
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          startIcon={<SaveIcon />}
          variant="contained"
          color="primary"
          className={styles.button}
          disabled={disabledForm}
        >
          <span className={styles.divider} />
          Assign
        </Button>
      </form>
    </div>
  );
};

export default Form;
