import { ReactElement } from 'react';

import { getBatteryChargeLevel } from '~/utils/deviceReading';
import { levels } from '../constants';
import styles from './Battery.module.scss';

interface IProps {
  levelValue: number;
}

const Battery = ({ levelValue }: IProps): ReactElement => {
  const batteryLevel = getBatteryChargeLevel(levelValue);
  const levelName = levels[batteryLevel].title;
  const { color } = levels[batteryLevel];

  const wrapperStyle = {
    border: `1px solid ${color}`,
  };

  const indicatorStyle = {
    width: `${levelValue}%`,
    backgroundColor: color,
  };

  const squareStyle = {
    backgroundColor: color,
  };

  return (
    <div className={styles.battery} title={`Battery level (${levelName})`}>
      <div style={wrapperStyle} className={styles.batteryWrapper}>
        <div style={indicatorStyle} className={styles.indicator} />
        <div style={squareStyle} className={styles.square} />
      </div>
      <span className={styles.title}>{`(${levelName})`}</span>
    </div>
  );
};

export default Battery;
