import { ReactElement, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import 'react-phone-input-2/lib/material.css';

import { useStoreActions } from '~/store/hooks';
import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import NotFound from './components/layouts/NotFoundPage';
import SignIn from './pages/SignIn';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Programs from './pages/Programs';
import Profile from './pages/Profile';
import Users from './pages/Users';
import Patients from './pages/Patients';
import Inventory from './pages/Inventory';
import ActivityLogs from './pages/ActivityLogs/ActivityLogsList';
import PatientActivityLogs from './pages/ActivityLogs/PatientActivityLogs';
import PatientDashboard from './pages/Patient/Dashboard';
import PatientDevices from './pages/Patient/Devices';
import PatientNotificationRules from './pages/Patient/NotificationRules';
import PatientNotes from './pages/Patient/Notes';
import PatientPlanOfCare from './pages/Patient/PlanOfCare';
import PatientReports from './pages/Patient/Reports';
import Reports from './pages/Reports';
import AuditLogs from './pages/AuditLogs';

import muiTheme from './themes/muiTheme';
import {
  SIGN_IN,
  DASHBOARD,
  PROGRAMS,
  PROFILE,
  USERS,
  PATIENTS,
  INVENTORY,
  PATIENT_DASHBOARD,
  PATIENT_DEVICES,
  PATIENT_NOTIFICATION_RULES,
  PATIENT_NOTES,
  PATIENT_PLAN_OF_CARE,
  PATIENT_REPORTS,
  ACTIVITY_LOGS,
  ACTIVITY_LOGS_PATIENT,
  REPORTS,
  AUDIT_LOGS,
} from './constants/paths';

import './assets/styles/app.scss';
import { useTrackUserActivity } from '~/utils/useTrackUserActivity';
import { setUpAxiosInterceptors } from '~/utils/setUpAxiosInterceptors';

const App = (): ReactElement => {
  const { onCheckAuthorization } = useStoreActions(actions => actions.auth);

  useEffect(() => {
    onCheckAuthorization();
  }, []);

  setUpAxiosInterceptors();
  useTrackUserActivity();

  return (
    <ThemeProvider theme={muiTheme}>
      <Router>
        <Switch>
          <PublicRoute exact path={SIGN_IN} component={SignIn} />
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute exact path={DASHBOARD} component={Dashboard} />
          <PrivateRoute exact path={PROGRAMS} component={Programs} />
          <PrivateRoute exact path={PROFILE} component={Profile} />
          <PrivateRoute path={USERS} component={Users} />
          <PrivateRoute exact path={PATIENTS} component={Patients} />
          <PrivateRoute path={INVENTORY} component={Inventory} />
          <PrivateRoute exact path={ACTIVITY_LOGS} component={ActivityLogs} />
          <PrivateRoute exact path={ACTIVITY_LOGS_PATIENT} component={PatientActivityLogs} />
          <PrivateRoute path={PATIENT_DASHBOARD} component={PatientDashboard} isPatientLayout />
          <PrivateRoute exact path={PATIENT_DEVICES} component={PatientDevices} isPatientLayout />
          <PrivateRoute
            exact
            path={PATIENT_NOTIFICATION_RULES}
            component={PatientNotificationRules}
            isPatientLayout
          />
          <PrivateRoute exact path={PATIENT_NOTES} component={PatientNotes} isPatientLayout />
          <PrivateRoute
            exact
            path={PATIENT_PLAN_OF_CARE}
            component={PatientPlanOfCare}
            isPatientLayout
          />
          <PrivateRoute exact path={PATIENT_REPORTS} component={PatientReports} isPatientLayout />
          <PrivateRoute exact path={REPORTS} component={Reports} />
          <PrivateRoute exact path={AUDIT_LOGS} component={AuditLogs} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
};
export default App;
