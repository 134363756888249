import { action } from 'easy-peasy';
import { IActionAddInsurance } from '../types';

const addInsurance: IActionAddInsurance = action((state, payload) => {
  state.items = [payload, ...state.items].sort(
    (a, b) => new Date(b.expiresAt).getTime() - new Date(a.expiresAt).getTime(),
  );
});

export default addInsurance;
