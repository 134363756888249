import { ReactElement, ReactNode } from 'react';

import styles from './GraphCard.module.scss';

interface IProps {
  children?: ReactNode;
  title: string;
  showNoData?: boolean;
}

const GraphCard = ({ children, title, showNoData = false }: IProps): ReactElement => (
  <div className={styles.container}>
    <div className={styles.header}>{title}</div>
    <div className={styles.inner}>
      {showNoData ? <div className={styles.noData}>No data found</div> : <div>{children}</div>}
    </div>
  </div>
);

export default GraphCard;
