import { AxiosResponse } from 'axios';

export const extractErrorMessage = (e: any, defaultError?: string) => {
  if (!e) {
    return 'Unknown Error Occurred.';
  }

  const error = (e.isAxiosError ? e.response : e) as AxiosResponse;

  if (error.status === 500) {
    return 'Internal Server Error.';
  }

  if (error.status === 404) {
    return 'Not Found.';
  }

  if (error.status === 400) {
    return Object.values<string[]>(error.data?.errors ?? {})[0]?.[0] ?? error.data;
  }

  return defaultError ?? 'Unknown Error Occurred';
};

export default {};
