import { DeviceType } from '~/services/api/enums';
import { getCurrentMonthFirstDayDate, setEndOfDay } from '~/utils/date';

export const getInitialFilters = () => ({
  startDate: getCurrentMonthFirstDayDate(),
  endDate: setEndOfDay(new Date()), // use `today` as endDate
  deviceTypeId: null as DeviceType,
});

export default {};
