import { IActivityLogsStore } from './types';

const initStore: IActivityLogsStore = {
  items: [],
  pagination: {
    page: 0,
    itemsPerPage: 20,
    hasMore: false,
  },
  itemsByPatientId: [],
  paginationByPatientId: {
    page: 0,
    itemsPerPage: 20,
    hasMore: false,
  },
};

export default initStore;
