import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkUpdateTableCustomizationsByTableTypeId } from '../types';

const onUpdateTableCustomizationsByTableTypeId: IThunkUpdateTableCustomizationsByTableTypeId =
  thunk(
    // @ts-ignore
    async ({ setTableCustomizations }, { tableTypeId, items }) => {
      const { data } = await api.tableCustomizations.updateByTableTypeId(tableTypeId, items);

      setTableCustomizations({ tableTypeId, items: data });
    },
  );

export default onUpdateTableCustomizationsByTableTypeId;
