import { ReactElement } from 'react';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';

import { isoToFormat } from '~/utils/date';

import { IPagePermissions } from '../../../types';
import styles from './Item.module.scss';
import { INote } from '~/services/api/notes/types';
import ReadMoreLess from '~/ui/components/common/ReadMoreLess';
import { Timezone } from '~/types';

interface IProps {
  note: INote;
  userId: number;
  permissions: IPagePermissions;
  onUpdateNote: (val: INote) => void;
  onDeleteNote: (noteId: number) => void;
}

const Item = ({ note, userId, permissions, onUpdateNote, onDeleteNote }: IProps): ReactElement => (
  <div className={styles.itemContainer}>
    <div className={styles.header}>
      <div>
        <span className={styles.bold}>Date: </span>
        <span>
          {isoToFormat(note.createdAt, 'dd MMM y H:mm ')}
          {note.patientTimezone}
        </span>
        <span className={styles.author}>
          <span className={styles.bold}>Author: </span>
          <span>{note.userFullName}</span>
        </span>
        <span className={styles.author}>
          <span className={styles.bold}>Telephone conversation: </span>
          <span>{note.isTelephoneConversation ? 'Yes' : 'No'}</span>
        </span>
        <span className={styles.author}>
          <span className={styles.bold}>Reminder to follow up: </span>
          <span>
            {note.reminderDate
              ? `Yes, ${isoToFormat(note.reminderDate, 'dd MMM y', Timezone.GMT, false)}`
              : 'No'}
          </span>
        </span>
      </div>
      {(note.userId === userId || permissions.canManage) && (
        <div className={styles.iconButtonWrapper}>
          <IconButton className={styles.iconButton} onClick={() => onUpdateNote(note)}>
            <CreateIcon className={styles.icon} />
          </IconButton>
          <IconButton
            className={styles.iconButton}
            title="Remove"
            onClick={() => onDeleteNote(note.id)}
          >
            <DeleteIcon className={styles.icon} />
          </IconButton>
        </div>
      )}
    </div>
    <ReadMoreLess content={note.content} />
  </div>
);

export default Item;
