import { DeviceStatus } from '~/services/api/enums';
import { IDevice } from '~/services/api/devices/types';
import { DeviceAction, IActions } from './types';

const deviceStatusActionsMap = {
  [DeviceStatus.Available]: [DeviceAction.Assign, DeviceAction.MarkAsLost, DeviceAction.Delete],
  [DeviceStatus.Ready]: [DeviceAction.Unassign, DeviceAction.MarkAsLost],
  [DeviceStatus.Issued]: [DeviceAction.Unregister],
  [DeviceStatus.Returning]: [DeviceAction.MarkAsReturned, DeviceAction.MarkAsLost],
  [DeviceStatus.Returned]: [DeviceAction.Assign, DeviceAction.MarkAsLost, DeviceAction.Delete],
  [DeviceStatus.Lost]: [DeviceAction.MarkAsReturned, DeviceAction.Delete],
};

export const getDevicesActions = (devices: IDevice[], checkedDevicesIds: number[]): IActions =>
  devices
    .filter(x => checkedDevicesIds.includes(x.id))
    .flatMap(x => deviceStatusActionsMap[x.status].map(action => ({ action, id: x.id })))
    .reduce((prev, device) => ({ ...prev, [device.action]: [...prev[device.action], device.id] }), {
      [DeviceAction.Assign]: [],
      [DeviceAction.Unassign]: [],
      [DeviceAction.Unregister]: [],
      [DeviceAction.MarkAsReturned]: [],
      [DeviceAction.MarkAsLost]: [],
      [DeviceAction.Delete]: [],
    });

export default {};
