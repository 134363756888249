import { ReactElement } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { getChartOptions } from './helper';
import GraphCard from '~/ui/components/reusable/GraphCard';
import { IReading } from '~/services/api/readings/types';
import { DeviceType } from '~/services/api/enums';
import { deviceTypeDefinitions } from '~/ui/pages/Dashboard/popups/DevicesPopup/constants';

interface IProps {
  readings: IReading[];
  deviceTypeId: DeviceType;
}

const MainChart = ({ readings, deviceTypeId }: IProps): ReactElement => {
  const title = `${deviceTypeDefinitions[deviceTypeId].label} Chart`;

  if (readings.length === 0) {
    return <GraphCard title={title} showNoData />;
  }

  const options = getChartOptions(readings, deviceTypeId);

  return (
    <GraphCard title={title}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </GraphCard>
  );
};

export default MainChart;
