import { ReactElement } from 'react';

import { ActionType } from '~/ui/layouts/types';
import styles from './Menu.module.scss';

interface IProps {
  show: boolean;
  onAction: (actionType: ActionType) => void;
  onClose: () => void;
}

const Menu = ({ show, onAction, onClose }: IProps): ReactElement => {
  if (!show) return null;

  return (
    <div className={styles.menuWrapper}>
      <div role="presentation" className={styles.overlay} onClick={onClose} />
      <ul className={styles.menu}>
        <li className={styles.item}>
          <div
            role="presentation"
            className={styles.itemLink}
            onClick={() => onAction(ActionType.Profile)}
          >
            Profile
          </div>
        </li>
        <li role="presentation" className={styles.item} onClick={() => onAction(ActionType.LogOut)}>
          <span className={styles.itemLink}>Logout</span>
        </li>
      </ul>
    </div>
  );
};

export default Menu;
