import { ReactElement, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { Grid } from '@material-ui/core';
import { useForm, useWatch } from 'react-hook-form';
import SearchIcon from '@mui/icons-material/Search';
import RotateRightIcon from '@mui/icons-material/RotateRight';

import Button from '~/ui/components/common/Button';
import DatePicker from '~/ui/components/inputs/DatePicker';

import validate from './validate';
import styles from './Header.module.scss';
import Select from '~/ui/components/inputs/Select';
import { IOption } from '~/ui/components/inputs/Select/types';
import { IPatientOverviewFilters } from '~/services/api/patients/types';
import { useStoreState } from '~/store/hooks';

interface IProps {
  onSubmit: (val: IPatientOverviewFilters) => void;
  deviceTypeOptions: IOption[];
  defaultValues: IPatientOverviewFilters;
}

const Header = ({ onSubmit, deviceTypeOptions, defaultValues }: IProps): ReactElement => {
  const overviewFilters = useStoreState(state => state.patient.overviewFilters);

  const formMethods = useForm({
    resolver: validate,
    defaultValues: overviewFilters ?? defaultValues,
  });

  const {
    reset,
    control,
    formState: { errors },
    handleSubmit,
  } = formMethods;

  const deviceTypeId = useWatch({
    control,
    name: 'deviceTypeId',
  });

  useEffect(() => {
    onSubmit({
      ...(overviewFilters ?? defaultValues),
      deviceTypeId,
    });
  }, [deviceTypeId]);

  return (
    <Paper elevation={24} square className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid item xs>
            <DatePicker
              placeholder=".. /.. /.."
              label="Start Date"
              name="startDate"
              maxDate={new Date()}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs>
            <DatePicker
              placeholder=".. /.. /.."
              label="End Date"
              name="endDate"
              maxDate={new Date()}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs>
            <Select
              name="deviceTypeId"
              control={control}
              errors={errors}
              options={deviceTypeOptions}
              label="Device Type"
              isDisabled={deviceTypeOptions.length === 1}
            />
          </Grid>
          <Grid item xs={5} />
          <Grid item>
            <Button variant="outlined" type="submit" color="primary" startIcon={<SearchIcon />}>
              Search
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              type="button"
              startIcon={<RotateRightIcon />}
              onClick={() => {
                const resetValues = {
                  ...defaultValues,
                  deviceTypeId: overviewFilters.deviceTypeId,
                };

                reset(resetValues);
                onSubmit(resetValues);
              }}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default Header;
