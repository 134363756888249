import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkUpdateInsurance } from '../types';

// @ts-ignore
const onUpdateInsurance: IThunkUpdateInsurance = thunk(async ({ updateInsurance }, payload) => {
  const insurance = await api.insurances.update(payload).then(res => res.data);
  updateInsurance(insurance);
});

export default onUpdateInsurance;
