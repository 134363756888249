import { format, formatISO } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { Timezone, TimezoneCode } from '~/types';

export const parseDateFromISO = (dateISO: string): string => formatISO(new Date(dateISO));

export const formatDateToISO = (date: string, timezone: Timezone): string => {
  // `datetime` value is comming formatted in patient timezone, but with redundant browser local offset suffix
  // For example, incoming datetime in CST '2021-09-24T05:45:00+03:00'
  // should be converted to outcoming UTC '2021-09-24T10:45:00Z'
  // (CST zone offset = -05:00)

  const cleanDate = format(new Date(date), "yyyy-MM-dd'T'HH:mm:ss");

  // Convert to UTC
  return zonedTimeToUtc(cleanDate, TimezoneCode[timezone]).toISOString();
};
