import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getPaginationPayload } from '~/utils/pagination';
import { IMedicationsStore, IThunkGetMoreMedications } from '../types';

const onGetMoreMedications: IThunkGetMoreMedications = thunk(
  // @ts-ignore
  async ({ setMoreMedications }, filters, { getState }) => {
    const localState = getState() as IMedicationsStore;

    const paginationPayload = getPaginationPayload(localState.pagination);

    const { data } = await api.medications.getList({
      ...filters,
      ...paginationPayload,
    });

    setMoreMedications(data);
  },
);

export default onGetMoreMedications;
