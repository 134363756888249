import { useState, ReactElement } from 'react';
import Modal from '~/ui/components/common/Modal';

import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import initialValues from './form/initialValues';
import MedicationForm from '~/ui/components/reusable/MedicationForm';

import { IFormValues } from '~/ui/components/reusable/MedicationForm/types';
import styles from './AddMedication.module.scss';
import { IMedication } from '~/services/api/medications/types';

interface IProps {
  patientId: number;
  onClose: () => void;
}

const AddMedication = ({ patientId, onClose }: IProps): ReactElement => {
  const [isAdding, setIsAdding] = useState(false);

  const onCreateMedication = useStoreActions(actions => actions.medications.onCreateMedication);
  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);

  const addMedication = async (data: IFormValues) => {
    try {
      setIsAdding(true);
      await onCreateMedication({ patientId, data: data as IMedication });
      showNotify('Medication successfully added');
      onClose();
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsAdding(false);
    }
  };

  const handleClose = () => {
    // prevent closing popup when medication is adding
    if (isAdding) return;
    onClose();
  };

  return (
    <Modal open title="Add Medication" className={styles.modal} onClose={handleClose}>
      <MedicationForm
        initialValues={initialValues}
        isProcessing={isAdding}
        onSubmit={addMedication}
      />
    </Modal>
  );
};

export default AddMedication;
