import React, { ReactElement } from 'react';
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import { useStoreState, useStoreActions } from '~/store/hooks';

const SnackbarComponent = (): ReactElement | null => {
  const message = useStoreState(state => state.snackbar.message);
  const isError = useStoreState(state => state.snackbar.isError);
  const hide = useStoreActions(actions => actions.snackbar.hide);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;
    hide();
  };

  if (!message) return null;

  const anchorOrigin: SnackbarOrigin = {
    vertical: 'top',
    horizontal: 'center',
  };

  const severity = isError ? 'error' : 'success';

  return (
    <Snackbar anchorOrigin={anchorOrigin} open autoHideDuration={4000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarComponent;
