import classnames from 'classnames';
import Box from '@material-ui/core/Box';
import Popper, { PopperPlacementType } from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { getActions } from './helper';

import styles from './Actions.module.scss';
import { DeviceAction, IActions } from '../../types';

interface IProps {
  anchorElement: HTMLElement;
  placement?: PopperPlacementType;
  actions: IActions;
  allDevices?: boolean;
  onSelect: (action: DeviceAction, devicesIds: number[]) => void;
  onClose: () => void;
}

const Actions = ({
  anchorElement,
  placement = 'bottom-end',
  actions,
  allDevices = false,
  onSelect,
  onClose,
}: IProps) =>
  Boolean(anchorElement) && (
    <ClickAwayListener onClickAway={onClose}>
      <Popper open anchorEl={anchorElement} placement={placement} className={styles.popper}>
        <Box boxShadow={3} className={styles.actions}>
          {getActions(actions).map((item, index) => {
            const amount = item.devicesIds.length;

            return allDevices || amount ? (
              <button
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                type="button"
                disabled={!amount}
                className={classnames(styles.btn, { [styles.btnDisabled]: !amount })}
                onClick={() => {
                  onSelect(item.action, item.devicesIds);
                  onClose();
                }}
              >
                {item.icon}
                <div className={styles.title}>
                  {item.title}
                  {allDevices && !!amount && ` (${amount})`}
                </div>
              </button>
            ) : null;
          })}
        </Box>
      </Popper>
    </ClickAwayListener>
  );

export default Actions;
