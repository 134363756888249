import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import InfiniteScroll from 'react-infinite-scroll-component';

import Loader from '~/ui/components/common/Loader';
import NoDataFound from '~/ui/components/reusable/NoDataFound';
import Item from './Item';

import { IDeviceHistoryLog } from '~/services/api/devices/types';
import styles from './HistoryList.module.scss';

interface IProps {
  items: IDeviceHistoryLog[];
  hasMore: boolean;
  onLoadMore: () => void;
}

const HistoryList = ({ items, hasMore, onLoadMore }: IProps) => (
  <TableContainer id="scrollableDiv" className={styles.tableContainer}>
    <InfiniteScroll
      next={onLoadMore}
      hasMore={hasMore}
      dataLength={items.length}
      loader={<Loader />}
      scrollableTarget="scrollableDiv"
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date and Time</TableCell>
            <TableCell>User</TableCell>
            <TableCell>Event</TableCell>
            <TableCell>Program</TableCell>
            <TableCell>Patient</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.length ? (
            items.map(item => <Item key={item.id} item={item} />)
          ) : (
            <TableRow>
              <TableCell colSpan={5}>
                <NoDataFound />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </InfiniteScroll>
  </TableContainer>
);

export default HistoryList;
