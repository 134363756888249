import { ReactElement, useState } from 'react';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import AddIcon from '@mui/icons-material/Add';
import { withStyles } from '@material-ui/core/styles';

import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import Button from '~/ui/components/common/Button';
import NoDataFound from '~/ui/components/reusable/NoDataFound';

import { IProgramList } from '~/services/api/programs/types';
import styles from './AvailableProgramsTable.module.scss';

const StyledCheckbox = withStyles({
  root: {
    padding: 0,
  },
})(Checkbox);

const StyledButton = withStyles({
  root: {
    marginBottom: 10,
    alignSelf: 'flex-end',
    fontWeight: 300,
  },
})(Button);

interface IProps {
  currentUserId: number;
  availablePrograms: IProgramList[];
  onRefresh: () => void;
}
const AvailableProgramsTable = ({
  currentUserId,
  availablePrograms,
  onRefresh,
}: IProps): ReactElement => {
  const [selectedPrograms, setSelectedPrograms] = useState([]);
  const [loading, setLoading] = useState(false);

  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);
  const onAddPrograms = useStoreActions(actions => actions.users.onAddPrograms);

  const isChecked = (programId: number) => includes(selectedPrograms, programId);
  const setProgram = (programId: number) =>
    isChecked(programId)
      ? setSelectedPrograms(prev => filter(prev, p => p !== programId))
      : setSelectedPrograms([...selectedPrograms, programId]);

  const onAssignPrograms = async (programsForAssigning: number[]) => {
    const payload = { id: currentUserId, programs: programsForAssigning };

    try {
      setLoading(true);
      await onAddPrograms(payload);
      onRefresh(); // refresh data in tables
      setSelectedPrograms([]);
      showNotify('Programs successfully assigned');
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.tableContainer}>
      <StyledButton
        disabled={!selectedPrograms.length || loading}
        color="primary"
        variant="contained"
        startIcon={<AddIcon />}
        className={styles.gutterBottom}
        onClick={() => onAssignPrograms(selectedPrograms)}
      >
        <span className={styles.divider} />
        Associate
      </StyledButton>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Program</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {availablePrograms.length ? (
              availablePrograms.map(program => {
                const checked = isChecked(program.id);

                return (
                  <TableRow key={program.id}>
                    <TableCell>
                      <StyledCheckbox
                        color="primary"
                        onChange={() => setProgram(program.id)}
                        checked={checked}
                      />
                    </TableCell>
                    <TableCell className={styles.cell}>{program.name}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={2}>
                  <NoDataFound />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AvailableProgramsTable;
