import { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';

import PhoneFlagInput from '~/ui/components/inputs/PhoneFlag';

const ContactInfo = (): ReactElement => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <PhoneFlagInput name="homePhone" control={control} errors={errors} label="Home Phone" />
      </Grid>
      <Grid item xs={4}>
        <PhoneFlagInput name="cellPhone" control={control} errors={errors} label="Cell Phone" />
      </Grid>
      <Grid item xs={4}>
        <PhoneFlagInput name="workPhone" control={control} errors={errors} label="Work Phone" />
      </Grid>
    </Grid>
  );
};

export default ContactInfo;
