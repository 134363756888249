import { ReactElement, useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import Loader from '~/ui/components/common/Loader';
import styles from './MobileUser.module.scss';

import Header from './Header';
import Table from './Table/Table';
import { IMobileUsersListFilters } from '~/services/api/mobileUsers/types';

const MobileUser = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState<IMobileUsersListFilters>(null);

  const { onGetMobileUsers, onGetMoreMobileUsers } = useStoreActions(
    actions => actions.mobileUsers,
  );
  const { items: mobileUsers, itemsPagination: pagination } = useStoreState(
    state => state.mobileUsers,
  );
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const onMount = async () => {
    try {
      await onGetMobileUsers(filters);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  const onLoadMore = async () => {
    try {
      await onGetMoreMobileUsers(filters);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    onMount();
  }, [filters]);

  return (
    <>
      <div className={styles.Header}>
        <Header onSubmit={setFilters} />
      </div>

      <InfiniteScroll
        dataLength={mobileUsers.length}
        next={onLoadMore}
        hasMore={pagination.hasMore}
        loader={<Loader />}
      >
        <Table mobileUsers={mobileUsers} isLoading={isLoading} />
      </InfiniteScroll>
    </>
  );
};

export default MobileUser;
