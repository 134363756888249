import { dayPartTimeInterval } from '~/ui/constants/deviceReadings';
import { daysMap } from '~/ui/constants/charts';
import { Day, DayPart } from '~/types';
import { INotificationRule } from '~/services/api/notificationRules/types';
import { Level } from './types';
import { RuleCondition, RuleType } from '~/services/api/enums';

// max signalLevel = 31
export const getSignalLevel = (signalLevel: number): Level => {
  if (signalLevel === null) return Level.Good;
  if (signalLevel <= 10) return Level.Poor;
  if (signalLevel <= 20) return Level.Fair;
  return Level.Good;
};

export const getBatteryChargeLevel = (batteryLevel: number): Level => {
  if (batteryLevel === null) return Level.Good;
  if (batteryLevel <= 20) return Level.Poor;
  if (batteryLevel <= 50) return Level.Fair;
  return Level.Good;
};

export const getRuleLevelWarning = (
  rules: INotificationRule[],
  ruleTypeId: RuleType,
  value: number,
): number =>
  Math.max(
    0,
    ...rules
      .filter(x => x.typeId === ruleTypeId)
      .map(rule => {
        if (rule.conditionId === RuleCondition.Above) {
          if (value >= rule.level3) {
            return 3;
          }

          if (value >= rule.level2) {
            return 2;
          }

          if (value >= rule.level1) {
            return 1;
          }
        }

        if (rule.conditionId === RuleCondition.Below) {
          if (value <= rule.level3) {
            return 3;
          }

          if (value <= rule.level2) {
            return 2;
          }

          if (value <= rule.level1) {
            return 1;
          }
        }

        return 0;
      }),
  );

export const getWeightRuleLevelWarning = (
  rules: INotificationRule[],
  condition: RuleCondition,
  value: number,
): number =>
  value === null
    ? 0
    : Math.max(
        0,
        ...rules
          .filter(x => x.typeId === RuleType.Weight && x.conditionId === condition)
          .map(rule => {
            if (Math.abs(value) >= rule.level3) {
              return 3;
            }

            if (Math.abs(value) >= rule.level2) {
              return 2;
            }

            if (Math.abs(value) >= rule.level1) {
              return 1;
            }

            return 0;
          }),
      );

export const getDay = (measuredAt: string): Day => {
  const date = new Date(measuredAt);

  const dayNumber = date.getDay();

  if (dayNumber in daysMap) {
    return daysMap[dayNumber];
  }

  return Day.Monday;
};

export const getDayPart = (measuredAt: string): DayPart => {
  const measuredAtHour = new Date(measuredAt).getHours();

  const morningStartHour = dayPartTimeInterval.morning.start;
  const morningEndHour = dayPartTimeInterval.morning.end;

  const noonStartHour = dayPartTimeInterval.noon.start;
  const noonEndHour = dayPartTimeInterval.noon.end;

  const eveningStartHour = dayPartTimeInterval.evening.start;
  const eveningEndHour = dayPartTimeInterval.evening.end;

  const nightStartHour = dayPartTimeInterval.night.start;
  const nightEndHour = dayPartTimeInterval.night.end;

  const isMorning = measuredAtHour >= morningStartHour && measuredAtHour < morningEndHour;
  const isNoon = measuredAtHour >= noonStartHour && measuredAtHour < noonEndHour;
  const isEvening = measuredAtHour >= eveningStartHour && measuredAtHour < eveningEndHour;
  const isNight = measuredAtHour >= nightStartHour || measuredAtHour < nightEndHour;

  switch (true) {
    case isMorning: {
      return DayPart.Morning;
    }
    case isNoon: {
      return DayPart.Noon;
    }
    case isEvening: {
      return DayPart.Evening;
    }
    case isNight: {
      return DayPart.Night;
    }
    default: {
      return DayPart.Morning;
    }
  }
};
