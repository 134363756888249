import { ISort } from './types';

export const getSort = (sort: ISort, id: string): ISort => {
  const { isDescending, orderBy } = sort;

  const newSort = {
    isDescending: false,
    orderBy: id,
  };

  if (orderBy !== id) {
    return newSort;
  }

  newSort.isDescending = !isDescending;

  return newSort;
};

export default {};
