import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkRemoveUser } from '../types';

// @ts-ignore
const onRemoveUser: IThunkRemoveUser = thunk(async ({ removeUser }, id) => {
  await api.users.remove(id);
  removeUser(id);
});

export default onRemoveUser;
