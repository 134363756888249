import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';

import api from '~/services/api';
import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import { initialValues, validate } from './form';
import Button from '~/ui/components/common/Button';
import Input from '~/ui/components/inputs/Input';

import { IIcd10Code } from '~/services/api/icd10Codes/types';
import { IFormValues } from './form/types';
import styles from './CreateCode.module.scss';

interface IProps {
  onClose: () => void;
}

const CreateCode = ({ onClose }: IProps) => {
  const [isCreating, setIsCreating] = useState(false);

  const { showError } = useStoreActions(actions => actions.snackbar);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: validate,
    defaultValues: initialValues,
  });

  const createIcd10Code = async (payload: IIcd10Code) => {
    try {
      setIsCreating(true);
      await api.icd10Codes.create(payload);
      onClose();
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsCreating(false);
    }
  };

  const onSubmit = (values: IFormValues) => {
    createIcd10Code(values as IIcd10Code);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Input name="name" label="Code *" register={register} errors={errors} />
        </Grid>
        <Grid item xs={9}>
          <Input name="description" label="Description *" register={register} errors={errors} />
        </Grid>
      </Grid>
      <div className={styles.btnWrapper}>
        <Button variant="outlined" color="default" disabled={isCreating} onClick={onClose}>
          Cancel
        </Button>
        <div className={styles.widthSpacer} />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          isLoading={isCreating}
          disabled={isCreating}
        >
          Save
        </Button>
      </div>
    </form>
  );
};

export default CreateCode;
