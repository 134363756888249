const homeHealthCareOptions = [
  {
    value: '1',
    label: 'Yes',
  },
  {
    value: '0',
    label: 'No',
  },
];

export default homeHealthCareOptions;
