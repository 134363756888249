export const getFileNameFromContentDisposition = (header: string): string => {
  if (!header || !header.trim()) return '';

  let fileName = header.split('filename=')[1];
  if (!fileName) return '';
  // eslint-disable-next-line
  fileName = fileName.split(';')[0];
  return fileName;
};

export default {};
