import { ReactElement } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import MainChart from './MainChart';
import ReadingSummary from './ReadingSummary';
import NotificationsSummary from './NotificationsSummary';
import MorningEvening from './MorningEvening';
import ReadingCount from './ReadingCount';
import WeeklyAnalysis from './WeeklyAnalysis';

import { IPatientOverview } from '~/services/api/patients/types';
import styles from './Charts.module.scss';
import { DeviceType } from '~/services/api/enums';

interface IProps {
  patientOverview: IPatientOverview;
  deviceTypeId: DeviceType;
}

const Charts = ({ patientOverview, deviceTypeId }: IProps): ReactElement => (
  <>
    <Paper elevation={24} square className={styles.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MainChart readings={patientOverview.readings} deviceTypeId={deviceTypeId} />
        </Grid>
        <Grid item xs={6}>
          <ReadingSummary readings={patientOverview.readings} deviceTypeId={deviceTypeId} />
        </Grid>
        <Grid item xs={6}>
          <NotificationsSummary
            notificationRules={patientOverview.notificationRules}
            readings={patientOverview.readings}
            deviceTypeId={deviceTypeId}
          />
        </Grid>
        <Grid item xs={6}>
          <MorningEvening readings={patientOverview.readings} deviceTypeId={deviceTypeId} />
        </Grid>
        <Grid item xs={6}>
          <ReadingCount readings={patientOverview.readings} />
        </Grid>
      </Grid>
    </Paper>
    <div className={styles.spacer} />
    <WeeklyAnalysis readings={patientOverview.readings} deviceTypeId={deviceTypeId} />
  </>
);

export default Charts;
