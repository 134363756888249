import { IDevicesListFilters } from '~/services/api/devices/types';
import { DeviceStatus, DeviceSupplier, DeviceType } from '~/services/api/enums';

export const initialValues: IDevicesListFilters = {
  imei: '',
  status: '' as DeviceStatus,
  typeId: '' as DeviceType,
  supplier: '' as DeviceSupplier,
};

export default {};
