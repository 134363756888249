import { ReactElement } from 'react';
import classnames from 'classnames';

import logo from 'logo/logo.svg';
import logoSmall from 'logo/logo-small.svg';
import styles from './Logo.module.scss';

interface IProps {
  isExpanded: boolean;
}

const Logo = ({ isExpanded }: IProps): ReactElement => (
  <div className={classnames(styles.logoWrapper, { [styles.expanded]: isExpanded })}>
    <img src={isExpanded ? logo : logoSmall} className={styles.logo} alt="Company Logo" />
  </div>
);

export default Logo;
