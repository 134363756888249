import initStore from './initStore';
import actions from './actions';
import thunks from './thunks';

import { INotesModel } from './types';

const notes: INotesModel = {
  ...initStore,
  ...actions,
  ...thunks,
};

export default notes;
