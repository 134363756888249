import { useState, ReactElement, Dispatch, SetStateAction } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import MuiTable from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import { IPagination } from '~/utils/pagination';
import Loader from '~/ui/components/common/Loader';
import Button from '~/ui/components/common/Button';
import NoDataFound from '~/ui/components/reusable/NoDataFound';

import { IUserPatient } from '~/services/api/users/types';
import styles from './Table.module.scss';

interface IProps {
  mobileUserTypeId: number;
  onLoadMore: () => void;
  assignedPatients: IUserPatient[];
  pagination: IPagination;
  setAssignedPatients: Dispatch<SetStateAction<IUserPatient[]>>;
}

const Table = ({
  mobileUserTypeId,
  onLoadMore,
  pagination,
  assignedPatients,
  setAssignedPatients,
}: IProps): ReactElement => {
  const [unassignPatientList, setUnassignPatientList] = useState([]);

  const [isRemoving, setIsRemoving] = useState(false);
  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);
  const onRemovePatients = useStoreActions(state => state.mobileUsers.onRemovePatients);

  const removePatients = async () => {
    const payload = { id: mobileUserTypeId, patients: unassignPatientList };
    try {
      setIsRemoving(true);
      await onRemovePatients(payload);
      setAssignedPatients(prev => prev.filter(item => !unassignPatientList.includes(item.id)));
      showNotify('Patients successfully unassigned');
      setUnassignPatientList([]);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
    setIsRemoving(false);
  };

  const onChange = (currentPatientId: number) =>
    unassignPatientList.includes(currentPatientId)
      ? setUnassignPatientList(prev => prev.filter(patientId => patientId !== currentPatientId))
      : setUnassignPatientList([...unassignPatientList, currentPatientId]);

  const tableCells = ['Patient', 'Patient Id', 'Select'];
  return (
    <div className={styles.container}>
      <TableContainer id="scrollableDiv" className={styles.tableContainer}>
        <InfiniteScroll
          next={onLoadMore}
          hasMore={pagination.hasMore}
          dataLength={assignedPatients.length}
          loader={<Loader />}
          scrollableTarget="scrollableDiv"
        >
          <MuiTable>
            <TableHead>
              <TableRow>
                {tableCells.map((cellName, id) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <TableCell key={id}>{cellName}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {assignedPatients.length ? (
                assignedPatients.map(patient => {
                  const checked = unassignPatientList.includes(patient.id);
                  return (
                    <TableRow key={patient.id}>
                      <TableCell>{patient.fullName}</TableCell>
                      <TableCell>{patient.subjectId}</TableCell>
                      <TableCell>
                        <Checkbox
                          checked={checked}
                          color="primary"
                          onChange={() => onChange(patient.id)}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>
                    <NoDataFound />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </MuiTable>
        </InfiniteScroll>
      </TableContainer>
      <div className={styles.buttonContainer}>
        {isRemoving ? (
          <Loader />
        ) : (
          <Button
            disabled={!unassignPatientList.length}
            variant="contained"
            color="primary"
            className={styles.button}
            startIcon={<ArrowBackIcon />}
            onClick={() => removePatients()}
          >
            <span className={styles.divider} />
            UNASSIGN
          </Button>
        )}
      </div>
    </div>
  );
};

export default Table;
