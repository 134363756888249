import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';
import { useEffect, useRef } from 'react';
import { getAccessToken } from '~/services/auth';
import { useStoreActions, useStoreState } from '~/store/hooks';

const anonymousEndpoints = ['auth'];

export const setUpAxiosInterceptors = () => {
  const { parsedAccessToken } = useStoreState(store => store.auth);

  const { onRefresh } = useStoreActions(actions => actions.auth);

  const parsedAccessTokenRef = useRef(null);

  parsedAccessTokenRef.current = parsedAccessToken;

  useEffect(() => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.paramsSerializer = params => qs.stringify(params, { indices: false });

    axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
      if (anonymousEndpoints.some(x => config.url.startsWith(x))) {
        return config;
      }

      let accessToken = getAccessToken();

      if (!accessToken || parsedAccessTokenRef.current?.exp - new Date().getTime() / 1000 < 60) {
        await onRefresh();

        accessToken = getAccessToken();
      }

      config.headers.Authorization = `Bearer ${accessToken}`;

      return config;
    });

    axios.interceptors.response.use(
      (response: AxiosResponse) => {
        const version = process.env.REACT_APP_API_VERSION;

        if (response.headers['api-deprecated-versions']?.includes(version)) {
          window.location.reload();
        }

        return response;
      },
      (error: AxiosError) => Promise.reject(error.response),
    );
  }, []);
};

export default {};
