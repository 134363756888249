import { object, string, number, array, ValidationError } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { phoneNumber } from '~/ui/constants/validation';
import { MobileUserRole } from '~/types';

const validationSchema = object()
  .shape({
    healthCareManagerIds: array().of(number()).min(1, 'Field is required'),
    subjectId: string().max(50, 'Max length - 50 symbols').required('Field is required'),
    firstName: string().max(100, 'Max length - 100 symbols').required('Field is required'),
    middleName: string().nullable().max(100, 'Max length - 100 symbols'),
    lastName: string().max(100, 'Max length - 100 symbols').required('Field is required'),
    phoneNumber: phoneNumber.required('Field is required'),
    dateOfBirth: string().nullable(),
    ethnicity: string().nullable(),
    gender: string().nullable(),
    country: string().nullable().required('Field is required'),
    state: string().nullable().required('Field is required'),
    city: string().max(100, 'Max length - 100 symbols').required('Field is required'),
    address: string().max(100, 'Max length - 100 symbols').required('Field is required'),
    zip: string().max(20, 'Max length - 20 symbols').required('Field is required'),
    timezone: string().required('Field is required'),
    isHomeHealthCare: string().nullable(),
    hospitalizationRiskScore: number()
      .nullable()
      .when('isHomeHealthCare', {
        is: (val: string) => Number(val) === 1,
        then: number().nullable().min(0, 'Min value: 0').max(100, 'Max value: 100'),
      })
      .transform(val => (val === Number(val) ? val : null)),
    comment: string().nullable().max(500, 'Max length - 500 symbols'),
    homePhone: phoneNumber,
    cellPhone: phoneNumber,
    workPhone: phoneNumber,
    shippingAddressSameAsHome: string().nullable(),
    shippingAddressCountry: string().nullable(),
    shippingAddressState: string().nullable(),
    shippingAddressCity: string()
      .nullable()
      .when('shippingAddressSameAsHome', {
        is: (val: string) => !Number(val),
        then: string().nullable().max(100, 'Max length - 100 symbols'),
      }),
    shippingAddress: string()
      .nullable()
      .when('shippingAddressSameAsHome', {
        is: (val: string) => !Number(val),
        then: string().nullable().max(100, 'Max length - 100 symbols'),
      }),
    shippingAddressZip: string()
      .nullable()
      .when('shippingAddressSameAsHome', {
        is: (val: string) => !Number(val),
        then: string().nullable().max(20, 'Max length - 20 symbols'),
      }),
    emergencyContactFirstName: string().nullable().max(100, 'Max length - 100 symbols'),
    emergencyContactLastName: string().nullable().max(100, 'Max length - 100 symbols'),
    emergencyContactPhone: phoneNumber,
    emergencyContactEmail: string()
      .nullable()
      .max(100, 'Max length - 100 symbols')
      .email('Email must be valid'),
    emergencyContactRelation: string().nullable(),
    caretakers: array().of(
      object().shape({
        phone: phoneNumber.required('Field is required'),
      }),
    ),
    homeHealthcarePeople: array().of(
      object().shape({
        phone: phoneNumber.required('Field is required'),
      }),
    ),
  })
  .test({
    name: 'uniquePhone',
    test: (value, { createError }) => {
      const errors = [] as ValidationError[];

      value.caretakers.forEach((item, index) => {
        if (value.caretakers.filter(x => x.phone === item.phone).length >= 2) {
          errors.push(
            new ValidationError(`'Phone' must be unique`, value, `caretakers[${index}].phone`),
          );
        }
      });

      value.homeHealthcarePeople.forEach((item, index) => {
        if (value.homeHealthcarePeople.filter(x => x.phone === item.phone).length >= 2) {
          errors.push(
            new ValidationError(
              `'Phone' must be unique`,
              value,
              `homeHealthcarePeople[${index}].phone`,
            ),
          );
        }
      });

      value.caretakers.forEach((item, index) => {
        if (value.homeHealthcarePeople.some(x => x.phone === item.phone)) {
          errors.push(
            new ValidationError(`'Phone' must be unique`, value, `caretakers[${index}].phone`),
          );
        }
      });

      value.homeHealthcarePeople.forEach((item, index) => {
        if (value.caretakers.some(x => x.phone === item.phone)) {
          errors.push(
            new ValidationError(
              `'Phone' must be unique`,
              value,
              `homeHealthcarePeople[${index}].phone`,
            ),
          );
        }
      });

      return errors.length === 0 ? true : createError({ message: () => errors });
    },
  })
  .test({
    name: 'roleIsCorrect',
    test: (value, { createError }) => {
      const errors = [] as ValidationError[];

      value.caretakers.forEach((item, index) => {
        if (item.mobileUserRoleId === MobileUserRole.HomeHealthCare) {
          errors.push(
            new ValidationError(
              `The user with this number is already registered as 'Home Healthcare' in the system, it cannot be used for 'Caretaker'`,
              value,
              `caretakers[${index}].phone`,
            ),
          );
        }
      });

      value.homeHealthcarePeople.forEach((item, index) => {
        if (item.mobileUserRoleId === MobileUserRole.Caretaker) {
          errors.push(
            new ValidationError(
              `The user with this number is already registered as 'Caretaker' in the system, it cannot be used for 'Home Healthcare'`,
              value,
              `homeHealthcarePeople[${index}].phone`,
            ),
          );
        }
      });

      return errors.length === 0 ? true : createError({ message: () => errors });
    },
  });

export default yupResolver(validationSchema);
