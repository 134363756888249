import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkDeletePatientNotificationRule } from '../types';

const onDeletePatientNotificationRule: IThunkDeletePatientNotificationRule = thunk(
  // @ts-ignore
  async ({ deletePatientNotificationRule }, id) => {
    await api.patientNotificationRules.remove(id).then(res => res.data);
    deletePatientNotificationRule(id);
  },
);

export default onDeletePatientNotificationRule;
