import { useEffect, useState, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Paper from '@material-ui/core/Paper';

import api from '~/services/api';
import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import Loader from '~/ui/components/common/Loader';
import InfoPanel from '~/ui/components/reusable/InfoPanel';
import PatientInfoPanel from './components/PatientInfoPanel';
import Header from './components/Header';
import Table from './components/Table';

import { ACTIVITY_LOGS } from '~/ui/constants/paths';
import { UserRole } from '~/types';
import { IPatientInfo } from '~/services/api/patients/types';
import { IPatientParams } from './types';
import styles from './PatientActivityLogs.module.scss';
import { formatToIsoWithTimezone, getInitialDateRange } from '~/utils/date';
import { IActivityLogsListFilters } from '~/services/api/activityLogs/types';

const PatientActivityLogs = (): ReactElement => {
  const routerParams = useParams<IPatientParams>();
  const patientId = Number(routerParams.patientId);

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [totalActivityMinutes, setTotalActivityMinutes] = useState(0);
  const [filters, setFilters] = useState<IActivityLogsListFilters>({ ...getInitialDateRange() });
  const [deleteActivityLogId, setDeleteActivityLogId] = useState<number>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const currentUser = useStoreState(state => state.auth.currentUser);
  const { itemsByPatientId: activityLogs, paginationByPatientId: pagination } = useStoreState(
    state => state.activityLogs,
  );

  const patientInfo = useStoreState(state => state.patient.info);

  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);
  const { onGetInfo: onGetPatientInfo } = useStoreActions(actions => actions.patient);
  const { onGetActivityLogsByPatientId, onGetMoreActivityLogsByPatientId } = useStoreActions(
    actions => actions.activityLogs,
  );

  const userRole = currentUser?.roleId;
  const userId = currentUser?.id;

  const permissions = {
    canManage: userRole === UserRole.SystemAdmin,
  };

  const getData = async (dateFilters: IActivityLogsListFilters, patientInfoData: IPatientInfo) => {
    setIsLoading(true);
    setFilters(dateFilters);

    try {
      const [, { totalMinutesCount }] = await Promise.all([
        await onGetActivityLogsByPatientId({
          patientId,
          params: {
            ...filters,
            startDate: formatToIsoWithTimezone(dateFilters.startDate, patientInfoData.timezone),
            endDate: formatToIsoWithTimezone(dateFilters.endDate, patientInfoData.timezone),
          },
        }),
        api.activityLogs
          .getCounters({
            ...filters,
            startDate: formatToIsoWithTimezone(dateFilters.startDate, patientInfoData.timezone),
            endDate: formatToIsoWithTimezone(dateFilters.endDate, patientInfoData.timezone),
            patientId,
          })
          .then(res => res.data),
      ]);
      setTotalActivityMinutes(totalMinutesCount);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  const onLoadMoreActivityLogs = async () => {
    try {
      await onGetMoreActivityLogsByPatientId({
        patientId,
        params: {
          ...filters,
          startDate: formatToIsoWithTimezone(filters.startDate, patientInfo.timezone),
          endDate: formatToIsoWithTimezone(filters.endDate, patientInfo.timezone),
        },
      });
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const removeActivityLog = async () => {
    try {
      setIsDeleting(true);
      await api.activityLogs.remove(deleteActivityLogId);
      await getData(filters, patientInfo); // refresh list after deleting
      setDeleteActivityLogId(null);
      showNotify('Activity log removed');
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsDeleting(false);
    }
  };

  const onMount = async () => {
    try {
      const patientInfoData = await onGetPatientInfo(patientId);
      await getData(getInitialDateRange(), patientInfoData);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsPageLoading(false);
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  if (isPageLoading) return <Loader />;

  return (
    <Paper elevation={3} className={styles.container}>
      <PatientInfoPanel patient={patientInfo} />
      <Header patientInfo={patientInfo} onSubmit={getData} />
      <InfoPanel
        startDate={filters.startDate}
        endDate={filters.endDate}
        label="Activity Logs"
        total={totalActivityMinutes}
        totalLabel="Activity Minutes"
        backBtnUrl={ACTIVITY_LOGS}
      />
      <InfiniteScroll
        dataLength={activityLogs.length}
        next={onLoadMoreActivityLogs}
        hasMore={pagination.hasMore}
        loader={<Loader />}
      >
        {!isLoading && (
          <Table
            activityLogs={activityLogs}
            permissions={permissions}
            userId={userId}
            onRemoveActivityLog={setDeleteActivityLogId}
          />
        )}
      </InfiniteScroll>
      {isLoading && <Loader />}
      {deleteActivityLogId && (
        <ConfirmModal
          title="Remove"
          description="Are you sure you want to remove current Activity log?"
          isLoading={isDeleting}
          onClose={() => setDeleteActivityLogId(null)}
          onConfirm={removeActivityLog}
        />
      )}
    </Paper>
  );
};

export default PatientActivityLogs;
