import { useEffect, ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';

import { isDuplicatedRuleCombination } from './helper';
import { initialValues, validate } from './form';
import Input from '~/ui/components/inputs/Input';
import Select from '~/ui/components/inputs/Select';

import {
  typeOptions,
  conditionOptions,
  weightConditionOptions,
  ruleTypes,
} from '~/ui/constants/notificationRule';
import styles from './AddNotificationRuleForm.module.scss';
import { RuleType } from '~/services/api/enums';
import { INotificationRule } from '~/services/api/notificationRules/types';

interface IProps {
  rules: INotificationRule[];
  onSubmit: (values: INotificationRule) => void;
  onCancel: () => void;
}

const AddNotificationRuleForm = ({
  rules,
  onSubmit: onSubmitCallback,
  onCancel,
}: IProps): ReactElement => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    trigger,
  } = useForm<INotificationRule>({
    mode: 'all',
    resolver: validate,
    defaultValues: initialValues,
  });

  const onSubmit = async (values: INotificationRule) => {
    const payload: INotificationRule = {
      ...values,
      typeName: ruleTypes[values.typeId],
      conditionName: (values.typeId === RuleType.Weight
        ? weightConditionOptions
        : conditionOptions
      ).find(x => x.value === values.conditionId).label,
      id: Date.now() * -1, // add unique id (number) (this will be used as react key in map method)
    };
    onSubmitCallback(payload);
  };

  const { typeId, conditionId, level1, level2, level3 } = watch();
  // check on combination duplicates
  const isDuplicate = isDuplicatedRuleCombination({ rules, typeId, conditionId });

  useEffect(() => {
    trigger();
  }, [typeId, conditionId, level1, level2, level3]);

  return (
    <div className={styles.addNotificationRule}>
      <div className={styles.title}>Add rule</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Select
                  name="typeId"
                  control={control}
                  errors={errors}
                  options={typeOptions}
                  label="Reading Type *"
                />
              </Grid>
              <Grid item xs={6}>
                <Select
                  name="conditionId"
                  control={control}
                  errors={errors}
                  options={typeId === RuleType.Weight ? weightConditionOptions : conditionOptions}
                  label="Condition *"
                />
              </Grid>
            </Grid>
            {isDuplicate && (
              <FormHelperText error>
                Current combination of `type` and `condition` values is already added
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={2}>
            <Input
              type="number"
              name="level1"
              register={register}
              errors={errors}
              label="Level1"
              placeholder="Level1"
            />
          </Grid>
          <Grid item xs={2}>
            <Input
              type="number"
              name="level2"
              register={register}
              errors={errors}
              label="Level2"
              placeholder="Level2"
            />
          </Grid>
          <Grid item xs={2}>
            <Input
              type="number"
              name="level3"
              register={register}
              errors={errors}
              label="Level3"
              placeholder="Level3"
            />
          </Grid>
          <Grid item xs={2}>
            <Grid container justifyContent="flex-end" className={styles.btnWrapper}>
              <Button size="small" variant="outlined" color="primary" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                type="submit"
                size="small"
                variant="contained"
                color="primary"
                disabled={isDuplicate}
                className={styles.btnSave}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default AddNotificationRuleForm;
