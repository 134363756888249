import { useState, ReactElement } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { getDevicesActions } from '../../helper';
import Actions from '../../popups/Actions';
import HistoryModal from '../../popups/HistoryModal';

import { IDevice } from '~/services/api/devices/types';
import styles from './Item.module.scss';
import { DeviceAction } from '../../types';

interface IProps {
  device: IDevice;
  checkedDevicesIds: number[];
  onToggleCheckDevice: (id: number) => void;
  onAction: (action: DeviceAction, devicesIds: number[]) => void;
}

const Item = ({
  device,
  checkedDevicesIds,
  onToggleCheckDevice,
  onAction,
}: IProps): ReactElement => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [showHistoryModal, setShowHistoryModal] = useState(false);

  const actions = getDevicesActions([device], [device.id]);

  const handleActionsBtnClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(prev => (prev ? null : e.currentTarget));
  };

  const handleActionsClose = () => {
    setAnchorElement(null);
  };

  return (
    <TableRow>
      <TableCell>
        <div className={styles.info}>
          <Checkbox
            color="primary"
            className={styles.checkbox}
            checked={checkedDevicesIds.includes(device.id)}
            onChange={() => onToggleCheckDevice(device.id)}
          />
          <IconButton color="primary" aria-label="close" onClick={() => setShowHistoryModal(true)}>
            <InfoOutlinedIcon />
          </IconButton>
        </div>
      </TableCell>
      <TableCell>{device.typeName}</TableCell>
      <TableCell className={styles.cell}>{device.imei}</TableCell>
      <TableCell>{device.statusName}</TableCell>
      <TableCell>{device.supplierName}</TableCell>
      <TableCell className={styles.cell}>{device.programName}</TableCell>
      <TableCell className={styles.cell}>{device.patientFullName}</TableCell>
      <TableCell align="center">
        <IconButton aria-label="close" onClick={handleActionsBtnClick}>
          <MoreVertIcon />
        </IconButton>
        <Actions
          anchorElement={anchorElement}
          placement="left-start"
          actions={actions}
          onSelect={onAction}
          onClose={handleActionsClose}
        />
      </TableCell>
      {showHistoryModal && (
        <HistoryModal deviceId={device.id} onClose={() => setShowHistoryModal(false)} />
      )}
    </TableRow>
  );
};

export default Item;
