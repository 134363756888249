import { object, string, number } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { RuleCondition, RuleType } from '~/services/api/enums';

const MIN_LEVEL_VALUE = 0;
const MAX_LEVEL_VALUE = 99999;

const FIELD_REQUIRED_TEXT = 'Field is required';
const MIN_VALUE_TEXT = `Min value: ${MIN_LEVEL_VALUE}`;
const MAX_VALUE_TEXT = `Max value: ${MAX_LEVEL_VALUE}`;

const validationSchema = object().shape({
  typeId: string().required(FIELD_REQUIRED_TEXT),
  conditionId: string().required(FIELD_REQUIRED_TEXT),
  level1: number()
    .positive()
    .typeError(FIELD_REQUIRED_TEXT)
    .min(MIN_LEVEL_VALUE, MIN_VALUE_TEXT)
    .max(MAX_LEVEL_VALUE, MAX_VALUE_TEXT)
    // check on condition = Below
    .test({
      name: 'level2',
      message: 'Value of `level1` must be greater then `level2`',
      test: (value, context) => {
        const { typeId, conditionId, level2 } = context.parent;
        if (typeId === RuleType.Weight) return true;
        if (conditionId === RuleCondition.Above) return true;
        if (value <= level2) return false;
        return true;
      },
    })
    .required(FIELD_REQUIRED_TEXT),
  level2: number()
    .positive()
    .typeError(FIELD_REQUIRED_TEXT)
    .min(MIN_LEVEL_VALUE, MIN_VALUE_TEXT)
    .max(MAX_LEVEL_VALUE, MAX_VALUE_TEXT)
    // check on condition = Above
    .test({
      name: 'level2',
      message: 'Value of `level2` must be greater then `level1`',
      test: (value, context) => {
        const { conditionId, level1 } = context.parent;
        if (conditionId === RuleCondition.Below) return true;
        if (value <= level1) return false;
        return true;
      },
    })
    // check on condition = Below
    .test({
      name: 'level2',
      message: 'Value of `level2` must be greater then `level3`',
      test: (value, context) => {
        const { typeId, conditionId, level3 } = context.parent;
        if (typeId === RuleType.Weight) return true;
        if (conditionId === RuleCondition.Above) return true;
        if (value <= level3) return false;
        return true;
      },
    })
    .required(FIELD_REQUIRED_TEXT),
  level3: number()
    .positive()
    .typeError(FIELD_REQUIRED_TEXT)
    .min(MIN_LEVEL_VALUE, MIN_VALUE_TEXT)
    .max(MAX_LEVEL_VALUE, MAX_VALUE_TEXT)
    // check on condition = Above
    .test({
      name: 'level3',
      message: 'Value of `level3` must be greater then `level2`',
      test: (value, context) => {
        const { conditionId, level2 } = context.parent;
        if (conditionId === RuleCondition.Below) return true;
        if (value <= level2) return false;
        return true;
      },
    })
    .required(FIELD_REQUIRED_TEXT),
});

const validate = yupResolver(validationSchema);

export default validate;
