import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkUpdateMedication } from '../types';

// @ts-ignore
const onUpdateMedication: IThunkUpdateMedication = thunk(async ({ updateMedication }, payload) => {
  const insurance = await api.medications.update(payload).then(res => res.data);
  updateMedication(insurance);
});

export default onUpdateMedication;
