import { userTypeOptions } from '~/ui/constants/user';
import { UserRole } from '~/types';

interface IAllowedUserTypes {
  [key: string]: UserRole[];
}

const allowedUserTypes: IAllowedUserTypes = {
  [UserRole.HealthManager]: [UserRole.HealthManager],
  [UserRole.ProgramAdmin]: [UserRole.HealthManager],
  [UserRole.SuperAdmin]: [UserRole.HealthManager, UserRole.ProgramAdmin],
  [UserRole.SystemAdmin]: [UserRole.HealthManager, UserRole.ProgramAdmin, UserRole.SuperAdmin],
};

export const getUserTypeOptions = (userRole: UserRole) =>
  userTypeOptions.filter(option => allowedUserTypes[UserRole[userRole]].includes(option.value));

export default {};
