import { ReactElement } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { isoToFormat } from '~/utils/date';
import NoDataFound from '~/ui/components/reusable/NoDataFound';
import DeviceStatus from '~/ui/components/reusable/DeviceStatus';

import { IPatientDetails } from '~/services/api/patients/types';
import styles from './Devices.module.scss';

interface IProps {
  patient: IPatientDetails;
}

const Devices = ({ patient }: IProps): ReactElement => {
  const { devices } = patient;

  return (
    <div className={styles.container}>
      <div className={styles.title}>Devices</div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Device</TableCell>
              <TableCell align="center">Model</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="center">Start Date</TableCell>
              <TableCell>IMEI</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {devices.length ? (
              devices.map(device => (
                <TableRow key={device.id}>
                  <TableCell>{device.typeName}</TableCell>
                  <TableCell align="center">{device.model}</TableCell>
                  <TableCell>{device.statusName}</TableCell>
                  <TableCell align="center">
                    {device.registeredAt
                      ? `${isoToFormat(device.registeredAt, 'dd MMM y H:mm')} ${patient.timezone}`
                      : '-'}
                  </TableCell>
                  <TableCell>{device.imei}</TableCell>
                  <TableCell align="center">
                    <DeviceStatus device={device} timezone={patient.timezone} />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <NoDataFound />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Devices;
