import { useEffect, useState } from 'react';
import { setCounterDurationSec } from '../helper';

let intervalId: NodeJS.Timeout = null;

// This is a custom hook for ActivityCounter
const useCounter = (initialCount = 0) => {
  const [count, setCount] = useState(initialCount);

  const start = (): void => {
    intervalId = setInterval(() => {
      setCount(prev => prev + 1);
    }, 1000);
  };

  const stop = (): void => {
    clearInterval(intervalId);
  };

  useEffect(() => {
    setCounterDurationSec(count);
  }, [count]);

  return { count, start, stop };
};

export default useCounter;
