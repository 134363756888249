import { action } from 'easy-peasy';
import { IActionSetMorePrograms } from '../types';

const setMorePrograms: IActionSetMorePrograms = action((state, payload) => {
  const hasMore = state.pagination.itemsPerPage === payload.length;

  state.items = [...state.items, ...payload];
  state.pagination.page += 1;
  state.pagination.hasMore = hasMore;
});

export default setMorePrograms;
