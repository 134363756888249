import { ReactElement } from 'react';
import classnames from 'classnames';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoneIcon from '@mui/icons-material/Done';
import ReplayIcon from '@mui/icons-material/Replay';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import PhonelinkEraseIcon from '@mui/icons-material/PhonelinkErase';
import WidgetsIcon from '@mui/icons-material/Widgets';

import { formatNumber } from '~/utils/format';
import { IDashboardCounters } from '~/services/api/dashboard/types';
import styles from './DevicesCard.module.scss';
import { IPagePermissions } from '../../types';

interface IProps {
  counters: IDashboardCounters;
  permissions: IPagePermissions;
  onClick: () => void;
}

const DevicesCard = ({ counters, permissions, onClick }: IProps): ReactElement => {
  const {
    totalDevicesCount,
    issuedDevicesCount,
    readyDevicesCount,
    availableDevicesCount,
    returningDevicesCount,
    returnedDevicesCount,
    lostDevicesCount,
  } = counters;

  return (
    <div role="presentation" className={styles.smallCard} onClick={onClick}>
      <div className={styles.inner}>
        <div className={styles.top}>
          <div className={classnames(styles.iconWrapper, styles.iconWrapper_blue)}>
            <WidgetsIcon className={classnames(styles.icon, styles.blue)} />
          </div>
          <div className={styles.content}>
            <div className={classnames(styles.amount, styles.blue)}>
              {formatNumber(totalDevicesCount)}
            </div>
            <div className={styles.title}>Total</div>
          </div>
          <div className={styles.verticalDivider} />
          <div className={styles.issuedWrapper}>
            <HowToRegIcon className={classnames(styles.iconSmall, styles.iconIssued)} />
            <div>{`${issuedDevicesCount} Issued`}</div>
          </div>
          <div className={styles.readyWrapper}>
            <DoneAllIcon className={classnames(styles.iconSmall, styles.iconReady)} />
            <div>{`${readyDevicesCount} Ready`}</div>
          </div>
        </div>
        {permissions.showDevicesExtraBlock && (
          <>
            <div className={styles.divider} />
            <div className={styles.bottom}>
              <div>
                <DoneIcon className={classnames(styles.iconSmall, styles.iconAvailable)} />
                <div>{`${availableDevicesCount} Available`}</div>
              </div>
              <div>
                <ReplayIcon className={classnames(styles.iconSmall, styles.iconReturning)} />
                <div>{`${returningDevicesCount} Returning`}</div>
              </div>
              <div>
                <KeyboardReturnIcon className={classnames(styles.iconSmall, styles.iconReturned)} />
                <div>{`${returnedDevicesCount} Returned`}</div>
              </div>
              <div>
                <PhonelinkEraseIcon className={classnames(styles.iconSmall, styles.iconLost)} />
                <div>{`${lostDevicesCount} Lost`}</div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DevicesCard;
