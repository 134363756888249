import { useEffect, useState, ReactElement } from 'react';
import IdleTimer from 'react-idle-timer';
import sf from 'seconds-formater';
import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';
import Paper from '@material-ui/core/Paper';

import useCounter from './hooks/useCounter';
import Loader from '~/ui/components/common/Loader';
import AlertModal from '~/ui/components/common/AlertModal';

import { INACTIVITY_INTERVAL_MS } from './constants';
import { IPatientInfo } from '~/services/api/patients/types';
import styles from './ActivityCounter.module.scss';

interface IProps {
  patient: IPatientInfo;
  initialCount?: number;
  className?: string;
}

const ActivityCounter = ({ patient, initialCount, className }: IProps): ReactElement => {
  const [showPausedPopup, setShowPausedPopup] = useState(false);

  const { count, start, stop } = useCounter(initialCount);

  const handlePauseCount = () => {
    stop();
    setShowPausedPopup(true);
  };

  const handleResumeCount = () => {
    start();
    setShowPausedPopup(false);
  };

  useEffect(() => {
    start();

    return () => {
      stop();
    };
  }, []);

  const handleOnIdle = () => {
    handlePauseCount();
  };

  return (
    <Paper elevation={1} className={classnames(styles.activityTimer, className)}>
      {isEmpty(patient) ? (
        <Loader />
      ) : (
        <>
          <div className={styles.name}>{`${patient.fullName} - ${patient.subjectId}`}</div>
          <div className={styles.timer}>{sf.convert(count).format()}</div>
          {showPausedPopup && (
            <AlertModal
              title="Timer Paused"
              description="Timer Paused due to Inactivity"
              btnText="Continue"
              onClose={() => handleResumeCount()}
            />
          )}
          <IdleTimer timeout={INACTIVITY_INTERVAL_MS} onIdle={handleOnIdle} debounce={1000} />
        </>
      )}
    </Paper>
  );
};

export default ActivityCounter;
