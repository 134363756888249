import { action } from 'easy-peasy';
import { IActionUpdateProgram } from '../types';

const updateProgram: IActionUpdateProgram = action((state, payload) => {
  const { id } = payload;
  state.items = state.items.map(item => {
    if (item.id === id) {
      return {
        ...item,
        ...payload,
      };
    }

    return item;
  });
});

export default updateProgram;
