import { action } from 'easy-peasy';
import { IActionUpdateActivityLog } from '../types';

const updateActivityLog: IActionUpdateActivityLog = action((state, payload) => {
  state.itemsByPatientId = state.itemsByPatientId.map(item => {
    if (item.id !== payload.id) return item;
    return {
      ...item,
      ...payload,
    };
  });
});

export default updateActivityLog;
