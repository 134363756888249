import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IUsersStore, IThunkUpdateUser } from '../types';

const onUpdateUser: IThunkUpdateUser = thunk(
  // @ts-ignore
  async ({ updateUser, removeUser }, payload, { getState }) => {
    const localState = getState() as IUsersStore;

    const user = await api.users.update(payload).then(res => res.data);

    const currentUser = localState.items.find(item => item.id === payload.id);

    updateUser(user);

    if (currentUser && currentUser.roleId !== user.roleId) {
      removeUser(payload.id);
    }
  },
);

export default onUpdateUser;
