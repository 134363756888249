import { ReactElement } from 'react';
import MuiTabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';

import { useStoreState } from '~/store/hooks';
import { ITab } from './types';
import styles from './VerticalTabs.module.scss';

const tabClasses = {
  root: styles.tab,
  wrapper: styles.tabWrapper,
  selected: styles.tabSelected,
};

interface IProps {
  tabs: ITab[];
  activeTab: string;
  onChange: (value: string) => void;
}

const TabsComponent = ({ tabs, activeTab, onChange }: IProps): ReactElement => {
  const currentUser = useStoreState(state => state.auth.currentUser);

  const handleChange = (e: React.ChangeEvent<unknown>, value: string) => {
    onChange(value);
  };

  return (
    <MuiTabs
      orientation="vertical"
      value={activeTab}
      classes={{ root: styles.tabs }}
      onChange={handleChange}
      TabIndicatorProps={{ children: <span /> }}
    >
      {tabs
        .filter(tab => tab.roles.length === 0 || tab.roles.includes(currentUser.roleId))
        .map((tab, index) => (
          <MuiTab
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            value={tab.value}
            label={tab.label}
            disableRipple
            classes={tabClasses}
          />
        ))}
    </MuiTabs>
  );
};

export default TabsComponent;
