import { ReactElement } from 'react';
import styles from './Wrapper.module.scss';

interface IProps {
  icon?: ReactElement | null;
  title: string;
  children: ReactElement;
}

const Wrapper = (props: IProps): ReactElement => {
  const { icon = null, title, children } = props;
  return (
    <div className={styles.wrapper}>
      <div className={styles.topWrapper}>
        {icon}
        <span className={styles.title}>{title}</span>
      </div>
      <div className={styles.wrapperContent}>{children}</div>
    </div>
  );
};

export default Wrapper;
