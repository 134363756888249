import { action } from 'easy-peasy';
import { deleteTokens } from '~/services/auth';
import initStore from '../initStore';
import { IActionReset } from '../types';

const reset: IActionReset = action(state => {
  const { currentUser, authorized, isMfaRequired, parsedAccessToken, parsedRefreshToken } =
    initStore;

  state.currentUser = currentUser;
  state.authorized = authorized;
  state.isMfaRequired = isMfaRequired;
  state.parsedAccessToken = parsedAccessToken;
  state.parsedRefreshToken = parsedRefreshToken;

  deleteTokens();
});

export default reset;
