import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkUpdateProgram } from '../types';

// @ts-ignore
const onUpdateProgram: IThunkUpdateProgram = thunk(async ({ updateProgram }, payload) => {
  const program = await api.programs.update(payload).then(res => res.data);
  updateProgram(program);
});

export default onUpdateProgram;
