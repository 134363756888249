import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getPaginationPayload } from '~/utils/pagination';
import { IThunkGetAuditLogs, IAuditLogsStore } from '../types';

const onGetAuditLogs: IThunkGetAuditLogs = thunk(
  // @ts-ignore
  async ({ setAuditLogs, reset }, filters, { getState }) => {
    reset();

    const localState = getState() as IAuditLogsStore;

    const paginationPayload = getPaginationPayload(localState.pagination);

    const { data } = await api.auditLogs.getList({
      ...filters,
      ...paginationPayload,
    });

    setAuditLogs(data);
  },
);

export default onGetAuditLogs;
