import { action } from 'easy-peasy';
import { IActionAddActivityLog } from '../types';

const addActivityLog: IActionAddActivityLog = action((state, payload) => {
  state.itemsByPatientId = [...state.itemsByPatientId, payload].sort(
    (a, b) => new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime(),
  );
});

export default addActivityLog;
