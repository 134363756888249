import { useRef, ReactElement } from 'react';
import { FileDrop } from 'react-file-drop';

import { isFile, validateFileType } from '~/utils/file';
import styles from './FileUpload.module.scss';

interface IProps {
  acceptTypes?: string;
  maxFileSize?: number;
  dropzoneText?: string;
  onUpload: (file: File) => void;
  onError: (err: string) => void;
}

/* Current FileUpload version supports only single file upload */
const FileUpload = ({
  acceptTypes = 'image/jpeg,image/png,image/gif',
  maxFileSize = 2, // 2Mb
  dropzoneText,
  onUpload,
  onError,
}: IProps): ReactElement => {
  const fileInputRef = useRef(null);

  const validateFile = (file: File) => {
    if (!isFile(file)) return 'No file';

    if (!validateFileType(file, acceptTypes)) {
      const shortTypeNames = acceptTypes
        .split(',')
        .map(type => type.split('/').pop().toLowerCase())
        .join(',');

      return `Only ${shortTypeNames} formats are valid`;
    }

    // convert fileSize from Mb to bytes (1 Mb = 1048576 Bytes)
    const maxFileSizeBytes = maxFileSize * 1048576;

    if (maxFileSize && file.size > maxFileSizeBytes) return `Max file size - ${maxFileSize}Mb`;

    return '';
  };

  const onTargetClick = () => {
    fileInputRef.current.click();
  };

  const onChange = (file: File) => {
    const err = validateFile(file);

    if (err) {
      onError(err);
    } else {
      onUpload(file);
    }
  };

  return (
    <>
      <FileDrop
        onTargetClick={onTargetClick}
        onDrop={(files, event) => {
          event.preventDefault();
          onChange(files[0]);
        }}
        className={styles.dropzone}
        targetClassName={styles.dropzoneTarget}
        draggingOverTargetClassName={styles.draggingOverTargetClassName}
      >
        {dropzoneText}
      </FileDrop>
      {/* Use input for onClick handler */}
      <input
        ref={fileInputRef}
        type="file"
        accept={acceptTypes}
        className={styles.hidden}
        onChange={event => {
          const { files } = event.target;
          onChange(files[0]);
        }}
      />
    </>
  );
};

export default FileUpload;
