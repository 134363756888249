import { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';

import Input from '~/ui/components/inputs/Input';
import Select from '~/ui/components/inputs/Select';
import RadioGroup from '~/ui/components/inputs/RadioGroup';
import { countryOptions, stateOptions } from '~/ui/constants';
import { shippingAddressOptions } from './constants';

const ShippingAddress = (): ReactElement => {
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const shippingAddressSameAsHome = watch('shippingAddressSameAsHome');

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <RadioGroup
            name="shippingAddressSameAsHome"
            control={control}
            errors={errors}
            options={shippingAddressOptions}
            label="Same as Home Address?"
          />
        </Grid>
      </Grid>
      {!Number(shippingAddressSameAsHome) && (
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Select
              name="shippingAddressCountry"
              control={control}
              errors={errors}
              options={countryOptions}
              label="Country"
            />
          </Grid>
          <Grid item xs={4}>
            <Select
              name="shippingAddressState"
              control={control}
              errors={errors}
              options={stateOptions}
              label="State"
            />
          </Grid>
          <Grid item xs={4}>
            <Input name="shippingAddressCity" register={register} errors={errors} label="City" />
          </Grid>
          <Grid item xs={4}>
            <Input name="shippingAddress" register={register} errors={errors} label="Address" />
          </Grid>
          <Grid item xs={4}>
            <Input name="shippingAddressZip" register={register} errors={errors} label="Zip" />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ShippingAddress;
