import axios from 'axios';
import { IResponse } from '~/services/api/types';
import { IPatientNotificationRulesListFilters } from './types';
import { INotificationRule } from '../notificationRules/types';

const apiUrl = 'patient-notification-rules';

export const getList = (
  params: IPatientNotificationRulesListFilters,
): IResponse<INotificationRule[]> =>
  axios({
    method: 'get',
    url: apiUrl,
    params,
  });

export const create = (patientId: number, data: INotificationRule): IResponse<INotificationRule> =>
  axios({
    method: 'post',
    url: apiUrl,
    data,
    params: {
      patientId,
    },
  });

export const remove = (id: number): IResponse<void> =>
  axios({
    method: 'delete',
    url: `${apiUrl}/${id}`,
  });
