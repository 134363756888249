import { ReactElement } from 'react';
import { INotificationRule } from '~/services/api/notificationRules/types';

import NotificationRules from '~/ui/components/reusable/ProgramForm/components/NotificationRules';
import { IPagePermissions } from '../../types';
import styles from './Table.module.scss';

interface IProps {
  notificationRules: INotificationRule[];
  permissions: IPagePermissions;
  removeNotificationRule: (index: number) => void;
}

const Table = ({
  notificationRules,
  permissions,
  removeNotificationRule,
}: IProps): ReactElement => (
  <div className={styles.container}>
    <div className={styles.marginTop} />
    <div className={styles.tableContainer}>
      <NotificationRules
        showBorder={false}
        rules={notificationRules}
        canManage={permissions.canManageNotificationRules}
        showDescriptionHint
        onRemove={removeNotificationRule}
      />
    </div>
  </div>
);

export default Table;
