import { ReactElement } from 'react';

import styles from './Divider.module.scss';

interface IProps {
  title?: string;
  showDividerLine?: boolean;
}

const Divider = ({ title = '', showDividerLine = true }: IProps): ReactElement => (
  <>
    {showDividerLine && <div className={styles.divider} />}
    {title && <div className={styles.title}>{title}</div>}
  </>
);

export default Divider;
