import { useEffect, useState, ReactElement } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Paper from '@material-ui/core/Paper';

import api from '~/services/api';
import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import Loader from '~/ui/components/common/Loader';
import InfoPanel from '~/ui/components/reusable/InfoPanel';
import Header from './Header';
import Table from './Table';

import styles from './AuditLogs.module.scss';
import { IAuditLogsListFilters, IAuditLogsSelectors } from '~/services/api/auditLogs/types';
import { getInitialFilters } from './Header/form';
import { ISort } from '~/utils/sort/types';
import { initialSort } from './Table/constants';

const AuditLogs = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState<IAuditLogsListFilters>({ ...getInitialFilters() });
  const [selectors, setSelectors] = useState<IAuditLogsSelectors>(null);
  const [sort, setSort] = useState<ISort>({ ...initialSort });

  const { items, pagination } = useStoreState(state => state.auditLogs);
  const { onGetAuditLogs, onGetMoreAuditLogs } = useStoreActions(actions => actions.auditLogs);
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const params = {
    ...filters,
    ...sort,
  };

  const getData = async () => {
    setIsLoading(true);

    try {
      const [, selectorsData] = await Promise.all([
        onGetAuditLogs(params),
        api.auditLogs.getSelectors(params).then(res => res.data),
      ]);

      setSelectors(selectorsData);

      setIsLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const onLoadMore = async () => {
    try {
      await onGetMoreAuditLogs(params);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    getData();
  }, [filters, sort]);

  return (
    <Paper elevation={3} className={styles.container}>
      {!isLoading && (
        <>
          <Header selectors={selectors} filters={filters} onSubmit={setFilters} />
          <InfoPanel startDate={filters.startDate} endDate={filters.endDate} label="Audit Logs" />
          <InfiniteScroll
            dataLength={items.length}
            next={onLoadMore}
            hasMore={pagination.hasMore}
            loader={<Loader />}
          >
            <Table items={items} sort={sort} setSort={setSort} />
          </InfiniteScroll>
        </>
      )}
      {isLoading && <Loader />}
    </Paper>
  );
};

export default AuditLogs;
