import { ReactElement } from 'react';

import { IReading } from '../types';

interface IProps {
  item: IReading;
}

const Reading = ({ item }: IProps): ReactElement => (
  <div>
    <div>{item?.value}</div>
    <div>{`${item?.day ?? '-'} ${item?.dayPart ?? '-'}`}</div>
  </div>
);

export default Reading;
