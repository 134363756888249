import { ReactElement } from 'react';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import get from 'lodash/get';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

interface IProps {
  control: Control<any>;
  errors: FieldErrors;
  size?: 'medium' | 'small';
  name: string;
  label?: string;
  disabled?: boolean;
}

const CheckboxComponent = ({
  control,
  errors,
  size = 'medium',
  name,
  label = '',
  disabled = false,
}: IProps): ReactElement => {
  const error = get(errors, `${name}.message`, '');

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <FormControl variant="outlined">
          <FormControlLabel
            control={<Checkbox checked={value} color="primary" size={size} onChange={onChange} />}
            label={label}
            disabled={disabled}
          />
          {error && <FormHelperText error>{error}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

export default CheckboxComponent;
