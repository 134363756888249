import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getPaginationPayload } from '~/utils/pagination';
import { INotificationsStore, IThunkGetNotifications } from '../types';

const onGetNotifications: IThunkGetNotifications = thunk(
  // @ts-ignore
  async ({ setNotifications, reset }, payload, { getState }) => {
    reset();

    const localState = getState() as INotificationsStore;

    const paginationPayload = getPaginationPayload(localState.pagination);

    const { data } = await api.notifications.getList({
      ...paginationPayload,
    });

    setNotifications(data);
  },
);

export default onGetNotifications;
