import onGetPatient from './onGetPatient';
import onUpdatePatient from './onUpdatePatient';
import onDischargePatient from './onDischargePatient';
import onActivatePatient from './onActivatePatient';
import onGetOverview from './onGetOverview';
import onGetInfo from './onGetInfo';
import onUpdateQuickNotes from './onUpdateQuickNotes';

export default {
  onGetPatient,
  onGetOverview,
  onGetInfo,
  onUpdatePatient,
  onDischargePatient,
  onActivatePatient,
  onUpdateQuickNotes,
};
