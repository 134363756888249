import { useState, ReactElement } from 'react';
import { UseFormRegister, FieldErrors } from 'react-hook-form';
import get from 'lodash/get';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import styles from './Input.module.scss';

interface IProps {
  register: UseFormRegister<any>;
  errors: FieldErrors;
  type?: string;
  size?: 'medium' | 'small';
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
}

const Input = ({
  register,
  errors,
  type = 'text',
  size = 'small',
  name,
  label = '',
  disabled = false,
  placeholder = '',
}: IProps): ReactElement => {
  const [showPassword, setShowPassword] = useState(false);

  const { ref, ...registerProps } = register(name);

  const errorText = get(errors, `${name}.message`, '');
  const isError = Boolean(errorText);

  const getInputType = () => {
    if (type !== 'password') return type;
    return showPassword ? 'text' : 'password';
  };

  return (
    <FormControl variant="outlined" size={size} className={styles.inputContainer}>
      {label && <InputLabel shrink>{label}</InputLabel>}
      <OutlinedInput
        {...registerProps}
        inputRef={ref}
        inputProps={{
          step: 'any',
        }}
        type={getInputType()}
        notched={!!label}
        disabled={disabled}
        placeholder={placeholder}
        label={label}
        endAdornment={
          type === 'password' && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                className={styles.icon}
                onClick={() => setShowPassword(prev => !prev)}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }
        error={isError}
      />
      {isError && <FormHelperText error>{errorText}</FormHelperText>}
    </FormControl>
  );
};

export default Input;
