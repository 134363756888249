import * as Highcharts from 'highcharts';
import { DeviceType } from '~/services/api/enums';
import {
  IBloodGlucoseReading,
  IBloodPressureReading,
  IPulseOximeterReading,
  IReading,
  IWeightScaleReading,
} from '~/services/api/readings/types';
import { isoToFormat } from '~/utils/date';
import { ChartColor, labels } from '../types';

const formatDate = (date: string) => isoToFormat(date, 'MM/dd/y H:mm');

const getBloodPressureSeries = (
  readings: IBloodPressureReading[],
): Highcharts.SeriesOptionsType[] => [
  {
    name: labels.systolic,
    type: 'line',
    data: readings.map(x => x.systolic),
    color: ChartColor.Blue,
  },
  {
    name: labels.diastolic,
    type: 'line',
    data: readings.map(x => x.diastolic),
    color: ChartColor.Green,
  },
  {
    name: labels.heartRate,
    type: 'line',
    data: readings.map(x => x.pulse),
    color: ChartColor.Red,
  },
];

const getWeightScaleSeries = (readings: IWeightScaleReading[]): Highcharts.SeriesOptionsType[] => [
  {
    name: labels.weight,
    type: 'line',
    data: readings.map(x => x.weightInLbs),
    color: ChartColor.Blue,
    yAxis: 0,
  },
  {
    name: 'Previous Gain/Loss',
    type: 'column',
    data: readings.map(x => x.previousGainInLbs ?? x.previousLossInLbs),
    color: ChartColor.Red,
    yAxis: 1,
  },
  {
    name: 'Daily Gain/Loss',
    type: 'column',
    data: readings.map(x => x.dailyGainInLbs ?? x.dailyLossInLbs),
    color: ChartColor.Green,
    yAxis: 1,
  },
  {
    name: 'Weekly Gain/Loss',
    type: 'column',
    data: readings.map(x => x.weeklyGainInLbs ?? x.weeklyLossInLbs),
    color: ChartColor.Yellow,
    yAxis: 1,
  },
];

const getPulseOximeterSeries = (
  readings: IPulseOximeterReading[],
): Highcharts.SeriesOptionsType[] => [
  {
    name: labels.spo2,
    type: 'line',
    data: readings.map(x => x.spo2),
    color: ChartColor.Blue,
  },
  {
    name: labels.heartRate,
    type: 'line',
    data: readings.map(x => x.pulseBpm),
    color: ChartColor.Red,
  },
];

const getBloodGlucoseSeries = (
  readings: IBloodGlucoseReading[],
): Highcharts.SeriesOptionsType[] => [
  {
    name: labels.bloodGlucose,
    type: 'line',
    data: readings.map(x => x.bloodGlucoseMgdl),
    color: ChartColor.Blue,
  },
];

const getYAxis = (deviceTypeId: DeviceType): Highcharts.YAxisOptions[] => {
  const defaultAxis = [
    {
      title: {
        text: 'Reading Values',
      },
    },
  ];

  const weightScaleAxis = [
    {
      title: {
        text: 'Reading Values',
      },
      height: '50%',
      showFirstLabel: false,
    },
    {
      title: {
        text: 'Gain/Loss',
      },
      height: '50%',
      top: '50%',
      offset: 0,
      plotLines: [
        {
          value: 0,
          width: 2,
          zIndex: 3,
        },
      ],
    },
  ];

  return deviceTypeId === DeviceType.WeightScale ? weightScaleAxis : defaultAxis;
};

export const getChartOptions = (
  readings: IReading[],
  deviceTypeId: DeviceType,
): Highcharts.Options => {
  let series = null;

  switch (deviceTypeId) {
    case DeviceType.BloodPressure:
      series = getBloodPressureSeries(readings as IBloodPressureReading[]);
      break;
    case DeviceType.WeightScale:
      series = getWeightScaleSeries(readings as IWeightScaleReading[]);
      break;
    case DeviceType.PulseOximeter:
      series = getPulseOximeterSeries(readings as IPulseOximeterReading[]);
      break;
    case DeviceType.BloodGlucose:
      series = getBloodGlucoseSeries(readings as IBloodGlucoseReading[]);
      break;
    default:
      throw new Error();
  }

  return {
    chart: {
      zoomType: 'x',
      marginTop: 25,
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
        },
      },
    },
    xAxis: {
      title: {
        text: 'Reading Dates',
      },
      type: 'linear',
      categories: readings.map(x => formatDate(x.measuredAt)),
    },
    yAxis: getYAxis(deviceTypeId),
    series,
  };
};

export default {};
