import { INote } from '~/services/api/notes/types';
import { IFormValues } from '~/ui/components/reusable/Notes/NoteForm/types';

const getInitialValues = (note: INote): IFormValues => {
  const reminderDate = note.reminderDate ? new Date(note.reminderDate) : null;

  return {
    ...note,
    showReminder: !!reminderDate,
    reminderDate: reminderDate
      ? new Date(
          reminderDate.getUTCFullYear(),
          reminderDate.getUTCMonth(),
          reminderDate.getUTCDate(),
        ).toISOString()
      : null,
  };
};

export default getInitialValues;
