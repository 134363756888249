import { ReactElement } from 'react';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';

import Loader from '~/ui/components/common/Loader';
import NoDataFound from '~/ui/components/reusable/NoDataFound';
import Item from './Item';

import { IDevice } from '~/services/api/devices/types';
import { DeviceAction } from '../types';

interface IProps {
  devices: IDevice[];
  checkedDevicesIds: number[];
  isLoading: boolean;
  onToggleCheckDevice: (id: number) => void;
  onAction: (action: DeviceAction, devicesIds: number[]) => void;
}

const TableComponent = ({
  devices,
  checkedDevicesIds,
  isLoading,
  onToggleCheckDevice,
  onAction,
}: IProps): ReactElement => {
  if (isLoading) return <Loader />;

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Device Type</TableCell>
            <TableCell>IMEI</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Supplier</TableCell>
            <TableCell>Program</TableCell>
            <TableCell>Patient</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {devices.length ? (
            devices.map(item => (
              <Item
                key={item.id}
                device={item}
                checkedDevicesIds={checkedDevicesIds}
                onToggleCheckDevice={onToggleCheckDevice}
                onAction={onAction}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6}>
                <NoDataFound />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableComponent;
