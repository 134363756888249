export const getDateFormat = (value: Date): string => {
  const now = new Date();
  const date = new Date(value);

  if (
    date.getDate() === now.getDate() &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  ) {
    return 'HH:mm';
  }

  if (date.getFullYear() === now.getFullYear()) {
    return 'MMM dd HH:mm';
  }

  return 'yyyy MMM dd HH:mm';
};

export default {};
