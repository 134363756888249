import onGetInsurances from './onGetInsurances';
import onGetMoreInsurances from './onGetMoreInsurances';
import onCreateInsurance from './onCreateInsurance';
import onUpdateInsurance from './onUpdateInsurance';
import onDeleteInsurance from './onDeleteInsurance';

export default {
  onGetInsurances,
  onGetMoreInsurances,
  onCreateInsurance,
  onUpdateInsurance,
  onDeleteInsurance,
};
