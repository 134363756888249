import { ReactElement, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@mui/icons-material/Search';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import initialValues from './form/initialValues';
import Button from '~/ui/components/common/Button';
import Input from '~/ui/components/inputs/Input';
import SelectVirtualized from '~/ui/components/inputs/SelectVirtualized';
import Select from '~/ui/components/inputs/Select';
import AddDeviceCsv from './AddDeviceCsv';
import Actions from '../popups/Actions';

import {
  deviceTypeOptions,
  deviceSupplierOptions,
  statusOptions,
} from '~/ui/pages/Dashboard/popups/DevicesPopup/constants';
import { IIdName } from '~/services/api/types';
import { IDevicesListFilters } from '~/services/api/devices/types';
import styles from './Header.module.scss';
import { DeviceAction, IActions } from '../types';

interface IProps {
  programs: IIdName[];
  actions: IActions;
  checkedDevicesCount: number;
  isCsvAdding: boolean;
  isExporting: boolean;
  onAddDeviceCsv: (formData: FormData) => void;
  onExportDevices: () => void;
  onAction: (action: DeviceAction, devicesIds: number[]) => void;
  onSubmit: (filters: IDevicesListFilters) => void;
}

const Header = ({
  programs,
  actions,
  checkedDevicesCount,
  isCsvAdding,
  isExporting,
  onAddDeviceCsv,
  onExportDevices,
  onAction,
  onSubmit,
}: IProps): ReactElement => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const formMethods = useForm<IDevicesListFilters>({
    defaultValues: initialValues,
  });

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = formMethods;

  const programOptions = useMemo(() => {
    const options = programs.map(item => ({
      value: item.id,
      label: item.name,
    }));

    return [
      {
        value: '',
        label: 'All Programs',
      },
      ...options,
    ];
  }, [programs.length]);

  const handleActionsBtnClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(prev => (prev ? null : e.currentTarget));
  };

  const handleActionsClose = () => {
    setAnchorElement(null);
  };

  return (
    <FormProvider {...formMethods}>
      <Grid container justifyContent="flex-end" className={styles.top}>
        <AddDeviceCsv isCsvAdding={isCsvAdding} onAddDeviceCsv={onAddDeviceCsv} />
        <Button
          variant="contained"
          color="primary"
          endIcon={<ExpandMoreIcon />}
          className={styles.btnTop}
          onClick={handleActionsBtnClick}
        >
          {checkedDevicesCount > 0 && `(${checkedDevicesCount}) `}Actions
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={isExporting}
          isLoading={isExporting}
          endIcon={<InsertDriveFileIcon />}
          className={styles.btnTop}
          onClick={onExportDevices}
        >
          Export
        </Button>
        <Actions
          anchorElement={anchorElement}
          actions={actions}
          allDevices
          onSelect={onAction}
          onClose={handleActionsClose}
        />
      </Grid>
      <form className={styles.headerForm} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Select
              name="typeId"
              label="Device Type"
              control={control}
              errors={errors}
              options={deviceTypeOptions}
            />
          </Grid>
          <Grid item xs>
            <Input name="imei" label="IMEI" register={register} errors={errors} />
          </Grid>
          <Grid item xs>
            <Select
              name="status"
              label="Status"
              control={control}
              errors={errors}
              options={statusOptions}
            />
          </Grid>
          <Grid item xs>
            <Select
              name="supplier"
              label="Supplier"
              control={control}
              errors={errors}
              options={deviceSupplierOptions}
            />
          </Grid>
          <Grid item xs>
            <SelectVirtualized
              name="programId"
              label="Program"
              control={control}
              errors={errors}
              options={programOptions}
            />
          </Grid>
          <Grid item xs>
            <Input name="patientFullName" label="Patient" register={register} errors={errors} />
          </Grid>
          <Grid item>
            <Button variant="outlined" type="submit" color="primary" startIcon={<SearchIcon />}>
              Search
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              type="button"
              startIcon={<RotateRightIcon />}
              onClick={() => {
                reset(initialValues);
                onSubmit(initialValues);
              }}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default Header;
