import { IMobileUsersListFilters } from '~/services/api/mobileUsers/types';

const initialValues: IMobileUsersListFilters = {
  typeId: '',
  phone: '',
  isActive: '',
  hasAgreed: '',
};

export default initialValues;
