import { ReactElement } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';

import { statusOptions, deviceTypeOptions, deviceSupplierOptions } from '../constants';
import { initialValues } from './form/initialValues';
import validate from './form/validate';
import Input from '~/ui/components/inputs/Input';
import Select from '~/ui/components/inputs/Select';
import AutoSubmit from '~/ui/components/inputs/AutoSubmit';
import { IDevicesListFilters } from '~/services/api/devices/types';

interface IProps {
  onSubmit: (arg: IDevicesListFilters) => void;
}

const Header = ({ onSubmit }: IProps): ReactElement => {
  const formMethods = useForm<IDevicesListFilters>({
    defaultValues: initialValues,
    resolver: validate,
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const handleOnSubmit = (values: IDevicesListFilters) => {
    const status = values.status as string;
    const { typeId } = values;

    if (status === '') {
      delete values.status;
    }

    if (typeId === null) {
      delete values.typeId;
    }

    onSubmit(values);
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Select name="typeId" control={control} errors={errors} options={deviceTypeOptions} />
          </Grid>
          <Grid item xs={3}>
            <Input name="imei" register={register} errors={errors} placeholder="IMEI" />
          </Grid>
          <Grid item xs={3}>
            <Select
              name="status"
              control={control}
              errors={errors}
              options={statusOptions}
              maxMenuHeight={130}
            />
          </Grid>
          <Grid item xs={3}>
            <Select
              name="supplier"
              control={control}
              errors={errors}
              options={deviceSupplierOptions}
              maxMenuHeight={130}
            />
          </Grid>
          <AutoSubmit debounce={1000} initialValues={initialValues} onSubmit={handleOnSubmit} />
        </Grid>
      </form>
    </FormProvider>
  );
};

export default Header;
