import { action } from 'easy-peasy';
import { PatientStatus } from '~/services/api/enums';
import { IActionDischargePatient } from '../types';

const dischargePatient: IActionDischargePatient = action(state => {
  state.personalData = {
    ...state.personalData,
    status: PatientStatus.Discharged,
  };
});

export default dischargePatient;
